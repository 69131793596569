<template>
  <main>
    <div class="relative mt-1 flex w-full" v-bind="$attrs">
      <!-- <span
        v-if="label"
        class="absolute left-5 py-0 -top-2 px-5 bg-white text-sm text-newGray"
      >
        {{ label }}
      </span> -->
      <div
        class="bg-white w-3/4 flex-grow text-sm md:leading-2 focus:outline-none border block appearance-none focus:border-boxBorder"
        id="grid-state"
        @click.self="isSelect = !isSelect"
        :class="[
          directorView ? 'rounded-2xl' : 'rounded-l-2xl border-r-0',
          `${value ? '  text-dark-700' : '  text-dark-500'} ${
            isSelect ? 'border-outlineGray' : 'border-outlineGray'
          }`,
          isSelect
            ? 'py-0 px-0'
            : directorView
            ? optionArray.length === 0
              ? 'py-3 px-2'
              : 'py-1 px-2'
            : 'py-3 px-4',
        ]"
      >
        <div
          v-if="directorView"
          class="grid grid-cols-2 md:grid-cols-3 max-h-44 overflow-y-scroll items-center w-full gap-2"
          @click.self="isSelect = !isSelect"
        >
          <div
            v-for="opt in optionArray"
            :key="opt"
            class="flex flex-col w-44 gap-y-3 items-start rounded-xl px-2 text-center bg-outlineGray py-1 border-outlineGray border text-xs"
          >
            <div
              :class="input || paye ? 'justify-start mt-2' : 'justify-around'"
              class="flex items-center flex-nowrap gap-x-3"
            >
              <span
                @click="update(opt)"
                class="flex-none w-7 h-7 inline-flex items-center justify-center rounded-full"
                :style="`background-color:${
                  initials(getLabelFromValue(opt)).color
                }`"
              >
                <span class="shrink-0 text-white text-xs font-medium">{{
                  initials(getLabelFromValue(opt)).str
                }}</span></span
              >

              <span class="grow cursor-pointer text-xs">
                {{ getLabelFromValue(opt) }}</span
              >
              <img
                class="flex-none cursor-pointer"
                @click="update(opt)"
                src="@/assets/icons/remove.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <button
          v-if="directorView && !optionArray.length"
          type="button"
          style="border: 3px solid transparent"
          class="rounded-tr-2xl rounded-br-2xl sm:px-10 -mt-1 bg-transparent focus:outline-none absolute -right-8"
        >
          <img src="../../assets/icons/newSearchIcon.svg" alt="" />
        </button>
        <form
          v-if="isSelect && allEmployees.length"
          action=""
          :class="[
            directorView ? 'w-full' : 'w-11/12',
            directorView && optionArray.length ? 'hidden' : undefined,
          ]"
          class="relative z-10 top-0 bottom-0"
        >
          <div v-if="directorView">
            <button
              type="button"
              style="border: 3px solid transparent"
              class="rounded-tr-2xl rounded-br-2xl sm:px-10 py-2 bg-transparent focus:outline-none absolute -left-8"
            >
              <img src="../../assets/icons/newSearchIcon.svg" alt="" />
            </button>

            <div
              v-if="isSelect"
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"
            >
              <svg
                class="fill-current h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
          <easiTextInput
            v-model="searchQuery"
            @keyup="searchFunction"
            autofocus
            class="pl-10"
            :forSelect="true"
          ></easiTextInput>
        </form>
        <!-- <span class="relative w-11/12" @click.self="isSelect = true" v-else>
          {{
            !optionArray.length ? "Select" : formatValue(optionArray).join(",")
          }}</span
        > -->
        <span
          v-show="!isSelect && directorView && !optionArray.length"
          class="relative w-11/12"
          @click.self="isSelect = true"
          v-else
        >
          {{ placeHolder.length ? placeHolder : "Select Employee" }}</span
        >

        <ul
          v-show="isSelect"
          class="md:mt-7 mt-6 z-20 w-full absolute top-8 right-0 py-1 shadow-md bg-white shadow-2xl overflow-y-scroll"
          :class="allEmployees.length <= 5 ? 'h-auto' : 'h-56'"
        >
          <div
            v-if="!single && allEmployees.length"
            @click="addAll()"
            class="w-full cursor-pointer flex items-center mb-2 gap-2"
            :class="
              directorView
                ? 'justify-between border-0.5 py-1 border-b border-dividerColor'
                : 'hover:bg-newLightGreen'
            "
          >
            <input
              v-show="!directorView"
              :checked="allChecked || allSelected"
              class="mx-4 my-2"
              type="checkbox"
              name=""
              id=""
            />
            <span
              :class="directorView ? 'mx-3 my-2' : undefined"
              class="capitalize font-bold text-bodyDark text-sm"
              >Select All</span
            >
            <div class="mx-4" v-if="directorView">
              <svg
                v-if="allChecked || allSelected"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 10.625L8.75 15.625L16.25 4.375"
                  stroke="#219653"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                v-else
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 3.75V16.25M16.25 10L3.75 10"
                  stroke="#11453B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <li
            v-show="allEmployees && !allEmployees.length"
            @click="$router.push({ name: 'AddEmployee' })"
            class="px-4 py-2 cursor-pointer hover:bg-newLightGreen text-sm flex gap-x-3 items-center border-b border-dividerColor"
          >
            <span><i class="fas fa-plus text-secondary"></i></span>
            <span class="text-dark-800 text-sm hover:text-primary"
              >Add employee(s)</span
            >
          </li>
          <li
            v-for="option in optionData"
            :key="option.value"
            class="cursor-pointer text-sm text-dark"
            :class="optionArray.includes(option.value) ? ' bg-none ' : ''"
            @click="update(option.value)"
          >
            <div
              class="flex items-center gap-2 justify-between pr-4 pb-1"
              :class="
                directorView
                  ? 'justify-between border-0.5 py-0 border-b border-dividerColor'
                  : 'hover:bg-mint'
              "
            >
              <div
                :class="directorView ? 'mx-3 my-1' : undefined"
                class="flex items-center gap-2"
              >
                <input
                  v-show="!directorView"
                  :checked="optionArray.includes(option.value)"
                  class="ml-4 mr-2 my-2"
                  type="checkbox"
                  name=""
                  id=""
                  :value="option.value"
                />

                <span
                  class="w-7 h-7 flex items-center justify-center rounded-full"
                  :style="`background-color:${initials(option.label).color}`"
                >
                  <span class="text-white text-xs font-medium">{{
                    initials(option.label).str
                  }}</span>
                </span>
                <span class="cursor-pointer font-medium text-dark-800">
                  {{ option.label }}</span
                >
              </div>

              <div class="" v-if="directorView">
                <svg
                  v-if="optionArray.includes(option.value)"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.75 10.625L8.75 15.625L16.25 4.375"
                    stroke="#219653"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <svg
                  v-else
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 3.75V16.25M16.25 10L3.75 10"
                    stroke="#11453B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <div
                v-if="salary"
                class="rounded-md bg-background-200 flex flex-col px-3 py-1 font-medium w-32"
              >
                <span class="text-xs"> Salary </span>
                <span class="text-dark-800 text-md"
                  >{{ formatAmount(getNetSalary(option.value)) }}
                </span>
              </div>
            </div>
            <hr class="my-2 text-dividerColor invisible" />
          </li>
        </ul>
      </div>
      <div
        v-if="!isSelect && !directorView"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-dark-500"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
      <easiFilter
        v-show="!directorView"
        class="z-10 flex-grow ml-auto w-auto"
        @update="filterFN"
        :bonus="true"
      />
    </div>

    <div
      v-if="
        optionArray.length &&
        pension == false &&
        hideList == false &&
        directorView == false
      "
      :class="paye || input ? 'bg-none' : 'bg-white '"
      class="max-w-100 pt-5 md:pt-0 md:-mt-0 -mt-6"
    >
      <div
        class="max-h-40 overflow-y-scroll flex flex-wrap gap-2 mt-5 mb-5 justify-start items-start"
      >
        <div
          v-for="opt in optionArray"
          :key="opt"
          :class="[
            input || paye ? 'border-primary ' : 'border-gray ',
            input ? 'w-full md:w-auto' : 'w-auto',
          ]"
          class="flex flex-col gap-y-3 items-start rounded-xl px-2 text-center bg-outlineGray py-1 border-outlineGray border text-xs"
        >
          <div
            :class="input || paye ? 'justify-start mt-2' : 'justify-around'"
            class="flex items-center flex-nowrap gap-x-3"
          >
            <span
              @click="update(opt)"
              class="flex-none w-8 h-8 inline-flex items-center justify-center rounded-full"
              :style="`background-color:${
                initials(getLabelFromValue(opt)).color
              }`"
            >
              <span class="shrink-0 text-white text-xs font-medium">{{
                initials(getLabelFromValue(opt)).str
              }}</span></span
            >

            <span class="grow cursor-pointer text-xs">
              {{ getLabelFromValue(opt) }}</span
            >
            <img
              class="flex-none cursor-pointer"
              @click="update(opt)"
              src="@/assets/icons/remove.svg"
              alt=""
            />
          </div>

          <span v-if="salary" class="text-xs flex-none"
            >Salary:
            <strong> {{ formatAmount(getNetSalary(opt)) }}</strong></span
          >
          <span v-if="paye" class="text-xs flex-none"
            >Gross Pay:
            <strong>
              {{ formatAmount(getStaffLevelFromId(opt).grossAmount) }}</strong
            ></span
          >
          <div v-if="input">
            <easiTextInput
              v-if="pension"
              placeholder="Input Pencode"
              type="text"
              name="pencode"
              class="mt-1 mb-1"
              required
              :emp="true"
            ></easiTextInput>

            <div v-if="pension" class="flex items-center">
              <span class="text-dark-800 text-xs font-medium"
                >Voluntary Employer/Employee Contribution</span
              >
              <input
                :checked="contribution"
                :value="true"
                @change="contribution = true"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
              />
            </div>

            <easiTextInput
              v-if="gratuity"
              placeholder="Last Drawn Amount"
              type="text"
              :format="true"
              name="lastamount"
              class="mt-1 mb-3 md:mb-1"
              required
              :emp="true"
            ></easiTextInput>

            <easiTextInput
              v-if="gratuity"
              placeholder="Number of Years Worked"
              type="text"
              :format="true"
              name="amount"
              class="mt-1 mb-3 md:mb-1"
              required
              :emp="true"
            ></easiTextInput>

            <easiTextInput
              v-if="gratuity"
              placeholder="Amount"
              type="text"
              :format="true"
              name="amount"
              class="mt-1 mb-3 md:mb-1"
              required
              :emp="true"
            ></easiTextInput>
          </div>
        </div>
      </div>
      <div></div>
    </div>

    <div v-if="error" class="text-xs font-medium text-error -mt-2 mb-2">
      {{ error }}
    </div>
    <div
      @click="isSelect = false"
      :class="!isSelect ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script setup>
import pensionProviders from "@/utils/pensionProviders";
import HmoProviders from "@/utils/HmoProviders";
import lifeInsuranceBodies from "@/utils/lifeInsuranceBodies";
import { helperFunctions } from "@/composable/helperFunctions";
import NigerianStates from "@/utils/states";

import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

const { truncateAmount, formatAmount } = helperFunctions;

const store = useDataStore();
const route = useRoute();
const { query, set } = store;
const { getAllEmployees, checkLoading } = storeToRefs(store);
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);
const contribution = ref(false);

const props = defineProps({
  value: {
    default: [],
  },

  placeHolder: {
    type: String,
    default: "",
  },
  grossAmount: {
    type: [Number, String],
    default: 0,
  },
  salary: {
    type: Boolean,
    default: false,
  },
  clear: {
    type: Boolean,
    default: false,
  },
  usePercent: {
    type: Boolean,
    default: true,
  },
  single: {
    type: Boolean,
    default: false,
  },
  clearAll: {
    type: Boolean,
    default: false,
  },
  compliance: {
    type: Boolean,
    default: false,
  },
  paye: {
    type: Boolean,
    default: false,
  },
  input: {
    type: Boolean,
    default: false,
  },

  pension: {
    type: Boolean,
    default: false,
  },

  gratuity: {
    type: Boolean,
    default: false,
  },

  hideList: {
    type: Boolean,
    default: false,
  },

  error: {
    type: [String, Boolean],
    default: false,
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  validate: {
    type: Boolean,
    default: false,
  },
  forFounder: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  directorView: {
    type: Boolean,
    default: false,
  },
});
let optionArray = ref([]);

const emit = defineEmits(["update", "args", "remove"]);
const allChecked = ref(false);
const allSelected = ref(false);

const payeOpt = ref(false);
const optionData = ref([]);
const removedData = ref([]);
// optionData.value = totalEmp;
const selected = ref("");
const searchQuery = ref("");

const isSelect = ref(false);

const createEmpList = () => {
  let list = [];
  list =
    allEmployees.value.length &&
    allEmployees.value.map((emp) => {
      return {
        label: `${emp.profile.firstName} ${emp.profile.lastName}`,
        value: emp._id,
      };
    });
  return list;
};
const clearArr = computed(() => props.clearAll);
watch(
  clearArr,
  (newVal, oldVal) => {
    allChecked.value = false;
    allSelected.value = false;
    optionArray.value = [];

    // console.log(newVal, oldVal, "PROPS.VALUE");
  },
  { deep: true }
);

const totalEmp = ref(createEmpList());
const propOptions = computed(() => props.options);

optionData.value = propOptions.value; //Switched to rely on data from parent component

function addAll() {
  allChecked.value = !allChecked.value;
  allSelected.value = !allSelected.value;
  optionArray.value.splice(0);
  if (allChecked.value) {
    if (props.forFounder) {
      props.options.forEach((el) => {
        optionArray.value.push(el.value);
      });
    } else {
      allEmployees.value.forEach((el) => {
        optionArray.value.push(el._id);
      });
    }
  }
  optionArray.value = [...new Set(optionArray.value)];
  emit("update", optionArray.value);
}
function getNetSalary(id) {
  const emp = allEmployees.value.filter((emp) => emp._id === id);
  if (emp) {
    return emp[0].prorate.staffLevel
      ? emp[0].prorate.staffLevel.netAmount
      : emp[0].staffLevel && emp[0].staffLevel.netAmount;
  }
}
function getStaffLevelFromId(id) {
  const SL = allEmployees.value.filter((emp) => emp._id === id);
  return SL[0].staffLevel;
}

function getLabelFromValue(value) {
  if (value) {
    const label = props.options.filter((item) => {
      return item.value === value;
    });

    return label && label[0] ? label[0].label : undefined;
  }
  return "";
}
function formatValue(val) {
  const newVal = val.map((a) => {
    return getLabelFromValue(a);
  });
  return newVal;
}
watch(
  allEmployees,
  (val) => {
    console.log(val);
    // const totalEmp = createEmpList();
    optionData.value = props.options;
  },
  { deep: true }
);
function searchFunction(e) {
  const name = e.target.value;
  let data;
  if (name.length) {
    if (e.key !== "Backspace") {
      data = totalEmp.value.filter((x) => {
        return x.label.toLowerCase().indexOf(name.toLowerCase()) > -1
          ? x.label
          : "";
      });
    } else {
      console.log(searchQuery.value);
      data = totalEmp.value.filter((x) => {
        return x.label.toLowerCase().indexOf(searchQuery.value.toLowerCase()) >
          -1
          ? x.label
          : "";
      });
    }

    if (data.length) {
      optionData.value = data;
      return data;
    }
  } else {
    optionData.value = props.options;
  }
}
function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const initials = computed(() => {
  return (name) => {
    let color;
    // Get color

    if (name) {
      const arr = name.split(" ");
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const initialOne = String(arr[0]).charAt(0);
      const initialTwo = String(arr[1]).charAt(0);
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = getColor(num);
      const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
        .charAt(0)
        .toUpperCase()}`;
      return { str, color };
    }
    return { str: "", color: "" };
  };
});

// function formatString(strn) {
//   const abbr = ["hmo", "itf", "nsitf", "paye", "nhf", "wht"];
//   if (strn) {
//     if (abbr.includes(strn)) {
//       return strn.toUpperCase();
//     } else {
//       return strn.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
//         return str.toUpperCase();
//       });
//     }
//   }
// }

// function toggleView(option) {
//   if (option === selected.value) {
//     selected.value = "";
//     return true;
//   }
//   selected.value = option;
// }
function update(param) {
  if (param) {
    if (props.single) {
      if (optionArray.value.includes(param)) {
        let index = optionArray.value.indexOf(param);
        if (index > -1) {
          optionArray.value.splice(index, 1);
        }
      } else {
        optionArray.value = [];
        optionArray.value.push(param);
        isSelect.value = false;
      }
    } else {
      if (optionArray.value.includes(param)) {
        let index = optionArray.value.indexOf(param);
        if (index > -1) {
          optionArray.value.splice(index, 1);
        }
        removedData.value.push(param);
      } else {
        optionArray.value.push(param);
        let index = removedData.value.indexOf(param);
        if (index > -1) {
          removedData.value.splice(index, 1);
        }
        nextTick();
      }
    }
    if (props.compliance) {
      emit("remove", removedData.value);
    }
    emit("update", optionArray.value);
  }
}

let searchText = ref("");
let isSearch = ref(false);

const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: {
      firstName: "ASC",
    },
  },
  filter: [],
});

const filterFN = ({ filter }) => {
  isSearch.value = true;
  filterOptions.value = filter;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  console.log("Payload");
  queryEmployees();
};

const queryEmployees = async () => {
  //   loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    // loading.value = false;
    console.log("Employees");
  } catch (err) {
    // loading.value = false;
    console.log(err);
  }
};
onMounted(() => {
  // console.log(props.value);
  queryEmployees();
  if (props.value.length > 0) {
    optionArray.value = props.value;
  }
  if (
    optionArray.value.length === props.options.length &&
    props.options.length > 0
  ) {
    allSelected.value = true;
    allChecked.value = true;
  }
  // console.log(props.value, "INITIAl");
});

function updateArgs() {
  console.log(staffLevel);
  emit("args", staffLevel);
}
</script>

<style></style>
