<template>
  <!-- class="" -->
  <nav
    class="no-printable no-scrollbar shadow-md sticky relative top-0 left-0 bg-white flex flex-wrap items-center justify-between z-50 py-0 px-0 md:shadow-xl md:w-60 md:block md:fixed md:left-0 md:top-0 md:bottom-0 md:flex-row md:flex-nowrap lg:overflow-y-auto"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full sticky md:p-0 md:mx-auto"
    >
      <router-link
        class="md:block hidden text-left md:pb-0 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm capitalize font-bold p-0 px-0 mb-4"
        to="/"
      >
        <span class="w-full">
          <img :src="logo" alt="Eazipay" />
        </span>
      </router-link>
      <!-- User -->
      <div v-if="!otherRoutes" class="w-full">
        <div
          class="md:hidden flex flex-wrap w-full"
          v-for="link in links"
          :key="link.main"
        >
          <div
            v-if="$route.path.includes(link.pathKey)"
            class="w-full p-4 flex gap-2 justify-between items-center overflow-x-auto no-scrollbar py-6"
          >
            <div
              v-for="sub in link.subs"
              :key="sub.name"
              class="inline-block relativ"
            >
              <router-link
                @click="moveCenter($event)"
                :to="sub.path"
                v-slot="{ href, navigate }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="text-sm whitespace-nowrap px-2"
                  :class="[
                    $route.path.includes(sub.path)
                      ? 'text-primary font-medium border-b-2 border-primary '
                      : 'text-gray hover:text-primary',
                    sub.name.toLowerCase() === 'edit salary' &&
                    $route.path.includes('bulk')
                      ? 'text-primary font-medium border-b-2 border-primary '
                      : 'text-gray hover:text-primary',
                  ]"
                  >{{ sub.name }}
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none px-6 py-3 md:p-0 shadow absolute inset-0 z-50 overflow-y-auto overflow-x-hidden h-screen items-center flex-1"
        :class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 my-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex items-center justify-between">
            <button
              type="button"
              class="cursor-pointer"
              v-on:click="toggleCollapseShow('hidden')"
            >
              <img src="@/assets/icons/close.svg" alt="" />
            </button>
            <button
              type="button"
              class="cursor-pointer flex items-center gap-2"
              v-on:click="logout"
            >
              <img src="@/assets/icons/active-logout.svg" alt="" />
              <span class="text-secondary text-sm font-medium">Logout</span>
            </button>
          </div>
          <div class="w-full flex mt-4 p-4 easiGradient">
            <img
              class="w-28 mx-auto"
              src="@/assets/img/eazi-text-logo.png"
              alt=""
            />
          </div>
        </div>

        <!-- Heading -->
        <!-- <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
          Admin Layout Pages
        </h6> -->
        <!-- Navigation -->

        <ul class="w-full flex flex-col list-none">
          <li class="flex-1 grow px-0">
            <router-link
              to="/admin/dashboard"
              v-slot="{ href, navigate, isActive }"
              :class="isActive ? 'active-bg' : undefined"
            >
              <a
                :href="href"
                @click="
                  () => {
                    toggleCollapseShow('hidden');
                    navigate;
                  }
                "
                class="text-sm text-left capitalize py-3 px-4 w-full h-full font-bold flex items-center gap-2"
                :class="[
                  isActive
                    ? 'text-primary  border-l border-5 active-bg border-primary'
                    : 'text-grey hover:text-primary ',
                ]"
              >
                <!-- <i
                  class="fas fa-home mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-gray']"
                ></i> -->

                <img
                  v-if="!isActive"
                  class="w-4 contain"
                  src="@/assets/icons/dashboard-icon-inactive.svg"
                  alt=""
                />
                <img
                  v-else
                  class="w-4 contain"
                  src="@/assets/icons/dashboard-active.svg"
                  alt=""
                />
                <span> Dashboard</span>
              </a>
              <!-- <i
                :class="isActive ? 'fas fa-angle-down' : 'fas fa-angle-right'"
                class="ursor-pointer text-base text-grey"
              /> -->
            </router-link>
          </li>
          <!-- Divider -->
          <hr class="mt-1 text-dividerColor md:min-w-full" />
          <!-- <li class="flex-1 grow mx-4">
            <router-link
              to="/admin/wallet/view-wallet"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate, toggleCollapseShow('hidden')"
                class="text-sm text-left uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-secondary '
                    : 'text-grey hover:text-secondary',
                ]"
              >
                <img
                  v-if="!isActive"
                  class="inline-block w-4 mr-2 -mt-1"
                  src="../../assets/icons/wallet.svg"
                  alt=""
                />
                <img
                  v-else
                  class="inline-block w-4 mr-2 -mt-1"
                  src="../../assets/icons/wallet-active.svg"
                  alt=""
                />
                Wallet
              </a>
            </router-link>
          </li>
          <hr class="my-1 text-dividerColor md:min-w-full" /> -->

          <!-- <li class="flex-1 grow">
            <router-link
              to="/admin/settings"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-tools mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Settings
              </a>
            </router-link>
          </li>

          <li class="flex-1 grow">
            <router-link
              to="/admin/tables"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-table mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Tables
              </a>
            </router-link>
          </li>

          <li class="flex-1 grow">
            <router-link to="/admin/maps" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="navigate"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-map-marked mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Maps
              </a>
            </router-link>
          </li> -->
        </ul>
        <div
          class="cursor-pointer w-full flex flex-col items-center justify-center accordion acc-single-open z-1"
        >
          <div
            v-for="(link, index) in links"
            :key="link.main"
            class="acc-container z-1 w-full grow"
            :class="[
              hasPrivilege(link.requiresPrivileges) ? undefined : 'hidden',
              $route.path.includes(link.pathKey)
                ? 'active-bg border-l border-5 border-primary'
                : undefined,
            ]"
          >
            <div
              @click="
                !link.subs && $router.push({ path: link.path }),
                  !link.subs && toggleCollapseShow('hidden')
              "
              class="flex justify-between items-center py-4 text-sm text-left capitalize font-bold mx-4 acc-title"
              :class="[
                $route.path.includes(link.pathKey)
                  ? 'text-primary '
                  : 'text-grey hover:text-primary',
              ]"
            >
              <div
                v-if="link.icon"
                class="pointer-events-none -z-1 mr-1 -mb-1 flex items-center gap-2"
              >
                <i :class="link.icon" class="mr-1"></i>
                <span class="pointer-events-none justify-self-start text-left">
                  {{ link.main }}</span
                >
              </div>
              <div
                class="pointer-events-none mr-1 -z-1 -mb-1 flex items-center gap-2"
                v-else
              >
                <img
                  v-if="!$route.path.includes(link.pathKey)"
                  class="w-4 contain"
                  :src="link.importIcon"
                  alt=""
                />
                <img
                  v-else
                  class="w-4 contain"
                  :src="link.importIconActive"
                  alt=""
                />
                <span
                  class="pointer-events-none -z-1 justify-self-start text-left"
                >
                  {{ link.main }}</span
                >
                <span
                  v-if="['Tax Filing Service', 'Api Keys'].includes(link.main)"
                  class="text-white bg-lightBorder py-0.5 px-1 text-xs rounded"
                  >New</span
                >
              </div>
              <span class="pointer-events-none -z-1">
                <img
                  v-if="['Api Keys'].includes(link.main)"
                  src="@/assets/icons/apiarrow.png"
                  class="h-5"
                  alt=""
                />

                <i
                  v-else
                  :class="
                    link.active ? 'fas fa-angle-down' : 'fas fa-angle-right'
                  "
                  class="inline-flex justify-end self-end text-right cursor-pointer text-sm text-grey"
                />
              </span>
            </div>
            <div
              v-for="sub in link.subs"
              :key="sub.name"
              :class="
                hasPrivilege(sub.requiresPrivileges) ? undefined : 'hidden'
              "
            >
              <!-- <p class="text-xs -z-1">
                <i :class="sub.icon" class="mr-2 text-xs text-blueGray-400"></i>
                
                {{ sub.name }}
              </p> -->
              <div
                v-if="isAllowedOnPersonalAccount(sub)"
                class="text-left px-4 acc-content -z-1"
              >
                <router-link :to="sub.path" v-slot="{ href, navigate }">
                  <a
                    :href="
                      hasPrivilege(sub.requiresPrivileges) ? href : undefined
                    "
                    @click="
                      hasPrivilege(sub.requiresPrivileges)
                        ? navigate
                        : undefined,
                        toggleCollapseShow('hidden')
                    "
                    class="text-left mx-4 text-sm font-medium -z-1"
                    :class="[
                      !hasPrivilege(sub.requiresPrivileges)
                        ? 'disabled-link text-dark-400  hover:text-dark-400 '
                        : undefined,
                      $route.path.includes(sub.path)
                        ? 'text-secondary '
                        : 'text-grey hover:text-primary',
                    ]"
                  >
                    <!-- <i
                      v-if="!sub.active"
                      class="mr-3 text-sm"
                      :class="[
                        $route.path.includes(sub.path)
                          ? 'opacity-75'
                          : 'text-gray-300',
                        sub.icon,
                      ]"
                    ></i>
                    <img
                      v-else
                      :src="
                        $route.path.includes(sub.path) ? sub.active : sub.icon
                      "
                      class="inline-block w-4 mr-2 -mt-1"
                      alt=""
                    /> -->
                    <img
                      :src="
                        $route.path.includes(sub.path)
                          ? activeBulletPoint
                          : inactiveBulletPoint
                      "
                      class="inline-block mr-2 -mt-3px text-secondary"
                      alt=""
                    />
                    {{ sub.name }}
                  </a>
                </router-link>
              </div>
            </div>
            <hr
              v-show="index !== links.length - 1"
              class="my-0 text-dividerColor md:min-w-full"
            />
            <!-- acc-content -->
          </div>
          <!-- acc-container -->
        </div>

        <hr class="mt-16 mb-7 text-dividerColor mx-2 w-11/12" />

        <div
          class="cursor-pointer pb-20 md:pb-0 w-full flex flex-col items-center justify-center accordion acc-single-open z-1"
        >
          <div
            v-for="link in extraLinks"
            :key="link.main"
            class="acc-container z-1 w-full grow"
            :class="[
              $route.path.includes(link.pathKey)
                ? 'active-bg border-l border-5 border-primary'
                : undefined,
            ]"
          >
            <div
              @click="
                !link.subs && $router.push({ path: link.path }),
                  !link.subs && toggleCollapseShow('hidden')
              "
              class="flex justify-between items-center py-4 text-sm text-left capitalize font-bold mx-4 acc-title"
              :class="[
                $route.path.includes(link.pathKey)
                  ? 'text-primary '
                  : 'text-grey hover:text-primary',
              ]"
            >
              <div
                v-if="link.icon"
                class="pointer-events-none -z-1 mr-1 -mb-1 flex items-center gap-2"
              >
                <i :class="link.icon" class="mr-1"></i>
                <span class="pointer-events-none justify-self-start text-left">
                  {{ link.main }}</span
                >
              </div>
              <div
                class="pointer-events-none mr-1 -z-1 -mb-1 flex items-center gap-2"
                v-else
              >
                <img
                  v-if="!$route.path.includes(link.pathKey)"
                  class="w-4 contain"
                  :src="link.importIcon"
                  alt=""
                />
                <img
                  v-else
                  class="w-4 contain"
                  :src="link.importIconActive"
                  alt=""
                />
                <span
                  class="pointer-events-none -z-1 justify-self-start text-left"
                >
                  {{ link.main }}</span
                >
              </div>
              <span class="pointer-events-none -z-1">
                <i
                  :class="
                    link.active ? 'fas fa-angle-down' : 'fas fa-angle-right'
                  "
                  class="inline-flex justify-end self-end text-right cursor-pointer text-sm text-grey"
                />
              </span>
            </div>
            <div
              v-for="sub in link.subs"
              :key="sub.name"
              :class="
                hasPrivilege(sub.requiresPrivileges) ? undefined : 'hidden'
              "
            >
              <!-- <p class="text-xs -z-1">
                <i :class="sub.icon" class="mr-2 text-xs text-blueGray-400"></i>
                
                {{ sub.name }}
              </p> -->
              <div
                v-if="isAllowedOnPersonalAccount(sub)"
                class="text-left px-4 acc-content -z-1"
              >
                <router-link :to="sub.path" v-slot="{ href, navigate }">
                  <a
                    :href="href"
                    @click="navigate, toggleCollapseShow('hidden')"
                    class="text-left mx-4 text-sm font-medium -z-1"
                    :class="[
                      $route.path.includes(sub.path)
                        ? 'text-primary '
                        : 'text-grey hover:text-primary',
                    ]"
                  >
                    <!-- <i
                      v-if="!sub.active"
                      class="mr-3 text-sm"
                      :class="[
                        $route.path.includes(sub.path)
                          ? 'opacity-75'
                          : 'text-gray-300',
                        sub.icon,
                      ]"
                    ></i>
                    <img
                      v-else
                      :src="
                        $route.path.includes(sub.path) ? sub.active : sub.icon
                      "
                      class="inline-block w-4 mr-2 -mt-1"
                      alt=""
                    /> -->
                    <img
                      :src="
                        $route.path.includes(sub.path)
                          ? activeBulletPoint
                          : inactiveBulletPoint
                      "
                      class="inline-block mr-2 -mt-3px text-secondary"
                      alt=""
                    />
                    {{ sub.name }}
                  </a>
                </router-link>
              </div>
            </div>
            <hr class="my-0 text-dividerColor md:min-w-full" />
            <!-- acc-content -->
          </div>
          <!-- acc-container -->
        </div>
        <!-- accordion -->
      </div>

      <!-- Button Navigation -->
      <div
        v-if="!checkFromApp"
        class="md:hidden w-full z-50 fixed bottom-0 left-0 px-2 bg-white flex justify-around"
        style="box-shadow: 0 4px 16px 2px rgba(0, 0, 0, 0.2)"
      >
        <div
          v-for="link in bottomLinks"
          :key="link.name"
          @click="$router.push(link.path)"
          class="flex flex-col items-center gap-1 p-4 cursor-pointer"
        >
          <img
            class="w-6"
            :src="$route.path.includes(link.pathKey) ? link.active : link.icon"
            alt=""
          />
          <span
            :class="
              $route.path.includes(link.pathKey)
                ? 'text-primary'
                : 'text-dark-900'
            "
            class="text-xs font-medium"
            >{{ link.name }}</span
          >
        </div>
        <div
          @click="
            collapseShow.includes('hidden')
              ? toggleCollapseShow('bg-white')
              : toggleCollapseShow('hidden')
          "
          :class="otherRoutes ? 'text-primary' : 'text-dark-900'"
          class="flex flex-col items-center justify-center gap-1 p-4 cursor-pointer py-3 px-6 md:py-0 md:px-0"
        >
          <!-- <i
            :class="otherRoutes ? 'text-secondary' : 'text-dark-600'"
            class="fas fa-bars text-xl"
          ></i> -->
          <img :src="otherRoutes ? newMore : more" alt="Eazipay" />

          <span class="text-xs font-medium">More</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { logout } from "@/api/api";

// import logo from "@/assets/img/eaziLogo.png";
import logo from "@/assets/img/eazi-banner.png";
import home from "@/assets/icons/home.svg";
import homeActive from "@/assets/icons/newHomeActive.svg";

import newMore from "@/assets/icons/newMore.svg";
import more from "@/assets/icons/moreInactive.svg";

import payroll from "@/assets/icons/payroll.svg";
import payrollActive from "@/assets/icons/newPayrollActive.svg";

import employee from "@/assets/icons/employee.svg";
import employeeActive from "@/assets/icons/newEmployeeActive.svg";

import walletIcon from "../../assets/icons/wallet.svg";
import walletIconActive from "@/assets/icons/wallet-active.svg";

import invoice from "@/assets/icons/invoice.svg";
import activeInvoice from "@/assets/icons/active-invoice.svg";

import edit from "@/assets/icons/edit-dollar.svg";
import editActive from "@/assets/icons/edit-dollar-active.svg";

import building from "@/assets/icons/building.svg";
import activeBuilding from "@/assets/icons/active-building.svg";

import userAdd from "@/assets/icons/user-add.svg";
import activeUserAdd from "@/assets/icons/active-user-add.svg";

import payrollIcon from "@/assets/icons/payroll-icon-inactive.svg";
import activePayrollIcon from "@/assets/icons/payroll-icon-active.svg";

import salaryAdd from "@/assets/icons/salary-add.svg";
import activeSalaryAdd from "@/assets/icons/active-salary-add.svg";

import activeBulletPoint from "@/assets/icons/activeBulletPoint.svg";
import inactiveBulletPoint from "@/assets/icons/newBulletPoint.svg";

import userView from "@/assets/icons/user-view.svg";
import activeUserView from "@/assets/icons/active-user-view.svg";

import userEdit from "@/assets/icons/user-edit.svg";
import activeUserEdit from "@/assets/icons/active-user-edit.svg";

import bonusAndDeductionIcon from "@/assets/icons/b-and-d.svg";
import activeBonusAndDeductionIcon from "@/assets/icons/b-and-d-active.svg";

import payrollhistory from "@/assets/icons/payrollhistory.svg";
import activePayrollhistory from "@/assets/icons/payrollhistory-active.svg";

import profile from "@/assets/icons/profile.svg";
import activeProfile from "@/assets/icons/profile-active.svg";

import manageAccount from "@/assets/icons/manage-account.svg";
import activeManageAccount from "@/assets/icons/manage-account-active.svg";

import businessProfile from "@/assets/icons/business-profile.svg";
import activeBusinessProfile from "@/assets/icons/business-profile-active.svg";

import team from "@/assets/icons/team.svg";
import activeTeam from "@/assets/icons/team-active.svg";

import security from "@/assets/icons/security.svg";
import activeSecurity from "@/assets/icons/security-active.svg";

import share from "@/assets/icons/share.svg";
import activeShare from "@/assets/icons/share-active.svg";
import activePricing from "@/assets/icons/share-active.svg";

import wallet from "@/assets/icons/wallet.svg";
import activeWallet from "@/assets/icons/wallet-active.svg";

import payslip from "@/assets/icons/payslip.svg";
import activePayslip from "@/assets/icons/payslip-active.svg";

import analysis from "@/assets/icons/analysis.svg";
import activeAnalysis from "@/assets/icons/analysis-active.svg";

import report from "@/assets/icons/report.svg";
import activeReport from "@/assets/icons/report-active.svg";

import regCompliance from "@/assets/icons/reg-compliance.svg";
import regComplianceActive from "@/assets/icons/reg-compliance-active.svg";

import plusInactive from "@/assets/icons/plus-inactive.svg";
import plusActive from "@/assets/icons/plus-active.svg";

import viewCompliance from "@/assets/icons/view-compliance.svg";
import viewComplianceActive from "@/assets/icons/view-compliance-active.svg";

import editCompliance from "@/assets/icons/edit-compliance.svg";
import editComplianceActive from "@/assets/icons/edit-compliance-active.svg";

import payrollSettings from "@/assets/icons/settings-icon.svg";
import activePayrollSettings from "@/assets/icons/settings-icon-active.svg";

import callIcon from "@/assets/icons/call.svg";
// import activePayrollSettings from "@/assets/icons/settings-icon-active.svg";

import loanApplication from "@/assets/icons/loan-application.svg";
import ActiveLoanApplication from "@/assets/icons/loan-application-active.svg";

import bookKeepingIcon from "@/assets/icons/bookkeeping.svg";
import ActiveBookKeepingIcon from "@/assets/icons/bookkeeping-active.svg";

import loanManage from "@/assets/icons/loan-manage.svg";
import ActiveLoanManage from "@/assets/icons/loan-manage-active.svg";
import taxFiling from "@/assets/icons/taxFiling.svg";
import TaxFilingActive from "@/assets/icons/taxFilingActive.svg";
import apiKeys from "@/assets/icons/key.png";

import loanHistory from "@/assets/icons/loan-history.svg";
import ActiveLoanHistory from "@/assets/icons/loan-history-active.svg";

import { mapWritableState, storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";

export default {
  setup() {
    const store = useDataStore();

    const { checkFromApp } = storeToRefs(store);
    return { checkFromApp };
  },
  data() {
    return {
      newMore,
      isActive: false,
      more,
      logout,
      logo: logo,
      collapseShow: "hidden",
      collapse: false,
      activeBulletPoint,
      inactiveBulletPoint,
      links: [
        {
          active: false,
          main: "Wallet",
          icon: null,
          importIcon: walletIcon,
          importIconActive: walletIconActive,
          pathKey: "wallet",
          path: "/admin/wallet/view-wallet",
          subs: null,
          requiresPrivileges: [
            "fund_wallet",
            "withdraw_to_bank",
            "transaction_history",
          ],
        },
        {
          active: false,
          main: "Employee Management",
          // icon: "fas fa-user",
          importIcon: userAdd,
          importIconActive: activeUserAdd,
          pathKey: "employee",
          requiresPrivileges: [
            "view_employee",
            "add_employee",
            "edit_employee",
            "payroll_preparation",
          ],
          subs: [
            {
              name: "Add Employee",
              link: "AddEmployee",
              path: "/employee/add",
              icon: userAdd,
              active: activeUserAdd,
              requiresPrivileges: ["add_employee"],
            },
            {
              name: "View Employee",
              link: "ViewEmployee",
              path: "/employee/view",
              icon: userView,
              active: activeUserView,
              requiresPrivileges: [
                "view_employee",
                "edit_employee",
                "payroll_preparation",
              ],
            },
            {
              name: "Edit Employee",
              link: "EditEmployee",
              path: "/employee/edit",
              icon: userEdit,
              active: activeUserEdit,
              requiresPrivileges: ["edit_employee", "payroll_preparation"],
            },
          ],
        },
        {
          active: false,
          main: "Payroll",
          // icon: "fas fa-money-bill-alt",
          importIcon: payrollIcon,
          importIconActive: activePayrollIcon,
          pathKey: "payroll",
          requiresPrivileges: [
            "payroll_preparation",
            "add_payroll",
            "view_payroll",
            "edit_payroll",
            "run_payroll",
            "manage_bonus",
            "manage_deductions",
            "manage_payslips",
          ],
          subs: [
            {
              name: "Add Salary",
              link: "AddSalary",
              path: "/payroll/add",
              icon: salaryAdd,
              active: activeSalaryAdd,
              requiresPrivileges: ["add_payroll", "payroll_preparation"],
            },
            {
              name: "View Salary",
              link: "ViewSalary",
              path: "/payroll/view",
              icon: invoice,
              active: activeInvoice,
              requiresPrivileges: [
                "view_payroll",
                "edit_payroll",
                "payroll_preparation",
              ],
            },
            {
              name: "Edit Salary",
              link: "EditSalary",
              path: "/payroll/edit",
              icon: edit,
              active: editActive,
              requiresPrivileges: [
                // "view_payroll",
                "edit_payroll",
                "payroll_preparation",
              ],
            },
            {
              name: "Bonus And Deduction",
              link: "BonusAndDeduction",
              path: "/payroll/salary/bonus-and-deductions",
              icon: bonusAndDeductionIcon,
              active: activeBonusAndDeductionIcon,
              requiresPrivileges: [
                "manage_bonus",
                "manage_deductions",
                "edit_payroll",
                "payroll_preparation",
              ],
            },
            {
              name: "Run Payroll",
              link: "PaySalary",
              path: "/payroll/paysalary",
              icon: building,
              active: activeBuilding,
              requiresPrivileges: ["run_payroll"],
            },
            {
              name: "Payslip",
              link: "Payslip",
              path: "/payroll/payslip",
              icon: payslip,
              active: activePayslip,
              requiresPrivileges: ["manage_payslips"],
            },
            {
              name: "Payroll History",
              link: "PayrollHistory",
              path: "/payroll/payroll-history",
              icon: payrollhistory,
              active: activePayrollhistory,
              requiresPrivileges: ["manage_payslips"],
            },
            {
              name: "Payroll Report",
              link: "PayrollReport",
              path: "/payroll/payroll-report",
              icon: report,
              active: activeReport,
              requiresPrivileges: ["manage_payslips"],
            },
            {
              name: "Payroll Analysis",
              link: "PayrollAnalysis",
              path: "/payroll/payroll-analysis",
              icon: analysis,
              active: activeAnalysis,
              requiresPrivileges: ["manage_payslips"],
            },
          ],
        },
        {
          active: false,
          main: "Compliance",
          icon: null,
          importIcon: regCompliance,
          importIconActive: regComplianceActive,
          pathKey: "compliance",
          path: "/compliance/register",
          requiresPrivileges: [
            "add_compliance",
            "view",
            "edit_compliance",
            "pay_compliance",
          ],
          subs: null,
          // subs: [
          //   {
          //     name: "Register Compliance",
          //     link: "RegisterCompliance",
          //     path: "/compliance/register",
          //     icon: regCompliance,
          //     active: regComplianceActive,
          //   },
          //   {
          //     name: "Add Compliance",
          //     link: "AddCompliance",
          //     path: "/compliance/add",
          //     icon: plusInactive,
          //     active: plusActive,
          //   },
          //   {
          //     name: "View Compliance",
          //     link: "ViewCompliance",
          //     path: "/compliance/view",
          //     icon: viewCompliance,
          //     active: viewComplianceActive,
          //   },
          //   {
          //     name: "Edit Compliance",
          //     link: "EditCompliance",
          //     path: "/compliance/edit",
          //     icon: editCompliance,
          //     active: editComplianceActive,
          //   },
          //   {
          //     name: "Pay Compliance",
          //     link: "PayCompliance",
          //     path: "/compliance/pay",
          //     icon: bonusAndDeductionIcon,
          //     active: activeBonusAndDeductionIcon,
          //   },
          // ],
        },
        {
          active: false,
          main: "Tax Filing Service",
          icon: null,
          importIcon: taxFiling,
          importIconActive: TaxFilingActive,
          pathKey: "tax-filing",
          path: "/admin/tax-filing",
          subs: null,
          requiresPrivileges: [],
        },
        {
          active: false,
          main: "Api Keys",
          icon: null,
          importIcon: apiKeys,
          importIconActive: apiKeys,
          pathKey: "api-keys",
          path: "/developers/dashboard/overview",
          subs: null,
          requiresPrivileges: ["api"],
        },
        {
          active: false,
          main: "Quick Loan",
          icon: null,
          importIcon: loanManage,
          importIconActive: ActiveLoanManage,
          requiresPrivileges: ["loan_application", "loan_payback"],

          // pathKey: "loan",
          // path: "/loan/application/view",
          // subs: null,
          subs: [
            {
              name: "Application",
              link: "LoanApplication",
              path: "/loan/application/view",
              icon: loanApplication,
              active: ActiveLoanApplication,
              requiresPrivileges: ["loan_application"],
            },
            {
              name: "Manage Loans",
              link: "ManageLoans",
              path: "/loan/manage",
              icon: loanManage,
              active: ActiveLoanManage,
              requiresPrivileges: ["loan_payback"],
            },
            // {
            //   name: "Loan History",
            //   link: "LoanHistory",
            //   path: "/loan/history",
            //   icon: loanHistory,
            //   active: ActiveLoanHistory,
            // requiresPrivileges: ["loan_payback"]
            // },
          ],
        },

        // {
        //   active: false,
        //   main: "Book Keeping",
        //   icon: null,
        //   importIcon: bookKeepingIcon,
        //   importIconActive: ActiveBookKeepingIcon,
        //   pathKey: "bookkeepinng",
        //   path: null,
        //   subs: null,
        // },
      ],

      extraLinks: [
        {
          active: false,
          main: "Support",
          icon: null,
          importIcon: callIcon,
          importIconActive: callIcon, //Change later
          pathKey: "support",
          subs: null,
          path: "/admin/support/talk-to-us",
        },

        {
          active: false,
          main: "Settings",
          icon: "fas fa-cog",
          pathKey: "settings",
          subs: [
            {
              name: "Profile",
              link: "Profile",
              path: "/settings/profile",
              icon: profile,
              active: activeProfile,
              // requiresPrivileges: ["manage_account"],
            },
            {
              name: "Manage Account",
              link: "ManageAccount",
              path: "/settings/manage-account",
              icon: manageAccount,
              active: activeManageAccount,
              requiresPrivileges: ["manage_account"],
            },
            {
              name: "Business Profile",
              link: "BusinessProfile",
              path: "/settings/business-profile",
              icon: businessProfile,
              active: activeBusinessProfile,
              requiresPrivileges: ["manage_business_profile"],
            },
            {
              name: "Payroll Settings",
              link: "PayrollSettings",
              path: "/settings/payment",
              icon: payrollSettings,
              active: activePayrollSettings,
              requiresPrivileges: ["manage_security", "payroll_preparation"],
            },
            {
              name: "Team",
              link: "Team",
              path: "/settings/team",
              icon: team,
              active: activeTeam,
              requiresPrivileges: ["manage_team"],
            },
            {
              name: "Security",
              link: "Security",
              path: "/settings/security",
              icon: security,
              active: activeSecurity,
              // requiresPrivileges: ["manage_security"],
            },
            {
              name: "Pricing",
              link: "Pricing",
              path: "/settings/pricing",
              icon: security,
              active: activePricing,
              requiresPrivileges: ["manage_security"],
            },
            {
              name: "Refer",
              link: "Refer",
              path: "/settings/refer",
              icon: share,
              active: activeShare,
            },
          ],
        },
      ],

      bottomLinks: [
        {
          name: "Home",
          icon: home,
          active: homeActive,
          pathKey: "dashboard",
          path: "/admin/dashboard",
        },
        {
          name: "Payroll",
          icon: payroll,
          active: payrollActive,
          pathKey: "payroll",
          path: "/payroll/view",
        },

        {
          name: "Employee Mgt.",
          icon: employee,
          active: employeeActive,
          pathKey: "employee",
          path: "/employee/view",
        },
      ],
    };
  },
  computed: {
    ...mapWritableState(useDataStore, ["getCompanyDetails"]),
    companyData: (store) => store.getCompanyDetails,
    otherRoutes() {
      if (
        this.$route.path.includes("settings") ||
        this.$route.path.includes("wallet")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    hasPrivilege(privilegeArr) {
      const accountType = window.localStorage.getItem("accountType");
      if (accountType && accountType !== "EMPLOYEE") {
        return true;
      }
      if (privilegeArr) {
        const storedArrayString = window.localStorage.getItem("privilege");
        const privileges = JSON.parse(storedArrayString);

        return privilegeArr && privilegeArr && privileges
          ? privileges.some((element) =>
              privilegeArr.includes(element.toLowerCase())
            )
          : false;
      }
      return true;
    },
    isAllowedOnPersonalAccount(sub) {
      const isPersonal =
        this.companyData && this.companyData.registrationType !== "COMPANY";

      if (isPersonal && sub.link === "BusinessProfile") {
        return false;
      } else {
        return true;
      }
    },
    toggleCollapseShow: function (classes, i) {
      console.log(classes);
      if (i !== undefined) {
        this.links[i].active = !this.links[i].active;
      }
      this.collapseShow = classes;
    },

    moveCenter: function (e) {
      const element = e.target;
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },
};
</script>

<style scoped>
.accordion {
  margin: 0 0 10px 0;
}

.acc-container {
  /* margin: 0 0 10px; */
  max-width: 100%;
  overflow: hidden;
}

.acc-title {
  max-width: 100%;
  z-index: 999;
  /* box-shadow: #4e4e4e; */
  /* background: rgba(242, 247, 253, 0.5); */
  /* color: #4e4e4e; */
  /* border-radius: 4px; */
  /* padding: 14px 20px; */
  /* font-size: 16px; */
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease-in-out;
}

/* .acc-active .acc-title,
.acc-title:hover {
  background: #183059;
  color: white;
  cursor: pointer;
} */

.acc-title:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 25 25'%3E%3Cline x1='6' y1='12.5' x2='19' y2='12.5' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3Cline y1='6' x1='12.5' y2='19' x2='12.5' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E"); /*Plus sign*/
  position: absolute;
  right: 20px;
  top: 50%;
  /* margin-top: -12.5px; */
  width: 25px;
  height: 25px;
  display: none;
}

.acc-active .acc-title:after {
  display: none;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 25 25'%3E%3Cline x1='6' y1='12.5' x2='19' y2='12.5' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E"); /*Minus sign*/
}

.acc-content {
  background: #f8f8f8;
  width: 100%;
  height: auto;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  padding: 0;
  transition: all 0.2s ease-in-out;
}

.acc-active .acc-content {
  /* font-size: 16px; */
  padding: 10px 0;
  max-height: 500px;
  overflow-y: auto;
  opacity: 1;
}

.active-bg {
  background: rgba(240, 247, 235, 0.5);
}

.disabled-link {
  text-decoration: none; /* Remove the underline */
  /* cursor: not-allowed; Change the cursor to indicate non-interactivity */
  pointer-events: none; /* Disable pointer events */
}
</style>

<!-- md:left-0 no-printable md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:shadow-xl shadow-md sticky top-0 left-0 bg-white flex flex-wrap items-center justify-between relative md:w-60 z-50 py-0 px-0 -->
