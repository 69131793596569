<template>
  <div
    class="grid grid-cols-2 justify-between items-center justify-items-start w-full"
  >
    <div class="flex gap-4 md:gap-4 justify-self-start md:pl-4 items-center">
      <div
        class="w-12 h-12 flex flex-col items-center justify-center rounded-xl bg-mint"
      >
        <img
          class="w-7 h-7 self-center"
          :src="getPackageIcon(plan.description)"
          alt=""
        />
      </div>
      <span class="text-bodyDark font-bold">{{ plan.description }}</span>
    </div>
    <div
      v-if="showCurrentPlan"
      style="background: #21965333"
      class="w-32 h-8 rounded-full text-primary flex justify-center justify-self-end items-center gap-x-4 font-medium"
    >
      <span class="text-sm text-center">Current plan</span>
      <img src="@/assets/icons/tag.svg" alt="" />
    </div>

    <div class="split-container w-full col-span-2 mt-4 p-4 rounded-xl">
      <div
        v-for="(data, i) in Object.keys(fieldKeys)"
        :key="i"
        class="grid grid-cols-1 md:grid-cols-2 justify-start gap-x-6 items-center"
      >
        <span
          :class="
            benefitsOfPlan &&
            benefitsOfPlan.length &&
            i == benefitsOfPlan.length - 1
              ? '-mt-12'
              : ''
          "
          class="border-b border-outlineGray font-medium w-11/12 pt-2 pb-3 hidden md:block text-sm capitalize justify-start"
        >
          {{ data && fieldKeys[data] }}
        </span>
        <!-- <hr class="w-64 justify-self-end self-end" /> -->

        <div
          class="w-full md:w-11/12 bg-transparent font-medium md:pl-3 pt-3 z-20 pb-3 text-sm"
        >
          <span v-if="benefitsOfPlan && benefitsOfPlan.length">
            {{
              data === "monthlySub"
                ? benefitsOfPlan[i] === 0
                  ? "Free"
                  : formatAmount(benefitsOfPlan[i])
                : benefitsOfPlan[i]
            }}
          </span>

          <div
            v-show="
              benefitsOfPlan &&
              benefitsOfPlan.length &&
              i == benefitsOfPlan.length - 1
            "
            @click="handleSelected(plan)"
            class="w-full mt-4 font-medium cursor-pointer text-center bg-primary border border-primary rounded-full text-white md:border px-3 text-sm py-3"
            :class="showCurrentPlan ? 'invisible' : undefined"
          >
            {{ upgrade === true ? "Upgrade to plan" : "Downgrade to plan" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import basicIcon from "@/assets/icons/basicIcon.svg";
import StartupIcon from "@/assets/icons/startupicon.svg";
import businessIcon from "@/assets/icons/businessicon.svg";
import businessplusIcon from "@/assets/icons/businessplusicon.svg";

import starter from "@/assets/icons/easi-starter.svg";
import plus from "@/assets/icons/easi-plus.svg";
import premium from "@/assets/icons/easi-premium.svg";
import business from "@/assets/icons/easi-business.svg";

import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;
const emit = defineEmits(["onSelected"]);
const isSelect = ref(false);
const isMonthly = ref(true);
const showBenefits = ref(false);

const thisPlan = ref({
  updatedAt: "",
  rate: "",
  packageName: "",
  level: 0,
  description: "",
  custom: false,
  staffSubscriptions: [
    {
      updatedAt: "",
      subscriptionId: "",
      staffPackagePrice: 0,
      staffNoMin: 0,
      staffNoMax: 0,
      createdAt: "",
      _id: "",
      __typename: "",
    },
  ],
  createdAt: "",
  companyId: null,
  benefits: [""],
  basePackagePrice: 0,
  _id: "",
  __typename: "",
});
const props = defineProps({
  plan: {
    type: Object,
    default: [],
  },
  companyId: {
    type: String,
    default: "",
  },
  showCurrentPlan: {
    type: Boolean,
    default: false,
  },
  upgrade: {
    type: Boolean,
    default: false,
  },
});
const isLoading = ref(false);
onMounted(() => {
  if (props.plan) {
    if (props.plan.rate === "MONTH") {
      isMonthly.value = true;
    } else {
      isMonthly.value = false;
    }
    thisPlan.value = props.plan;
  }

  isSelect.value = props.showCurrentPlan;
  showBenefits.value = props.showCurrentPlan;
  console.log("current", props.showCurrentPlan);
});
// const getPackageIcon = (name) => {
//   const icons = {
//     Basic: basicIcon,
//     Startup: StartupIcon,
//     Business: businessIcon,
//     "Business Plus": businessplusIcon,
//   };

//   return name ? icons[name] : null;
// };

const getPackageIcon = (name) => {
  const icons = {
    EaziStarter: starter,
    EaziPlus: plus,
    EaziPremium: premium,
    EaziBusiness: business,
  };

  return name ? icons[name] : null;
};
async function handleSelected(selected) {
  emit("onSelected", selected);
}

// const priceData = ref([
//   {
//     monthlySubscription: "Free",
//     employeeSize: "Less than 10 employees",
//     payroll: "Unlimited; bank charges",
//     payslips: "NGN 50 per payslip",
//   },
// ]);

const fieldKeys = ref({
  monthlySub: "Monthly Subscription",
  employeeMaxSize: "Employee size",
  payroll: "Payroll",
  payslip: "Payslips",
  compliance: "PAYE, Pension & HMO Compliance",
  otherCompliance:
    "Other Compliances: NHF, ITF, NSITF, Gratuity, Life Assurance",
  taxFilingDiscount: "Tax Filing",
  loans: "Loans",
  teamSizeMax: "Teams",
});

const formatPricingText = (key) => {
  const benefits = props.plan.benefits;
  if (benefits) {
    if (key === "monthlySub") {
      return benefits[key] === 0
        ? "Free"
        : `${formatAmount(benefits[key])} monthly`;
    }
    if (key === "employeeMaxSize") {
      return `Less than ${benefits[key] + 1} employees`;
    }
    if (key === "payroll") {
      return "Unlimited; bank charges";
    }
    if (key === "payslip") {
      return benefits[key] === 0
        ? "Free payslips"
        : `${formatAmount(benefits[key])} per payslip`;
    }
    if (key === "compliance") {
      return benefits[key] === 0
        ? "Free PAYE, Pension & HMO remittance"
        : `${formatAmount(benefits[key])} per employee`;
    }
    if (key === "otherCompliance") {
      return benefits[key] === 0
        ? "Custom"
        : `Extra ${formatAmount(benefits[key])} monthly per compliance`;
    }
    if (key === "taxFilingDiscount") {
      return benefits[key] === 0
        ? props.plan.name == "EaziBusiness"
          ? "Custom"
          : "Zero discount"
        : `${benefits[key]}% Discount`;
    }
    if (key === "loans") {
      return benefits[key] ? `${benefits[key]} loans` : "Not available";
    }
    if (key === "teamSizeMax") {
      return benefits[key] === 99999999
        ? "Unlimited"
        : `Up to ${benefits[key]} users`;
    }
  }
};
const benefitsOfPlan = computed(() => {
  let ben = [];
  if (props.plan && props.plan.benefits) {
    ben = [props.plan.basePackagePrice, ...props.plan.benefits];
  }
  return ben;
});
</script>

<style lang="scss" scoped>
// .split-container {
//   background: linear-gradient(
//     to right,
//     #ffffff 50%,
//     #d9ebcd 50%
//   );
// }

.split-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.split-container::before,
.split-container::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  border-radius: 16.605px;
  box-shadow: 0px 8.302px 49.815px 0px rgba(38, 45, 118, 0.08);
}

.split-container::before {
  left: 0;
  background-color: #ffffff; /* Color for the left half */
  z-index: -1;
}

.split-container::after {
  right: 0;
  background-color: #d9ebcd; /* Color for the right half */
}
@media screen and (max-width: 600px) {
  .split-container::before {
    display: none; /* Hide the right half on screens with a maximum width of 600 pixels */
    width: 0%;
  }
  .split-container::after {
    left: 0;
    right: 0;
    width: 100%;
  }
}
</style>
