<template>
  <main class="pt-5 w-full">
    <h4
      class="uppercase md:mt-3 md:text-sm md:text-left text-xs text-headerText"
    >
      COMPLIANCE / {{ dedsTable[route.params.id] }}
    </h4>
    <div class="text-left my-6">
      <easiBackButton></easiBackButton>
    </div>

    <div class="w-full rounded-3xl bg-white p-4">
      <div class="relative flex items-center justify-between text-grey">
        <h2 class="font-bold text-grey">
          Overview of
          <span
            :class="route.params.id == 'pension' ? 'capitalize' : 'uppercase'"
          >
            {{ route.params.id }}</span
          >
        </h2>
        <div class="flex items-center gap-3">
          <img src="@/assets/icons/filter.svg" alt="" />
          <span>Duration</span>
          <div
            @click="timeSelect = !timeSelect"
            class="cursor-pointer gap-3 flex items-center"
          >
            <span class="text-grey text-sm capitalize">{{
              selectedTimeline
            }}</span>
            <div class="text-newLightgrey text-sm font-bold">></div>
          </div>
        </div>

        <div
          v-show="timeSelect"
          class="w-36 absolute group-hover:text-primary flex right-0 flex-col bg-white rounded-lg top-5 shadow-md"
          style="z-index: 1"
        >
          <div
            class="flex flex-col z-50 text-left items-start text-dark-800 text-xs cursor-pointer pt-2"
          >
            <span
              v-for="timeline in ['month', 'year']"
              :key="timeline"
              class="hover:bg-newLimeGreen cursor-pointer py-2 px-4 capitalize w-full"
              @click="
                () => {
                  selectedTimeline = timeline;
                  timeSelect = false;
                }
              "
            >
              {{ timeline }}
            </span>
          </div>
        </div>
      </div>

      <div class="w-full grid grid-cols-1 md:grid-cols-3 mt-5 gap-4">
        <div
          v-for="total in totalDetails"
          :key="total.top"
          class="bg-newGrey w-full rounded-2xl p-2 flex gap-3"
        >
          <div
            class="bg-white flex flex-col items-center justify-center rounded-xl w-12 h-12"
          >
            <img class="w-6 contain" :src="total.icon" alt="" />
          </div>

          <div class="flex flex-col justify-center gap-3">
            <p class="capitalize text-newGray text-xs">{{ total.top }}</p>
            <span class="text-dark-800 font-medium text-sm">{{
              typeof total.bottom == "number"
                ? formatAmount(total.bottom)
                : total.bottom
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full rounded-3xl flex items-center justify-between bg-white my-5 gap-4 p-4"
    >
      <div class="md:hidden w-full">
        <easiButton
          @click="
            $router.push({
              name: 'RegisterCompliance',
              query: { action: 'register', type: route.params.id },
            })
          "
          variant="outlined"
          size="small"
          class="w-full"
          >Register</easiButton
        >
      </div>
      <div
        @click="
          $router.push({
            name: 'RegisterCompliance',
            query: { action: 'register', type: route.params.id },
          })
        "
        class="w-full bg-primary border h-24 cursor-pointer rounded-2xl hidden md:flex items-center"
      >
        <div
          style="
            background: linear-gradient(
              rgba(17, 69, 59, 1),
              rgba(217, 235, 205, 1)
            );
          "
          class="h-full w-4 bg-primary rounded-l-2xl"
        ></div>
        <div
          style="
            background: linear-gradient(
              rgba(17, 69, 59, 1),
              rgba(217, 235, 205, 1)
            );
          "
          class="w-full text-white font-bold h-full border-r border-white rounded-l-2xl rounded-r-full p-4"
        >
          To onboard your employee to this service, click here to get started
        </div>

        <h3 class="font-bold w-full text-center text-white">Register now</h3>
      </div>

      <div
        @click="
          $router.push({
            name: 'RegisterCompliance',
            query: { action: 'pay', type: route.params.id },
          })
        "
        class="w-full bg-primary border h-24 cursor-pointer rounded-2xl hidden md:flex items-center"
      >
        <div
          style="
            background: linear-gradient(
              rgba(17, 69, 59, 1),
              rgba(217, 235, 205, 1)
            );
          "
          class="h-full w-4 bg-primary rounded-l-2xl"
        ></div>
        <div
          style="
            background: linear-gradient(
              rgba(17, 69, 59, 1),
              rgba(217, 235, 205, 1)
            );
          "
          class="w-full text-white font-bold h-full border-r border-white rounded-l-2xl rounded-r-full p-4"
        >
          To remit your compliance, click here to get started
        </div>

        <h3 class="font-bold w-full text-center text-white">Make a Payment</h3>
      </div>

      <div class="md:hidden w-full">
        <easiButton
          class="w-full"
          @click="
            $router.push({
              name: 'RegisterCompliance',
              query: { action: 'register', type: route.params.id },
            })
          "
          size="small"
          >Make Payment</easiButton
        >
      </div>
    </div>

    <div
      v-if="visibleData && visibleData.length"
      class="md:my-8 w-full bg-white rounded-2xl border border-outlineGray px-4 py-3"
    >
      <h2 class="font-bold text-sm mt-1 mb-3">List of Beneficiaries</h2>

      <div v-if="loading" class="w-full">
        <easiLoader />
      </div>

      <div class="flex w-full gap-x-3">
        <div class="w-full">
          <ComplianceSearch
            placeholder="Search by name, date..."
            v-model="searchText"
            @search="searchData"
          />
        </div>
      </div>

      <div class="w-full mt-3">
        <BeneficiaryTable :data="visibleData" class="w-full" />
      </div>
    </div>
    <div v-else class="text-center mt-8 flex flex-col justify-center">
      <div class="bg-white p-4 rounded-xl">
        <div
          class="container mx-auto flex items-center justify-center gap-4 flex-wrap"
        >
          <img src="@/assets/img/Validation.png" class="h-32" alt="" />
        </div>
        <h3 class="text-sm md:text-xl text-dark-800 font-bold">
          No Beneficiary!
        </h3>
        <p class="text-dark-500 text-sm">
          There are no employees in benefit of this compliance.
        </p>
      </div>
    </div>

    <div
      @click="timeSelect = false"
      :class="!timeSelect ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";

// import Filter from "@/components/Filters/ComplianceFilter.vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useRoute } from "vue-router";

import ComplianceSearch from "@/components/Search/ComplianceSearch.vue";
import ComplianceActions from "@/components/Compliance/ComplianceActions.vue";
import BeneficiaryTable from "@/components/Compliance/BeneficiaryTable.vue";

import smallPaye from "@/assets/icons/paye-small-icon.svg";
import smallHmo from "@/assets/icons/hmo-small-icon.svg";
import smallPension from "@/assets/icons/pension-small-icon.svg";
import smallNhf from "@/assets/icons/smallNhf.svg";
import smallItf from "@/assets/icons/smallItf.svg";
import smallNsitf from "@/assets/icons/smallNsitf.svg";

import ratio from "@/assets/icons/ratio.svg";

import coins from "@/assets/icons/coins.svg";
import staff from "@/assets/icons/staff.svg";
import successful from "@/assets/icons/successful.svg";
import failed from "@/assets/icons/failed.svg";
import calendar from "@/assets/icons/small-calendar.svg";

const { formatDateString, formatAmount, getDateText } = helperFunctions;
const route = useRoute();

const store = useDataStore();
const loading = ref(false);
const selectedTimeline = ref("month");
const timeSelect = ref(false);

const { query } = store;

const visibleData = ref([]);

const details = computed(() => store.getSingleComplianceSummary);
const dataSummary = computed(() =>
  store.getSingleComplianceSummary &&
  store.getSingleComplianceSummary.data &&
  store.getSingleComplianceSummary.data.beneficiaries
    ? store.getSingleComplianceSummary.data.beneficiaries
    : []
);

visibleData.value = dataSummary.value;

const searchText = ref("");

function analyzeSearchText(searchText) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Check if the search text partially matches a month
  const partialMonthMatch = monthNames.some((month) =>
    month.toLowerCase().includes(searchText.toLowerCase())
  );
  if (partialMonthMatch) {
    return "Partial Month";
  }

  // Check if the search text partially matches a date (assuming the date is in the "YYYY-MM-DD" format)
  if (/^\d{4}-\d{0,2}-\d{0,2}$/.test(searchText) || /\d/.test(searchText)) {
    return "Partial Date";
  }

  // If it's not a partial month or date, consider it as regular text
  return "Regular Text";
}

function searchArrayObjects(arr, searchString) {
  if (analyzeSearchText(searchString) === "Regular Text") {
    return arr.filter((item) => {
      return (
        item.fullName.toLowerCase().includes(searchString.toLowerCase()) ||
        item.fullName.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      );
    });
  }
  return arr.filter((item) => {
    return (
      item.lastPayment.toLowerCase().includes(searchString) ||
      getDateText(item.lastPayment).toLowerCase().includes(searchString)
    );
  });
}

function searchData(e) {
  searchText.value = e.toLowerCase();
  if (
    searchText.value &&
    searchText.value !== undefined &&
    searchText.value.length
  ) {
    visibleData.value = details.value.data.beneficiaries;
    visibleData.value = searchArrayObjects(
      details.value.data.beneficiaries,
      searchText.value
    );
  } else {
    visibleData.value = details.value.data.beneficiaries;
  }
}

function returnDetails(type) {
  const data = {
    paye: {
      icon: smallPaye,
      text: "PAYE",
    },
    pension: {
      icon: smallPension,
      text: "Pension",
    },
    hmo: {
      icon: smallHmo,
      text: "HMO",
    },
    itf: {
      icon: smallItf,
      text: "HMO",
    },
    nsitf: {
      icon: smallNsitf,
      text: "HMO",
    },
    nhf: {
      icon: smallNhf,
      text: "HMO",
    },
  };
  return data[type];
}
const empLength = computed(() =>
  store.getAllEmployees && store.getAllEmployees.data
    ? store.getAllEmployees.data.length
    : 0
);

const numberOfRegisteredEmps = computed(() =>
  details.value && details.value.data && details.value.data.beneficiaries
    ? details.value.data.beneficiaries.length
    : 0
);

const ratioPercent = computed(() => {
  if (empLength.value === 0 || numberOfRegisteredEmps.value === 0) return 0;

  return (numberOfRegisteredEmps.value / empLength.value) * 100;
});

const totalDetails = ref([
  {
    top: "Service Type",
    bottom: returnDetails(route.params.id).text,
    icon: returnDetails(route.params.id).icon,
  },

  {
    top: "Total Registered Beneficiaries",
    bottom: String(numberOfRegisteredEmps.value),
    icon: staff,
  },

  {
    top: "Employee Ratio",
    bottom: `${String(Math.trunc(ratioPercent.value))}%`,
    icon: ratio,
  },
]);

const dedsTable = {
  paye: "PAYE",
  pension: "Pension",
  nhf: "NHF",
  nsitf: "NSITF",
  itf: "ITF",
  wht: "WHT",
  lifeAssurance: "Employee_Life_Assurance",
  hmo: "HMO",
};

//pending when Abdul creates the endpoint for each compliance type
const queryComplianceDetails = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetComplianceRegistrationSummary",
      payload: {
        input: {
          name: dedsTable[route.params.id],
        },
      },
      service: "PAYROLL",
      storeKey: "singleComplianceSummary",
    });
    console.log(details.value, "dva");
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
queryComplianceDetails();
onMounted(async () => {
  await queryComplianceDetails();
});
</script>

<style scoped>
/* .round {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
} */
</style>
