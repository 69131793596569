<template>
  <div class="relative">
    <slot :showMenu="showMenu" :toggleMenu="toggleMenu" />

    <div
      v-if="showMenu"
      style="box-shadow: 0px 20px 48px 0px #aaaaaa4a; width: 550px"
      class="absolute z-50 left-0 top-8 bg-white p-3 py-4 grid grid-cols-2 gap-y-4 gap-x-2 rounded-2xl"
    >
      <div
        v-for="(menu, i) in menuItems"
        :key="i"
        @mouseover="hoveringItem = menu.title"
        @mouseout="hoveringItem = null"
        @click="
          $router.push(menu.routes);
          showMenu = false;
        "
        :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
        class="flex cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight"
      >
        <div
          :class="[activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg']"
          class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center"
        >
          <img
            :src="
              hoveringItem === menu.title || activeLink == menu.routes
                ? menu.iconActive
                : menu.icon
            "
            alt=""
          />
        </div>

        <div class="flex flex-col">
          <h3 class="text-base font-semibold text-dark-900">
            {{ menu.title }}
          </h3>
          <p class="text-sm text-dark-600 line-clamp-2">
            {{ menu.description }}
          </p>
        </div>
      </div>
    </div>

    <div
      @click="showMenu = false"
      :class="[!showMenu ? 'pointer-events-none' : '']"
      class="fixed z-30 inset-0"
    ></div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

const props = defineProps({
  menuItems: {
    type: Array,
    default: () => [],
  },
});

const showMenu = ref(false);
const hoveringItem = ref(null);

const activeLink = computed(() => route.path);

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
</script>

<style scope></style>
