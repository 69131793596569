<template>
  <div class="overflow-hidden">
    <SiteNav />
    <div class="">
      <section class="hero relative overflow-hidden pt-12 lg:pt-20 pb-1">
        <div class="absolute -top-56 -right-10">
          <img src="./assets/blur.svg" />
        </div>
        <div class="l-container">
          <div
            class="text-center flex flex-col-reverse lg:text-left lg:grid grid-cols-2 gap-8 items-center"
          >
            <div class="">
              <p class="tagline">ANNUAL PAYE FIING</p>
              <h1 class="heading level-2 mb-4">
                Sort out your <br />
                taxes with ease.
              </h1>
              <p class="text-summary w-4/5 mx-auto lg:mx-0 mb-6">
                Beat the deadline and save {{ formatAmount(500000) }} in tax
                penalties.
              </p>
              <SiteButton color="primary" shadow to="/login">
                Get started
              </SiteButton>
            </div>
            <div class="max-w-xl mx-auto">
              <img src="./assets/img-1.png" />
            </div>
          </div>

          <div class="flex flex-col sm:gap-2 mt-16 sm:mt-24">
            <span class="text-base text-primary font-semibold"
              >Our Tax Offerings</span
            >
            <p class="">
              File your taxes with ease with our various tax options
            </p>

            <div>
              <button
                @click="$router.push('/admin/tax-filing')"
                class="text-sm focus:outline-none font-medium fo text-primary flex items-center gap-2"
              >
                Explore now
                <i class="pi pi-arrow-right text-xs"></i>
              </button>
            </div>
          </div>

          <div
            :class="{
              'flex items-center overflow-x-hidden': true,
              relative: isInfinite,
            }"
          ></div>

          <div class="my-10 md:mb-14">
            <div class="hidden md:flex items-center gap-4">
              <IconCard
                v-for="(item, i) in infoList"
                :key="`info-${i}`"
                :title="item.title"
                :color="item.color"
              />
            </div>

            <InfinitScrollVue class="md:hidden">
              <IconCard
                v-for="(item, i) in infoList"
                :key="`info-${i}`"
                :title="item.title"
                :color="item.color"
              />
            </InfinitScrollVue>
          </div>
        </div>
      </section>

      <section class="filing-section py-12 lg:py-20">
        <div class="l-container">
          <div class="flex flex-col lg:grid grid-cols-2 lg:gap-16 items-center">
            <div>
              <img src="./assets/img-2.png" />
            </div>
            <div
              class="text-center max-w-md mx-auto mb-10 lg:mb-0 lg:max-w-none lg:text-left"
            >
              <h2 class="heading level-3 mb-4">
                Filing your taxes just got easier!
              </h2>
              <div class="flex flex-col gap-5">
                <p class="text-summary">
                  With Eazipay, you are ensured impeccable compliance with
                  Nigerian tax regulations, preventing penalties of up to
                  N500,000.
                </p>
                <p class="text-summary">
                  Our hassle-free solution simplifies the entire process, saving
                  you time and effort.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="why-choose-section py-12 lg:py-20 lg:pb-28">
        <div class="l-container">
          <div class="flex flex-col lg:grid grid-cols-2 lg:gap-16 items-center">
            <div
              class="text-center sm:w-10/12 md:max-w-md mx-auto mb-10 lg:mb-0 lg:max-w-none lg:text-left"
            >
              <h2 class="heading level-3 mb-4">Why Choose Eazipay?</h2>

              <p class="text-summary w-4/5 mx-auto lg:mx-0 mb-4 md:mb-6">
                These are few of the reasons why we offer you the best
                experience you can ever get.
              </p>
              <span class="hidden md:block">
                <SiteButton color="primary" shadow to="/login">
                  Get started
                </SiteButton>
              </span>

              <div class="mt-12 md:mt-20 hidden md:block">
                <img src="./assets/arrow.svg" alt="" />
              </div>
            </div>

            <div
              class="lg:mt-16 md:w-full grid grid-cols-1 md:grid-cols-2 gap-6"
            >
              <div
                v-for="(item, i) in itemList"
                :key="`item-${i}`"
                :class="[item.style, 'w-full']"
              >
                <Card :data="item.data" />
              </div>
            </div>

            <div
              class="w-full mt-9 mb-14 flex items-center justify-between gap-5 md:hidden"
            >
              <div class="-mb-14">
                <img src="./assets/arrow.svg" alt="" />
              </div>

              <SiteButton
                color="primary"
                class="whitespace-nowrap"
                shadow
                to="/login"
              >
                Get started
              </SiteButton>
            </div>
          </div>
        </div>
      </section>
    </div>
    <SiteFooter />
  </div>
</template>

<script setup>
import InfinitScrollVue from "../../components/InfinitScroll.vue";
import IconCard from "../../components/IconCard.vue";
import Card from "../../components/Card.vue";
import SiteFooter from "../../components/SiteFooter.vue";
import SiteNav from "../../components/SiteNav.vue";
import SiteButton from "../../components/SiteButton.vue";

import Package from "./assets/Package.svg";
import PackageHover from "./assets/Package-Active.svg";
import SealCheck from "./assets/SealCheck.svg";
import SealCheckHover from "./assets/SealCheck-Active.svg";
import TreePalm from "./assets/TreePalm.svg";
import TreePalmHover from "./assets/TreePalm-Active.svg";
import Gauge from "./assets/Gauge.svg";
import GaugeHover from "./assets/Gauge-Active.svg";

import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;

const itemList = [
  {
    data: {
      title: "Fast and Easy",
      description: "Fill out our simple form and we'll handle the rest.",
      icon: Gauge,
      hoverIcon: GaugeHover,
    },
    style: "md:w-10/12 md:-mt-16 lg:min-w-64 place-self-end",
  },
  {
    data: {
      title: "Accurate and Secure",
      description:
        "Our team of experts ensures your returns are error-free and filed on time.",
      icon: SealCheck,
      hoverIcon: SealCheckHover,
    },

    style: "md:w-10/12 md:-mb-24 lg:min-w-64",
  },
  {
    data: {
      title: "Services we offer",
      description:
        "Direct Assessment, Annual PAYE Returns, State Tax Clearance, State Tax Registration  (For Company and  staff).",
      icon: Package,
      hoverIcon: PackageHover,
    },
    style: "lg:min-w-86 lg:-ml-16  ",
  },
  {
    data: {
      title: "Stress-Free",
      description:
        "Relax and focus on what matters, while we take care of the paperwork.",
      icon: TreePalm,
      hoverIcon: TreePalmHover,
    },
    style: "md:w-10/12 md:mt-24  lg:min-w-64",
  },
];

const infoList = [
  {
    title: "Direct Assessment",
    color: "secondary",
  },
  {
    title: "Annual PAYE Filing",
    color: "primary",
  },
  {
    title: "StateTax Registration",
    color: "secondary",
  },
  {
    title: "Sate Tax Clearance",
    color: "primary",
  },
];
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

.hero {
  .heading {
    color: #292a29;
  }
}

.filing-section {
  background: #f8f8f8;
  .heading {
    font-family: "Roboto";
    span {
      color: #ea4e4b;
    }
  }
}

.why-choose-section {
  .heading {
    font-family: "Roboto";
  }
}
</style>
