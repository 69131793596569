<template>
  <main class="m-0">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-2 mx-auto ml-20 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        QUICK LOANS / APPLICATION
      </h4>
      <!-- <div class="text-left sm:mt-4 sm:mb-6">
        <easiBackButton></easiBackButton>
      </div> -->
    </div>
    <div
      class="banner relative mt-8 rounded-3xl mx-auto h-40 h-60 md:h-56 text-left md:pl-10 pl-8 pt-4 text-white"
    >
      <div
        class="-z-1 hidden md:block absolute overflow-hidden right-0 bottom-0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="333"
          height="150"
          viewBox="0 0 333 150"
          fill="none"
        >
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M477.412 66.7227C340.022 97.0194 149.885 -36.1871 0.48877 10.3068V118.433C149.885 71.9439 340.022 205.15 477.412 174.331V66.7253V66.7227Z"
            fill="#043128"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div
        class="-z-1 absolute md:hidden left-0 overflow-hidden right-0 bottom-0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="396"
          height="180"
          viewBox="0 0 396 180"
          fill="none"
        >
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M436.461 67.2227C299.072 97.5194 108.935 -35.6871 -40.4614 10.8068V118.933C108.935 72.4439 299.072 205.65 436.461 174.831V67.2253V67.2227Z"
            fill="#043128"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <!-- <div
        class="object-cover mt-8 w-44 h-48 md:w-60 md:h-48 overflow-hidden absolute bottom-0 right-0 md:right-10"
      >
        <img
          src="https://s3-alpha-sig.figma.com/img/ae01/2eda/486c091631030a084958b06c709a9885?Expires=1692576000&Signature=ll1op29tOL4RK0LEYUaxPJpDUR~oaqBJP8nFZxGs2JvhcuSmlz6NSr5Ian3NJ7Iu8qFvSRjhqeU53ynJICTVxjYsxC2cnUW-FX7HJHv8DKS0MYWrSNK-UsNEdyP7ffIhlQRiv0Oa353iNxtOeI1L~GfBubuEVNdc-0jHnfOSreL1S-H4X~FApwMmmMgNdnWnX05Iep2jASyg~63HYVt62TExCS5egU3JtDtUp8hLCS9Ujq6Th1i1LcpDK-B1ciNXF-MwrAO5mJf4tAYVwZ80~r3NubPLWTSthx8tY4S5XMBCKUeHiqG1AWvfBjyqL8pgCSpXttfLx95bbYyUQdcZng__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
          alt=""
        />
      </div> -->

      <div
        class="flex flex-row-reverse items-center md:justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
      >
        <h4
          class="md:mt-3 md:text-sm hidden md:block md:text-left text-xs font-medium text-white capitalize sm:uppercase"
        >
          Quick Loans / Application
        </h4>
        <h4
          class="md:mt-3 md:text-sm md:hidden mr-5 md:text-left text-xs font-medium text-white capitalize sm:uppercase"
        >
          Loan / Application
        </h4>
        <!-- <div class="text-left sm:mt-2">
            <easiBackButton color="white"></easiBackButton>
          </div> -->
      </div>
      <div class="w-7/12 z-50 md:w-5/12">
        <h2
          class="text-2xl z-50 md:text-2xl text-white -mt-6 font-extrabold md:mt-6"
        >
          {{ getText.title }}
        </h2>
        <p class="font-medium mt-4 z-90 sm:block">
          {{ getText.sub }}
        </p>
      </div>
    </div>

    <!-- <div class="w-full my-6 flex justify-center items-center gap-2 text-sm">
        <i
          v-for="(item, i) in application"
          :key="i"
          :class="
            selected === item.slug ? 'text-primary bg-primary' : 'text-newGrey'
          "
          class="fas fa-circle cursor-pointer border border-primary rounded-full"
        ></i>
      </div> -->
    <div
      class="md:ml-20 w-full md:w-full md:px-4 my-10 flex md:flex-row flex-col flex-wrap justify-start items-start gap-4 md:gap-5 mb-20"
    >
      <div
        v-for="item in application"
        :key="item.name"
        @click="selected = item.slug"
        class="-px cursor-pointer relative w-full md:w-5/12 border bg-white rounded-2xl px-6 py-6 flex flex-col md:items-stretch"
      >
        <div
          :style="{
            backgroundImage: ' url(' + item.img + ')',
          }"
          style="
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          "
          class="w-full h-24 my-3 rounded-3xl"
        ></div>
        <div class="flex items-center justify-between">
          <h2 class="text-left font-bold text-xl md:text-xl text-dark-800">
            {{ item.name }}
          </h2>
          <img
            @mouseenter="setHoveredType(item.type)"
            @mouseleave="clearHoveredType"
            class="hidden md:block cursor-pointer"
            src="@/assets/icons/information-circle.svg"
            alt=""
          />
          <img
            @click="setHoveredType(item.type)"
            @mouseleave="clearHoveredType"
            class="md:hidden cursor-pointer"
            src="@/assets/icons/information-circle.svg"
            alt=""
          />
        </div>
        <span class="text-sm mb-2 mt-4 leading-5 text-dark-800">{{
          item.shortText
        }}</span>

        <div
          v-if="isHovered(item.type)"
          class="absolute z-20 top-40 mt-5 bg-white shadow-2xl rounded-2xl flex gap-3 pb-2 flex-col text-sm mb-2 text-left"
        >
          <span
            class="px-5 bg-lightGreyBg py-4 rounded-t-2xl text-dark-800 font-medium"
            >{{ item.name }} (Eligibility Criteria)</span
          >
          <div
            v-for="list in item.list"
            :key="list"
            class="flex items-center gap-2 p-5 hover:bg-lightGreyBg py-2"
          >
            <i class="fas fa-circle text-primary text-xs"></i>
            <span>{{ list }}</span>
          </div>
        </div>

        <span class="flex flex-col md:justify-end h-full">
          <easiButton
            block
            class="capitalize mt-4"
            variant="outlined"
            @click="checkEligibility(item)"
            >Select Loan</easiButton
          >
        </span>
      </div>
    </div>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <h1>Confirming Eligibility</h1>
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>

        <div>Hold on this won´t take long</div>
      </template>
    </easiLoading>

    <easiModal
      v-if="notEligibleModal"
      @close="notEligibleModal = false"
      :isHeader="false"
      :isBorder="false"
      rounded="xl"
    >
      <div class="mb-4 mt-2 flex justify-center items-center">
        <img
          src="@/assets/icons/empty-state.svg"
          class="ml-4 w-20 mt-10"
          alt=""
        />
      </div>
      <h1
        class="capitalize text-center text-lg md:text-lg font-extrabold text-red-600"
      >
        Oops! Not Eligible
      </h1>
      <p class="text-center text-xs mt-4 mb-6 mx-2 md:mx-16">
        {{ notEligibleReason }}.
      </p>

      <!-- <hr class="my-6 border-0.5 border-dividerColor text-dividerColor" /> -->
      <div
        class="px-4 sm:px-12 lg:px-8 xl:px-10 mb-4 flex flex-col gap-4 md:gap-6"
      >
        <easiButton @click="notEligibleModal = false" block>
          <span class="text-sm">Back To Loan List</span>
        </easiButton>
      </div>
    </easiModal>
  </main>
  <!-- <Apply v-else @close="applyFN" :amount="eligibleAmount" /> -->
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

import bg1 from "@/assets/img/loan-banner.png";
import bg2 from "@/assets/img/loanbg-2.png";
import bg3 from "@/assets/img/loanbg-3.png";

// import assetLoan from "@/assets/img/asset-loan.jpg";
// import invoiceLoan from "@/assets/img/invoice-loan.webp";
// import operationalLoan from "@/assets/img/operational-loan.jpg";
// import businessLoan from "@/assets/img/business-loan.jpg";

// import Apply from "@/components/loan/apply.vue";

const route = useRoute();
const router = useRouter();
const store = useDataStore();
const toast = useToast();
const { query, mutate } = store;
async function queryLoan() {
  await query({
    endpoint: "ListLoans",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
      },
      filter: {
        type: null,
        status: null,
      },
      sort: {
        createdAt: -1,
      },
    },
    service: "LOAN",
    storeKey: "loans",
  });
}

queryLoan();

// watch(
//   route,
//   (val) => {
//     if (val && val.query && val.query.token) {
//       alert(JSON.stringify(val.query.token));
//       localStorage.setItem("token", route.query.token);
//       // queryLoan();
//     }
//   },
//   { immediate: true }
// );

let selected = ref("cover");

const loadingProgress = ref(false);
const bgImgs = {
  cover: bg1,
  salary: bg2,
  enterprise: bg3,
};

const show = computed(() => {
  const params = ["PAYROLL_COVER", "SALARY_ADVANCE", "ENTERPRISE_LOAN"];
  const id = route.params.id;
  return params.includes(id) ? true : false;
});
const allLoans = computed(() => store.getAllLoans && store.getAllLoans.data);

const companyData = computed(
  () => store.getCompanyAdmin && store.getCompanyAdmin
);
// const isOpen = ref(false);

const elibilityLoading = ref(false);
const notEligibleModal = ref(false);
const notEligibleReason =
  ref(`Sorry you are not eligible for this loan at the moment.
        Please use Eazipay actively for at least three (3) months and try again.`);

const application = ref([
  {
    name: "Asset Purchasing Loan",
    slug: "enterprise",
    color: "#11453B",
    bg: "#D9EBCD",
    icon: "#8D8E8D",

    // img: assetLoan,
    img: "https://i.ibb.co/wY69LQM/asset-loan.jpg",
    shortText:
      "Get the funds you need for purchasing assets. Apply now and access quick loans to secure your investments. Simplify your asset acquisition today!",
    list: [
      "Use Eazipay actively for at least 3 months (90 days)",
      "Loan will be credited to the wallet",
      "6 months bank statement",
      "Maximum loan period is 12 months",
      "Interest rate is typically between 4-5% monthly ",
    ],

    type: "ASSET_PURCHASING_LOAN",
  },

  {
    name: "Invoice Financing Loans",
    slug: "enterprise",
    color: "#11453B",
    bg: "#D9EBCD",
    icon: "#8D8E8D",

    // img: invoiceLoan,
    img: "https://i.ibb.co/fDPZbqb/invoice-loan.webp",
    shortText:
      "Unlock your business’s cash flow potential with Invoice Financing Loans! Get immediate access to funds by leveraging your outstanding invoices.",
    list: [
      "Use Eazipay actively for at least 3 months (90 days)",
      "Loan will be credited to the wallet",
      "6 months bank statement",
      "Maximum loan period is 30 - 90 days",
      "Interest rate is typically between 4-5% monthly ",
    ],

    type: "INVOICE_FINANCING",
  },
  {
    name: "Operational Loans",
    slug: "enterprise",
    color: "#11453B",
    bg: "#D9EBCD",
    icon: "#8D8E8D",

    // img: operationalLoan,
    img: "https://i.ibb.co/WsTD3Jw/operational-loan.jpg",
    shortText:
      "Fuel your business operations with ease through our Operational Loans! From covering daily expenses to expanding your ventures, our flexible financing options ensure smooth operations and unhindered growth.",
    list: [
      "Use Eazipay actively for at least 3 months (90 days)",
      "Loan will be credited to the wallet",
      "6 months bank statement",
      "Maximum loan period is 12 months",
      "Interest rate is typically between 4-5% monthly ",
    ],

    type: "OPERATIONAL_LOANS",
  },
  {
    name: "Small Business Loans",
    slug: "enterprise",
    color: "#11453B",
    bg: "#D9EBCD",
    icon: "#8D8E8D",

    // img: businessLoan,
    img: "https://i.ibb.co/hXYP7zR/business-loan.jpg",
    shortText:
      "Fuel entrepreneurial dreams with Small Business Loans!Whether you're starting a new venture or looking to expand your existing business, our tailored financing solutions provide the support you need.",
    list: [
      "Use Eazipay actively for at least 3 months (90 days)",
      "Loan will be credited to the wallet",
      "6 months bank statement",
      "Maximum loan period is 60 - 120 days",
      "Interest rate is typically between 4-5% monthly ",
    ],

    type: "SMALL_BUSINESS_LOAN",
  },
  {
    name: "Payroll Loans",
    slug: "enterprise",
    color: "#11453B",
    bg: "#D9EBCD",
    icon: "#8D8E8D",

    // img: operationalLoan,
    img: "https://i.ibb.co/WsTD3Jw/operational-loan.jpg",
    shortText:
      "To meet up with staff obligations with up to 60% of the salary budget, introducing our exclusive Payroll Loans Cover! Manage your workforce efficiently and maintain financial stability with this innovative loan option.",
    list: [
      "Use Eazipay actively for at least 3 months (90 days)",
      "Loan will be credited to the wallet",
      "6 months bank statement",
      "Maximum loan period is 30 days",
      "Interest rate is typically between 4-5% monthly ",
    ],

    type: "PAYROLL_COVER",
  },
]);
const hoveredType = ref("");
function setHoveredType(type) {
  hoveredType.value = type;
}
function clearHoveredType() {
  hoveredType.value = null;
}
function isHovered(type) {
  return hoveredType.value === type;
}

onMounted(() => {
  if (route.params.id && route.params.id === "view") {
    selected.value = "cover";
  }
});

const applyFN = (arg) => {
  if (arg) {
    loadingProgress.value = true;
    setTimeout(() => {
      selected.value = arg.slug;
      router.push({
        name: "LoanApplication",
        params: { id: arg.slug },
        query: { id: "terms" },
      });
      loadingProgress.value = false;
    }, 3000);
  } else {
    router.push({ name: "LoanApplication", params: { id: "view" } });
  }
};

const getText = computed(() => {
  const obj = {
    cover: {
      title: "Eazi Loans, for your business",
      sub: "Get low interest rate loans with flexible payment options! No application hassle, no application fee required.",
    },
    salary: {
      title: "Access Our Employee Loan",
      sub: "Instant loans and salary advances for your employees to pay bills, finance healthcare, or other personal expenses.",
    },
    enterprise: {
      title: "Finance Your Expenses",
      sub: "Personal and business financing is made easy! Easily fund your bulk expenses.",
    },
  };

  if (selected.value) {
    return obj[selected.value];
  } else {
    return {
      title: "",
      sub: "",
    };
  }
});

const eligibleAmount = ref({
  min: 0,
  max: 0,
});

const getLoanAmount = async (type) => {
  try {
    let res = await mutate({
      endpoint: "FetchLoanAmount",
      data: { type },
      service: "LOAN",
    });

    if (res && res.success) {
      eligibleAmount.value.min = res.data.min;
      eligibleAmount.value.max = res.data.max;
      return true;
    } else {
      toast.error(res.message);
      // router.push({ name: "Loan" });
    }
  } catch (e) {
    console.log(e.message);
    toast.error(e.message);
    // router.push({ name: "Loan" });
  }
};

const checkEligibility = async (arg) => {
  router.push({ name: "ApplyForLoan", params: { id: arg.type } });
  // try {
  //   loadingProgress.value = true;

  //   let res = await mutate({
  //     endpoint: "CheckEligibility",
  //     data: { type: arg.type },
  //     service: "LOAN",
  //   });

  //   console.log("Eligibility", res);
  //   if (res && res.success) {
  //     const bool = await getLoanAmount(arg.type);
  //     if (bool) {
  //       loadingProgress.value = false;
  //       router.push({ name: "LoanApplication", params: { id: arg.type } });
  //     }
  //   } else {
  //     notEligibleReason.value = res.message;
  //     notEligibleModal.value = true;
  //     loadingProgress.value = false;
  //   }
  // } catch (e) {
  //   loadingProgress.value = false;
  //   console.log(e.message);
  //   toast.error(e.message);
  // } finally {
  //   loadingProgress.value = false;
  // }
};
</script>

<style scoped>
.banner {
  background: linear-gradient(180deg, #11453b 0%, #d9ebcd 100%);
  width: 85%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

@media (max-width: 768px) {
  .banner {
    display: none;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom bottom;
  }
}
</style>
