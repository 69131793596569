<template>
  <div class="flex flex-col gap-4">
    <div
      class="bg-newGrey rounded-2xl md:rounded-3xl p-2 md:p-4 flex flex-col items-start gap-2 md:gap-4 text-xs md:text-sm"
    >
      <easiTextCard title="BVN" :value="kycInfo && kycInfo.bvn" />
      <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2 md:gap-4">
        <easiTextCard
          title="Bank Name"
          :value="kycInfo && kycInfo.bankName"
          :image="logo"
        />
        <easiTextCard
          title="Account Number & Name"
          :value="
            kycInfo &&
            kycInfo.accountNumber &&
            kycInfo.accountName &&
            `${kycInfo.accountNumber} - ${kycInfo.accountName}`
          "
        />
      </div>
    </div>
    <div class="text-xs md:text-sm">
      <span class="text-error font-bold leading-6">Important Notice</span>
      <p>
        Please contact
        <a class="text-blue-500" target="_blank" href="mailto:hi@myeazipay.com"
          >support</a
        >
        for further assistance to change any information.
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useDataStore } from "@/stores/data.js";
import banks from "@/utils/banks";

const store = useDataStore();
const kycInfo = computed(
  () => store.getKyc && store.getKyc.data.accountInformation
);

const logo = computed(() => {
  if (kycInfo.value && kycInfo.value.bankCode) {
    const data = banks.find((bnk) => bnk.code === kycInfo.value.bankCode);
    return data ? data.logo : "";
  }
  return "";
});
</script>

<style></style>
