<template>
  <easiLayout>
    <div class="h-full flex items-center justify-center">
      <!-- ///// Forgot Pin Form Start  ///// -->
      <easiContainer
        bg=" bg-white  px-4 sm:px-10 sm:px-16 lg:px-20 xl:px-24"
        showBackbutton
        :isClose="false"
        @close="close"
        class="w-11/12 sm:w-8/12 md:w-10/12 xl:w-10/12 mx-auto bg-white h-80-screen pt-16 text-center"
      >
        <div>
          <div class="mb-3 flex justify-center items-center">
            <h2 class="text-secondary ml-2 font-bold text-3xl md:text-4xl">
              {{
                display === "passcode"
                  ? "Reset Passcode?"
                  : " Forgot Passcode? "
              }}
            </h2>
          </div>

          <span
            v-if="display === 'email'"
            class="text-base md:text-md leading-5 font-medium"
            >Please enter the email address or phone number associated with your
            account</span
          >
        </div>

        <form @submit.prevent="" ref="form" autocomplete="on">
          <easiTextInput
            v-if="display === 'email'"
            placeholder="Email or Phone Number"
            v-model="userInput"
            type="text"
            name="emailOrPhoneNumber"
            class="my-4"
            :error="errorRules.userInput"
          />

          <!-- // As an Employee -->
          <div
            v-if="display === 'email'"
            class="justify-center items-center mt-4 gap-2 flex"
          >
            <input
              class="my-2"
              v-model="asEmployee"
              type="checkbox"
              name="box"
              id=""
            />
            <div
              class="relative flex items-center text-gray-500 cursor-pointer hover:text-gray-600"
              x-data="{ hover: false }"
              @mouseenter="showTip = true"
              @mouseleave="showTip = false"
            >
              <p>As an employee</p>
              <div class="relative">
                <div
                  v-show="showTip"
                  class="absolute bottom-0 inline-block w-64 px-4 py-3 mb-10 -ml-32 text-white bg-primary rounded-lg"
                  x-show="hover"
                  x-transition:enter="transition ease-out duration-300"
                  x-transition:enter-start="opacity-0 transform scale-90"
                  x-transition:enter-end="opacity-100 transform scale-100"
                  x-transition:leave="transition ease-in duration-300"
                  x-transition:leave-start="opacity-100 transform scale-100"
                  x-transition:leave-end="opacity-0 transform scale-90"
                  x-cloak
                >
                  <span class="inline-block text-xs"
                    >For employees who have been invited as an uploader, auditor
                    or assigned to a role.</span
                  >
                  <span
                    class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-primary"
                    style="left: 50%"
                  ></span>
                </div>
                <svg
                  class="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <easiButton
              v-if="display === 'email'"
              @click="sendOTP"
              class="w-full mt-4"
              rounded="3xl"
              color=" newGreen"
              :loading="sentOtpLoading"
              >Send OTP</easiButton
            >
            <!-- <div v-else class="flex items-center justify-center">
              <span class="text-success font-semibold">OTP Sent</span>
              <span class="ml-1">
                <img src="../../assets/icons/good.svg" alt="" />
              </span>
            </div> -->
          </div>

          <div v-if="display === 'otp'" class="mb-4">
            <p class="text-center text-dark-800 mb-10">
              Kindly input the OTP sent to your email address/phone number
            </p>
            <p class="text-left ml-1">Enter OTP code</p>
            <span class="w-fit flex justify-around items-center">
              <easiOtp
                class="w-full sm:w-auto"
                size="10"
                :token_length="6"
                :max-length="1"
                @input="checkOtp"
                :error="errorRules.otpCode"
              />
            </span>
            <div
              class="text-dark-800 text-sm font-medium text-right flex items-center justify-end"
            >
              <easiButton @click="resendOTP" variant="text" color="primary"
                >Resend</easiButton
              >
            </div>
          </div>

          <easiButton
            block
            @click="handleOtp"
            v-if="display === 'otp'"
            rounded="3xl"
            color=" newGreen"
            class=""
            :loading="loading"
          >
            Submit
          </easiButton>

          <div v-if="display === 'passcode'" class="text-left">
            <div class="flex items-center mb-1">
              <span class="text-dark-700 ml-3">New Code</span>
              <img
                v-if="showPin"
                @click="showPin = !showPin"
                class="cursor-pointer ml-2"
                src="../../assets/icons/eye-close.svg"
              />
              <img
                v-else
                @click="showPin = !showPin"
                class="cursor-pointer ml-2"
                src="../../assets/icons/eye-open.svg"
              />
            </div>

            <span class="mb-4 flex justify-center items-center">
              <easiOtp
                class=""
                :type="showPin ? 'password' : 'number'"
                :token_length="6"
                size="10"
                :max-length="1"
                @input="checkPin"
                :error="errorRules.pin"
              />
            </span>
          </div>

          <div v-if="display === 'passcode'" class="text-left">
            <div class="flex items-center mb-1">
              <span class="capitalize text-dark-700 ml-3">Confirm code</span>

              <img
                v-if="showPin1"
                @click="showPin1 = !showPin1"
                class="cursor-pointer ml-2"
                src="../../assets/icons/eye-close.svg"
              />
              <img
                v-else
                @click="showPin1 = !showPin1"
                class="cursor-pointer ml-2"
                src="../../assets/icons/eye-open.svg"
              />
            </div>
            <span class="mb-4 flex justify-center items-center">
              <easiOtp
                class=""
                :type="showPin1 ? 'password' : 'number'"
                :token_length="6"
                size="10"
                :max-length="1"
                :error="errorRules.confirmPin"
                @input="checkConfirmPin"
              />
            </span>
          </div>

          <easiButton
            block
            @click="submit"
            v-if="display === 'passcode'"
            rounded="3xl"
            color=" newGreen"
            class=""
            :loading="loading"
          >
            Submit
          </easiButton>
        </form>
      </easiContainer>
      <!-- ///// Forgot Pin Form End  ///// -->

      <!-- ///// Success Message Start ///// -->
      <easiContainer :isClose="false" v-if="display === 'success'" class="py-8">
        <h2 class="text-dark-800 ml-2 font-bold text-3xl md:text-4xl">
          Pin Change Successful
        </h2>

        <img
          class="mx-auto py-6 sm:py-10"
          src="../../assets/icons/happy.svg"
          alt=""
        />

        <easiButton @click="goBack"> Back to login</easiButton>
      </easiContainer>
      <!-- ///// Success Message End ///// -->
    </div>

    <easiSuccess
      v-if="updateSuccess"
      :noHead="true"
      @close="updateSuccess = false"
    >
      <template v-slot:message>
        <span class="mt-2 font-bold text-sm"
          >PIN changed successfully. Login with new PIN?</span
        >
      </template>

      <template v-slot:action>
        <easiButton @click="$router.push('/login')" class="md:w-40 w-full"
          >Login</easiButton
        >
      </template>
    </easiSuccess>
  </easiLayout>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { useDataStore } from "../../stores/data.js";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "../../composable/helperFunctions";

const { processNumber } = helperFunctions;
const { mutate } = useDataStore();
const router = useRouter();
const toast = useToast();

const showOtpForm = ref(JSON.parse(window.localStorage.getItem("showOtpForm")));
const display = ref(window.localStorage.getItem("forgotPasswordForm"));
watch(display, (val) => {
  window.localStorage.setItem("forgotPasswordForm", val);
});
let userInput = ref("");
const sentOtpLoading = ref(false);
const loading = ref(false);

const otpCode = ref("");
const args = reactive({
  email: "",
  phoneNumber: "",
  pin: "",
  confirmPin: "",
});
const showPin = ref(true);
const showPin1 = ref(true);
const updateSuccess = ref(false);

const asEmployee = ref(false);
const showTip = ref(false);

const errorRules = reactive({
  userInput: false,
  pin: false,
  otpCode: false,
  confirmPin: false,
});

watch(showOtpForm, (val) => {
  window.localStorage.setItem("showOtpForm", val);
});

onMounted(() => {
  display.value === null ? (display.value = "email") : display.value;

  ///Init the value to the display on load to show the right form///
  // showOtpForm.value === "null"
  //   ? (showOtpForm.value = false)
  //   : showOtpForm.value;
});

function validate() {
  if (userInput.value.trim() === "" && display.value === "email") {
    errorRules.userInput = "Please enter your email or phone number";
    return false;
  } else if (otpCode.value.trim() === "" && display.value === "otp") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
    return false;
  } else if (args.pin.trim() === "") {
    errorRules.pin = "Please set a pin";
    return false;
  } else if (args.confirmPin.trim() === "") {
    errorRules.confirmPin = "Please confirm your pin";
    return false;
  } else if (args.pin !== args.confirmPin) {
    errorRules.pin = "Pin does not match. Please check and put a matching pin";
    return false;
  } else {
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });
    return true;
  }
}

// Update OTP Input Functions
function checkPin(otp_input) {
  args.pin = otp_input;
}

function checkConfirmPin(otp_input) {
  args.confirmPin = otp_input;
}

function checkOtp(otp_input) {
  otpCode.value = otp_input;
}

function checkInputType(payload) {
  if (payload.includes("@")) {
    args.email = payload;
    return "email";
  } else {
    args.phoneNumber = payload;
    return "phone";
  }
}

async function checkPasscode() {
  if (validate()) {
    display.value = "otp";
  }
}
async function handleOtp() {
  if (otpCode.value.trim() === "" && display.value === "otp") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
    return;
  }
  display.value = "passcode";
}

async function submit() {
  if (validate()) {
    try {
      console.log("Submit");
      loading.value = true;

      checkInputType(userInput.value); //check the input type set it to the appropraite args

      let payload = {
        pin: args.pin,
        code: otpCode.value,
        email: args.email.trim().toLocaleLowerCase(),
        phoneNumber: processNumber(args.phoneNumber),
        reference: window.localStorage.getItem("otpReference"),
        accountType: asEmployee.value ? "EMPLOYEE" : "COMPANYADMIN",
      };

      //Check if the userinput is email or phone and dynamically add the property
      // const inputType = checkInputType(userInput.value);
      // if (inputType === "email") {
      //   payload.email = userInput.value;
      // } else {
      //   payload.phoneNumber = userInput.value;
      // }

      let res = await mutate({
        endpoint: "VerifyOtpForogotPassword",
        data: { input: payload },
        service: "AUTH",
      });

      // log(res);
      if (res.success) {
        loading.value = false;
        // toast.success(res.message);
        display.value = "email";
        updateSuccess.value = true;
      } else {
        toast.error(res.message);
      }
    } catch (e) {
      // log(e);
      loading.value = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    } finally {
      loading.value = false;
    }
  }
}

async function sendOTP() {
  checkInputType(userInput.value);
  const payload = {
    email: args.email,
    phoneNumber: processNumber(args.phoneNumber),
    accountType: asEmployee.value ? "EMPLOYEE" : "COMPANYADMIN",
  };

  if (userInput.value.trim() !== "") {
    //Clear user input error state
    errorRules.userInput = false;
    try {
      sentOtpLoading.value = true;

      let res = await mutate({
        endpoint: "SendOtpForgotPassword",
        data: { input: payload },
        service: "AUTH",
      });

      // log(res);
      if (res.success) {
        window.localStorage.setItem("otpReference", res.data.reference);
        sentOtpLoading.value = false;
        display.value = "otp";
        showOtpForm.value = true;
      } else {
        display.value = "email";
        showOtpForm.value = false;
        errorRules.userInput = res.message;
      }
    } catch (e) {
      // log(e);
      sentOtpLoading.value = false;
      const message =
        e.message === "User Not Found!"
          ? "Doesn't identify registered user's email or phone"
          : e.message;

      errorRules.userInput = message;
    } finally {
      sentOtpLoading.value = false;
    }
  } else {
    errorRules.userInput = "Please enter your email or phone number";
  }
}

async function resendOTP() {
  checkInputType(userInput.value);
  const payload = {
    email: args.email,
    phoneNumber: processNumber(args.phoneNumber),
    accountType: asEmployee.value ? "EMPLOYEE" : "COMPANYADMIN",
  };

  log(processNumber(args.phoneNumber));
  if (userInput.value.trim() !== "") {
    //Clear user input error state
    errorRules.userInput = false;
    try {
      let res = await mutate({
        endpoint: "SendOtpForgotPassword",
        data: { input: payload },
        service: "AUTH",
      });

      // log(res);
      if (res.success) {
        window.localStorage.setItem("otpReference", res.data.reference);
        toast.success(res.message);
      }
    } catch (e) {
      // log(e);
      errorRules.userInput = e.message;
    }
  } else {
    errorRules.userInput = "Please enter your email or phone number";
  }
}

function close() {
  if (display.value === "email") {
    router.go(-1);
  }
  if (display.value === "passcode") {
    display.value = "email";
  } else if (display.value === "otp") {
    display.value = "passcode";
  } else {
    display.value = "email";
  }

  display.value = "email";
  showOtpForm.value = false;
}

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
