<template>
  <main class="mx-auto">
    <div v-if="!isEdit" class="w-full">
      <div class="w-full flex items-center justify-between">
        <h4 class="text-lg md:text-xl font-medium">
          Personal Profile Settings
        </h4>
        <div
          @click="isEdit = true"
          class="border text-primary border-primary rounded-full flex items-center py-2 px-4 space-x-2 cursor-pointer"
        >
          <img src="@/assets/icons/Edit3.svg" class="md:hidden" />

          <span class="font-medium">
            Edit <span class="hidden md:inline">Profile</span>
          </span>
        </div>
      </div>

      <hr class="border-1 border-dark-100 my-4 md:my-6" />
      <div
        class="easiGradient relative overflow-hidden w-full p-8 flex flex-col md:flex-row justify-center items-center gap-4"
      >
        <div
          class="rounded-full w-44 h-44 border-4 border-white overflow-hidden"
        >
          <img
            v-if="adminDetails && adminDetails.pfp"
            :src="adminDetails && adminDetails.pfp"
            class="object-cover object-center w-full h-full"
            :alt="adminDetails && adminDetails.firstName"
          />
          <div
            v-else
            :style="`background-color:${initials(getAdminName).color}`"
            class="p-2 w-full h-full text-white font-medium rounded-full flex items-center justify-center text-3xl md:text-5xl"
          >
            {{ initials(getAdminName).str }}
          </div>
        </div>
        <div class="flex flex-row md:flex-col gap-4">
          <!-- <div
          
            style="background-color: #b9bbc07d; border-color: #e0e0e08b"
            class="cursor-pointer border rounded-lg p-3 md:py-4 px-6 flex items-center text-white gap-2 md:gap-4"
          >
            <img class="w-4" src="@/assets/icons/upload-white.svg" alt="" />
            <span class="font-medium">Upload</span>
          </div> -->
          <UploadComponent :proPic="true" @fileUrl="updateProfilePic($event)" />
          <div
            @click="updateProfilePic(null)"
            class="cursor-pointer rounded-lg p-3 px-4 md:px-6 flex items-center gap-2 md:gap-4"
            :style="{
              color: 'rgba(255, 255, 255, 0.6)',
              border: '1px solid rgba(255, 255, 255, 0.5) ',
            }"
          >
            <i class="far fa-times-circle text-xl"></i>
            <span class="font-medium text-base">Cancel</span>
          </div>
        </div>

        <div
          class="absolute -right-24 bottom-4 w-56 opacity-25 pointer-events-none"
        >
          <img src="@/assets/icons/auth-illustration.svg" alt="" />
        </div>
      </div>

      <hr class="text-dark-400 my-4" />

      <div class="w-full grid grid-cols-2 gap-4 mt-4">
        <div class="flex flex-col items-start col-span-2 md:col-span-1">
          <span class="text-sm md:text-base text-dark-600 font-medium"
            >First Name:</span
          >
          <span
            class="text-base md:text-lg text-dark-800 font-bold capitalize"
            >{{ adminDetails && adminDetails.firstName }}</span
          >
        </div>
        <div class="flex flex-col items-start col-span-2 md:col-span-1">
          <span class="text-sm md:text-base text-dark-600 font-medium"
            >Last Name:</span
          >
          <span
            class="text-base md:text-lg text-dark-800 font-bold capitalize"
            >{{ adminDetails && adminDetails.lastName }}</span
          >
        </div>

        <div class="w-full flex flex-col items-start col-span-2">
          <span class="text-sm md:text-base text-dark-600 font-medium"
            >Phone Number:</span
          >
          <span class="text-base md:text-lg text-dark-800 font-bold">{{
            adminDetails && adminDetails.phoneNumber
          }}</span>
        </div>
        <div class="w-full flex flex-col items-start col-span-2">
          <span class="text-sm md:text-base text-dark-600 font-medium"
            >Email:</span
          >
          <span
            class="text-base md:text-lg text-dark-800 font-bold lowercase"
            >{{ adminDetails && adminDetails.email }}</span
          >
        </div>
      </div>
    </div>

    <div v-else class="w-full px-6 py-10 relative">
      <span
        @click="isEdit = false"
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/close.svg" alt="" />
      </span>

      <h4 class="text-xl md:text-2xl font-medium">Edit Profile</h4>

      <hr class="text-dark-400 my-4" />

      <form
        @submit.prevent="updateProfile"
        autocomplete="on"
        class="flex flex-col gap-y-3"
      >
        <easiTextInput
          placeholder="First Name"
          label="First Name"
          type="text"
          name="firstName"
          class="mt-1 mb-5"
          required
          v-model="args.firstName"
          :error="errorRules.firstName"
        ></easiTextInput>

        <easiTextInput
          placeholder="Last Name"
          label="Last Name"
          type="text"
          name="lastName"
          class="mt-1 mb-5"
          required
          v-model="args.lastName"
          :error="errorRules.lastName"
        ></easiTextInput>

        <div class="text-xs md:text-base mb-4">
          Please contact
          <a href="mailto:hi@myeazipay.com" class="text-secondary font-bold"
            >support</a
          >
          to change your phone number or email address
        </div>

        <easiTextInput
          placeholder="Phone Number"
          label="Phone Number"
          type="tel"
          name="phoneNumber"
          readonly
          class="mt-1 mb-5"
          v-model="args.phoneNumber"
          :error="errorRules.phoneNumber"
        ></easiTextInput>

        <easiTextInput
          placeholder="Email"
          label="Email Address"
          type="email"
          name="email"
          readonly
          class="mt-1 mb-5"
          v-model="args.email"
          :error="errorRules.email"
          autocom
        ></easiTextInput>

        <div
          class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <span
            @click="isEdit = false"
            class="w-full font-normal text-base cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 py-3"
            >Cancel</span
          >
          <div class="w-full md:w-full">
            <easiButton type="submit" :loading="loading" class="w-full">
              Update
            </easiButton>
          </div>
        </div>
      </form>
    </div>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span> Profile updated successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed, watch } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

import UploadComponent from "@/components/global/UploadComponent";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const { processDate, validatePhone, validateEmail, uploadFileToServer } =
  helperFunctions;

const adminDetails = computed(
  () => store.getCompanyAdmin && store.getCompanyAdmin.companyAdmin
);

const loading = ref(false);
const updateSuccess = ref(false);
const isEdit = ref(false);
const uploadFile = ref(null);

const errorRules = ref({
  firstName: "",
  lastName: "",
  // phoneNumber: "",
  // email: "",
});

const args = reactive({
  firstName: "",
  lastName: "",
  pfp: "",
  // email: "",
  // phoneNumber: "",
});

function validate() {
  if (args.firstName.trim() == "") {
    errorRules.firstName = "Please enter first name";
    return false;
  } else if (args.lastName.trim() == "") {
    errorRules.firstName = "Please enter first name";
    return false;
  } else {
    errorRules.firstName = false;
    errorRules.lastName = false;

    return true;
  }
}

function assignValues() {
  const keys = ["firstName", "lastName", "email", "phoneNumber", "pfp"];
  for (let i = 0; i < keys.length; i++) {
    if (adminDetails.value && adminDetails.value[keys[i]]) {
      args[keys[i]] = adminDetails.value[keys[i]];
    }
  }
}

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const initials = computed(() => {
  return (name) => {
    if (name && name.length) {
      let color;
      // Get color

      const arr = name.split(" ");
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const initialOne = String(arr[0]).charAt(0);
      const initialTwo = String(arr[1]).charAt(0);
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = getColor(num);
      const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
        .charAt(0)
        .toUpperCase()}`;
      return { str, color };
    } else {
      return { str: "AA", color: "#189F00" };
    }
  };
});

const getAdminName = computed(() => {
  let name = "";

  if (adminDetails.value) {
    name = `${adminDetails.value && adminDetails.value.firstName} ${
      adminDetails.value && adminDetails.value.lastName
    }`;
  }
  return name;
});

const updateProfile = async () => {
  if (validate()) {
    loading.value = true;

    // log(args);
    const payload = {
      firstName: args.firstName,
      lastName: args.lastName,
      pfp: args.pfp,
    };
    try {
      let res = await mutate({
        endpoint: "UpdateAdminProfile",
        data: { input: payload },
        service: "SETTINGS",
      });

      // log(res);
      if (res.success) {
        loading.value = false;
        isEdit.value = false;
        updateSuccess.value = true;
        setTimeout(() => {
          window.location.reload();
        }, 500);
        // toast.success(res.message);

        await queryCompanyAdmin();
        await assignValues();
      } else {
        loading.value = false;
      }
    } catch (e) {
      loading.value = false;
      toast.error(e.message);
      // log(e);
    }
  }
};

async function updateProfilePic(e) {
  loading.value = true;

  const img = await uploadFileToServer(e);

  const payload = {
    firstName: args.firstName,
    lastName: args.lastName,
    pfp: img,
  };

  try {
    let res = await mutate({
      endpoint: "UpdateAdminProfile",
      data: { input: payload },
      service: "SETTINGS",
    });
    if (res.success) {
      loading.value = false;
      // console.log(res);

      await queryCompanyAdmin();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
      }, 500);
    } else {
      loading.value = false;
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}

const queryCompanyAdmin = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });

  console.log("Company Admin Details");
  // log(adminDetails);
};

queryCompanyAdmin();
assignValues();

const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
};

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style>
.color {
  color: #b9bbc07d;
  color: #e0e0e08b;
}
</style>
