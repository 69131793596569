<template>
  <div class="px-4 md:px-28 py-6">
    <easiRoundLoader v-if="loading" />

    <div v-show="isKYCForm">
      <div
        class="flex gap-2 mb-10 font-medium cursor-pointer"
        @click="isKYCForm = !isKYCForm"
      >
        <img src="@/assets/icons/arrow-small-left.svg" alt="" />
        Back to Profile
      </div>
      <div class="px-8 py-10 border border-outlineGray">
        <KYC />
      </div>
    </div>

    <div v-if="!isKYCForm">
      <p class="font-bold text-2xl text-black mb-10">User Profile</p>

      <div v-if="userProfile" class="p-8 border border-outlineGray">
        <div
          v-if="userProfile.membership == 'owner'"
          class="border border-outlineGray bg-newAsh px-8 py-4 cursor-pointer flex justify-between items-center rounded-xl"
          @click="isKYCForm = !isKYCForm"
        >
          <div v-if="false" class="flex gap-2">
            <img src="@/assets/icons/Ellipse.svg" alt="" class="rotate-180" />

            <p class="text-secondary font-medium">
              Click here to complete your business verification
            </p>
          </div>
          <div v-else class="flex gap-2">
            <img src="@/assets/icons/apikyc.svg" alt="" class="rotate-180" />

            <p class="text-dark-800 font-medium">KYC Information</p>
          </div>

          <img
            src="@/assets/icons/arrow-small-right.svg"
            alt=""
            class="rotate-180"
          />
        </div>
        <hr class="my-8" />

        <div class="flex justify-between items-end">
          <div class="flex gap-4">
            <div
              class="rounded-full w-20 h-20 border-2 border-white overflow-hidden"
            >
              <img
                v-if="userProfile && userProfile.employee.profile.pfp"
                :src="userProfile && userProfile.employee.profile.pfp"
                class="object-cover object-center w-full h-full"
                :alt="userProfile && userProfile.employee.profile.firstName"
              />
              <div
                v-else
                class="p-2 uppercase bg-primary w-full h-full text-white font-medium rounded-full flex items-center justify-center text-3xl md:text-4xl"
              >
                {{ initials }}
              </div>
            </div>
            <div class="flex flex-col capitalize">
              <p class="text-xl font-bold text-dark-800">{{ userFullName }}</p>
              <p class="font-normal">
                {{ userProfile.employee.profile.jobTitle || "N/A" }}
              </p>
              <p class="text-newGray text-base">
                Western African Time (WAT), Lagos UTC +1
              </p>
            </div>
          </div>
          <div
            @click="isOpen = true"
            class="border text-primary border-primary rounded-full flex items-center py-2 px-4 space-x-2 cursor-pointer"
          >
            <img src="@/assets/icons/Edit3.svg" class="hidden" />

            <span class="font-medium">
              Edit <span class="hidden md:inline">Profile</span>
            </span>
          </div>
        </div>
        <hr class="my-8" />

        <div v-if="userProfile" class="grid grid-cols-2 md:grid-cols-3 gap-2">
          <div class="flex flex-col gap-1">
            <p class="text-newGray text-base">User Id</p>
            <p class="text-grey text-lg font-normal truncate">
              {{ userProfile.employee.profile._id || "N/A" }}
            </p>
          </div>
          <div class="flex flex-col gap-1">
            <p class="text-newGray text-base">Email Address</p>
            <p class="text-grey text-lg font-normal truncate">
              {{ userProfile.employee.profile.email || "N/A" }}
            </p>
          </div>
          <div class="flex flex-col gap-1">
            <p class="text-newGray text-base">Phone Number</p>
            <p class="text-grey text-lg font-normal">
              {{ userProfile.employee.profile.phoneNumber || "N/A" }}
            </p>
          </div>
        </div>
        <hr class="my-8" />
        <div class="grid grid-cols-2 md:grid-cols-3 gap-2">
          <div class="flex flex-col gap-1">
            <p class="text-newGray text-base">Company</p>
            <p class="text-grey text-lg font-normal capitalize">
              {{ userProfile.company.company.companyName || "N/A" }}
            </p>
          </div>
          <div class="flex flex-col gap-1">
            <p class="text-newGray text-base">Job Title</p>
            <p class="text-grey text-lg font-normal">
              {{ userProfile.employee.profile.jobTitle || "N/A" }}
            </p>
          </div>
          <div class="flex flex-col gap-1">
            <p class="text-newGray text-base">Country</p>
            <p class="text-grey text-lg font-normal">
              {{ userProfile.employee.profile.country || "N/A" }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <UpdateEmployeeProfile
      :isOpen="isOpen"
      @close="isOpen = false"
      @query="queryProfile"
    />
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import UpdateEmployeeProfile from "@/components/ApiDashboard/UpdateEmployeeProfile.vue";
import KYC from "@/components/ApiDashboard/kyc/Index.vue";

import { ref, onMounted, computed } from "vue";

import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const route = useRoute();
const store = useDataStore();
const { query } = store;

const isKYCForm = ref(false);
const loading = ref(false);
const isOpen = ref(false);

const companyData = computed(() => store.getCompanyAdmin);
const userProfile = computed(() => store.apiProfile);

console.log('user',userProfile.value);

const userFullName = computed(() => {
  const { employee } = userProfile.value;
  if(!employee.profile) return "N/A"
  const name = `${employee.profile.firstName} ${employee.profile.lastName}`;
  return name || "N/A";
});

const initials = computed(() => {
  const { employee } = userProfile.value;
  if(!employee.profile) return "N/A"
  const name = `${employee.profile.firstName} ${employee.profile.lastName}`;
  console.log("Name", name);
  if (name && name.trim()) {
    const arr = name.split(" ");
    const first = arr[0] || "";
    const second = arr[1] || "";
    let str = `${first && first[0]}${second && second[0]}`;
    return str;
  } else {
    return "N/A";
  }
});

onMounted(() => {
  queryProfile();
  const { kyc } = route.query;
  if (kyc) {
    isKYCForm.value = true;
  }
});

const queryProfile = async (showLoading = true) => {
  try {
    const { companyAdmin } = companyData.value || {};
    if (!companyAdmin) return;

    loading.value = showLoading;

    const res = await query({
      endpoint: "GetEmployeeProfile",
      payload: {
        input: {
          employeeId: companyAdmin._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiProfile: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
