<template>
  <main
    class="flex w-full md:m-0 md:bg-white rounded-xl overflow-hidden pt-4 md:border gap-4 md:gap-0 border-dark-100 md:p-0 flex-col md:flex-row items-start"
  >
    <div
      class="bg-white md:bg-transparent rounded-2xl md:rounded-none grid grid-cols-2 md:grid-cols-1 p-2 md:p-0 md:py-5 w-full md:w-56 md:items-start"
    >
      <button
        v-for="(btn, i) in pages"
        v-show="btn.show"
        :key="i"
        @click="switchView(i)"
        :class="[
          btn.active
            ? ' text-primary bg-highlight font-semibold shadow-sm '
            : 'text-dark-800',
          i == 0 ? 'rounded-l-2xl' : '',
          i == pages.length - 1 ? 'rounded-r-2xl' : '',
        ]"
        class="relative col-span-1 w-full capitalize md:rounded-none border md:border-0 md:border-t border-dark-100 text-sm px-4 py-3 focus:outline-none text-center md:text-left whitespace-nowrap"
      >
        {{ btn.title }}
        <div
          v-if="btn.active"
          style="width: 3px"
          class="hidden md:block absolute right-0 top-0 h-full rounded-l-sm bg-primary"
        ></div>
      </button>
    </div>
    <div
      class="relative shadow-md md:shadow-none rounded-2xl md:rounded-none p-4 w-full text-sm md:border-l border-dark-100 h-auto bg-white"
    >
      <!-- <span
        v-if="!fromArchived"
        @click="
          $router.push({
            name: 'EditPersonalInfo',
            params: { id: $route.params.id },
          })
        "
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/edit.svg" alt="" />
      </span> -->
      <span></span>
      <keep-alive>
        <component :is="display" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, computed, shallowRef } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

//// View Components ////
import Company from "@/components/Settings/Business/Company.vue";
import Founder from "@/components/Settings/Business/Founder.vue";
import Directors from "@/components/Settings/Business/Directors.vue";
import Branch from "@/components/Settings/Business/Branch.vue";
import AccountInfo from "@/components/Settings/Business/AccountInfo.vue";

import Kyc from "@/components/Settings/Business/KYC/Index.vue";

const store = useDataStore();
const { pageIndex, getPageIndex } = storeToRefs(store);

const fromArchived = computed(() => store.getViewFromArchived);
const display = shallowRef(Company);

const pages = reactive([
  {
    title: "Company Info",
    value: "company",
    component: Company,
    active: true,
    live: "view",
    show: true,
  },
  {
    title: "Business Verification",
    value: "company",
    component: Kyc,
    active: false,
    live: "view",
    show: true,
  },
  {
    title: "CEO/Founder",
    value: "founder",
    component: Founder,
    active: false,
    live: "view",
    show: true,
  },
  {
    title: "Company Directors",
    value: "directiors",
    component: Directors,
    active: false,
    live: "view",
    show: true,
  },
  {
    title: "Account Information",
    value: "account-info",
    component: AccountInfo,
    active: false,
    live: "view",
    show: true,
  },
  {
    title: "Branch/Department",
    value: "branch",
    component: Branch,
    active: false,
    live: "view",
    show: true,
  },
]);

const switchView = (index) => {
  if (index != undefined && index != null) {
    display.value = pages[index].component;

    store.$patch({
      pageIndex: index,
    });

    pages.forEach((el) => {
      el.active = false;
    });
    pages[index].active = true;
  } else {
    display.value = pages[0].component;

    store.$patch({
      pageIndex: 0,
    });

    pages.forEach((el) => {
      el.active = false;
    });
    pages[0].active = true;
  }
};
switchView(getPageIndex.value);
</script>

<style></style>
