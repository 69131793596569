<template>
  <div>
    <!-- //////// Desktop View Start //////// -->
    <!-- /////////// Search Not found ////// -->

    <div
      v-if="!visibleData.length"
      class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
    >
      <div class="flex flex-col text-left col-span-2 md:col-span-1">
        <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
          Oops!
        </h1>
        <div class="text-sm md:text-lg">
          Sorry we couldn’t find a result for your request.
        </div>
        <p class="text-xs md:text-sm mt-2">
          Please check your search and filter, then try again.
        </p>
      </div>
      <div class="col-span-2 md:col-span-1">
        <img src="@/assets/icons/search_null.svg" alt="" />
      </div>
    </div>

    <div v-else>
      <div class="hidden md:flex flex-col text-right pt-2">
        <div
          v-if="visibleData.length"
          class="overflow-x-auto rounded-t-3xl text-grey"
        >
          <table style="min-width: 900px" class="w-full">
            <thead class="">
              <tr
                style="color: #515251"
                class="font-bold shadow-md bg-dark-100 relative"
              >
                <th
                  class="bg-tableHead border border-outlineGray font-bold capitalize text-sm tracking-wide logo text-center whitespace-nowrap px-3"
                  :style="headStyle"
                >
                  <div class="">
                    <input
                      type="checkbox"
                      class=""
                      :checked="allChecked"
                      @change="toggleCheckAll()"
                    />
                  </div>
                </th>

                <th
                  v-for="(title, index) in headers"
                  :key="index"
                  class="font-bold bg-tableHead uppercase text-xs tracking-wide logo text-center whitespace-nowrap py-2 px-3 border shadow-lg"
                  style="border: 2px solid #e7e8e7"
                >
                  <div class="px-2 py-2">
                    {{ title }}
                  </div>
                </th>
              </tr>
            </thead>
            <div class="p-2"></div>
            <tbody class="relative">
              <tr
                v-for="employee in visibleData"
                :key="employee"
                class="hover:bg-easigrey group bg-white cursor-pointer relative"
              >
                <td
                  :style="bodyCheckStyle"
                  class="border border-outlineGray text-left text-sm p-2 bg-white"
                >
                  <div class="text-center">
                    <input
                      :checked="
                        selectedEmployees.includes(
                          employee && employee.employeeId
                        )
                      "
                      @change="selectEmployee(employee && employee.employeeId)"
                      type="checkbox"
                      name=""
                      id=""
                    />
                  </div>
                </td>

                <td
                  @click="viewEmployee(employee.employeeId)"
                  class="border border-outlineGray bg-newLimeGreen text-left z-20 text-sm p-2 group-hover:bg-primary-100 group-hover:border-primary-300"
                >
                  <div class="flex items-center gap-1 capitalize">
                    <div class="w-full">
                      <div
                        v-if="
                          employee &&
                          employee.employee.profile &&
                          employee.employee.profile.pfp
                        "
                        class="border w-9 h-9 rounded-full overflow-hidden"
                      >
                        <img
                          class="w-full h-full object-cover"
                          :src="
                            employee &&
                            employee.employee.profile &&
                            employee.employee.profile.pfp
                          "
                          :alt="
                            employee &&
                            employee.employee.profile &&
                            employee.employee.profile.firstName +
                              ' ' +
                              employee &&
                            employee.employee.profile &&
                            employee.employee.profile.lastName
                          "
                        />
                      </div>
                      <div
                        v-else
                        class="bg-primary p-2 h-9 w-9 text-sm uppercase text-white font-medium rounded-full text-center"
                      >
                        {{ getAvatar(employee && employee.employee).name }}
                      </div>
                    </div>
                    <span class="w-full">
                      {{
                        display(
                          employee &&
                            employee.employee.profile &&
                            employee.employee.profile.firstName
                        )
                      }}</span
                    >
                  </div>
                </td>
                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray bg-newLimeGreen text-left z-20 text-sm p-2 group-hover:bg-primary-100 group-hover:border-primary-300"
                >
                  {{
                    display(
                      employee &&
                        employee.employee.profile &&
                        employee.employee.profile.lastName
                    )
                  }}
                </td>

                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray text-left text-sm p-2"
                  style="background: #fcfcfc"
                >
                  {{ formatAmount(employee && employee.grossAmount) }}
                </td>
                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray text-left text-sm p-2"
                  style="background: #fcfcfc"
                >
                  {{ formatAmount(employee && employee.netAmount) }}
                </td>
                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray capitalize text-left text-sm p-2"
                  style="background: #fcfcfc"
                >
                  {{ employee && employee.accountName }}
                </td>
                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray text-left text-sm p-2"
                  style="background: #fcfcfc"
                >
                  {{ employee && employee.accountNumber }}
                </td>
                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray text-left text-sm p-2"
                  style="background: #fcfcfc"
                >
                  {{
                    (employee && employee.bankName) ||
                    fetchBankName(employee.bankCode)
                  }}
                </td>

                <td
                  v-for="all in allowances"
                  :key="all._id"
                  @click="viewEmployee(employee && employee.status)"
                  class="border border-outlineGray text-left text-sm p-2"
                  style="background: #fcfcfc"
                >
                  {{
                    employee &&
                    employee[all.name] &&
                    employee[all.name].percentage
                      ? formatAmount(
                          calculatePercentage(
                            employee.grossAmount,
                            employee[all.name].percentage
                          )
                        )
                      : employee[all.name] && employee[all.name].amount
                      ? formatAmount(employee[all.name].amount || 0)
                      : formatAmount(0)
                  }}
                </td>
                <!-- <td
                  @click="viewEmployeeemployee && employee.employeeId"
                  class="border border-outlineGray text-left text-sm p-2"
                >
                  {{ formatDateString(employee && employee.createdAt) }}
                </td> -->
                <td
                  @click="viewEmployee(employee && employee.status)"
                  class="border border-outlineGray text-left text-sm p-2 font-semibold capitalize"
                  :class="getStatus(employee && employee.status)"
                >
                  {{ employee && employee.status }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- //////// Desktop View Ends ////////  -->

      <!-- //////// Mobile View Start //////// -->
      <div class="flex md:hidden w-full" v-if="visibleData.length">
        <div class="w-full">
          <div class="w-full flex items-center justify-between mb-2">
            <div class="flex items-center gap-2">
              <input
                type="checkbox"
                :checked="allChecked"
                class="h-4 w-4"
                @change="toggleCheckAll()"
              />

              <span class="text-dark-600">Select All</span>
            </div>

            <div>
              <span
                >{{
                  currentPage == 0 ? currentPage + 1 : pageSize + currentPage
                }}
                -
                {{
                  currentPage == 0
                    ? pageSize > allEmployees && allEmployees.length
                      ? allEmployees && allEmployees.length
                      : pageSize
                    : pageSize + visibleData.length
                }}
                of
                {{ allEmployees && allEmployees.length }}</span
              >
            </div>
          </div>

          <div
            v-for="employee in visibleData"
            :key="employee"
            class="w-full mb-8"
          >
            <table class="table-auto w-full overflow-y-auto">
              <tbody class="font-medium text-sm">
                <tr>
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-newLimeGreen"
                  >
                    <div class="flex items-center gap-2">
                      <input
                        :checked="
                          selectedEmployees.includes(
                            employee && employee.employeeId
                          )
                        "
                        @change="
                          selectEmployee(employee && employee.employeeId)
                        "
                        type="checkbox"
                        name=""
                        id=""
                      />
                      First Name
                    </div>
                  </td>
                  <td
                    @click="viewEmployee(employee && employee.employeeId)"
                    class="w-8/12 border border-primary-200 text-left p-2 px-3 bg-newLimeGreen group-hover:bg-primary-100 group-hover:border-primary-300"
                  >
                    <div class="flex items-center gap-2">
                      <div
                        v-if="
                          employee &&
                          employee.employee.profile &&
                          employee.employee.profile.pfp
                        "
                        class="border w-8 h-8 rounded-full overflow-hidden"
                      >
                        <img
                          class="w-full h-full object-cover"
                          :src="
                            employee &&
                            employee.employee.profile &&
                            employee.employee.profile.pfp
                          "
                          :alt="
                            employee &&
                            employee.employee.profile &&
                            employee.employee.profile.firstName +
                              ' ' +
                              employee &&
                            employee.employee.profile &&
                            employee.employee.profile.lastName
                          "
                        />
                      </div>
                      <div
                        v-else
                        class="bg-primary uppercase p-2 h-8 w-8 text-xs text-white text-center font-medium rounded-full"
                      >
                        {{ getAvatar(employee && employee.employee).name }}
                      </div>
                      {{
                        display(
                          employee &&
                            employee.employee.profile &&
                            employee.employee.profile.lastName
                        )
                      }}
                    </div>
                  </td>
                </tr>
                <tr @click="viewEmployee(employee && employee.employeeId)">
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-outlineGray"
                  >
                    <div class="flex items-center gap-2">Last Name</div>
                  </td>
                  <td
                    class="w-8/12 border border-outlineGray text-left p-2 bg-newLimeGreen px-3"
                  >
                    {{
                      display(
                        employee &&
                          employee.employee.profile &&
                          employee.employee.profile.lastName
                      )
                    }}
                  </td>
                </tr>
                <tr @click="viewEmployee(employee && employee.employeeId)">
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-outlineGray"
                  >
                    <div class="flex items-center gap-2">Gross Pay</div>
                  </td>
                  <td
                    class="w-8/12 border bg-white border-outlineGray text-left p-2 px-3"
                  >
                    {{ formatAmount(employee && employee.grossAmount) }}
                  </td>
                </tr>

                <tr @click="viewEmployee(employee && employee.employeeId)">
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-outlineGray"
                  >
                    <div class="flex items-center gap-2">Net Pay</div>
                  </td>
                  <td
                    class="w-8/12 border border-outlineGray bg-white text-left p-2 px-3"
                  >
                    {{ formatAmount(employee && employee.netAmount) }}
                  </td>
                </tr>

                <tr @click="viewEmployee(employee && employee.employeeId)">
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-outlineGray"
                  >
                    <div class="flex items-center gap-2">Status</div>
                  </td>
                  <td
                    class="w-8/12 border border-outlineGray text-left p-2 px-3 bg-white"
                    :class="getStatus(employee && employee.status)"
                  >
                    {{ employee && employee.status }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- //////// Mobile View End //////// -->
    <!-- //////// Pagination //////// -->
    <easiPagin
      v-if="allEmployees && allEmployees.length"
      :data="allEmployees"
      @page:update="updatePage"
      :currentPage="currentPage"
      :pageSize="pageSize"
      class="my-4"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import banks from "@/utils/banks";

import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import EmptyTable from "@/components/global/emptyTable.vue";

const { formatAmount, formatDateString, getAvatar, calculatePercentage } =
  helperFunctions;
const route = useRoute();
const router = useRouter();
const store = useDataStore();

const { query } = store;

const props = defineProps({
  isSearch: {
    type: Boolean,
    default: false,
  },
  showPaid: {
    type: Boolean,
    default: false,
  },
  searchText: {
    type: String,
    default: "",
  },
  employees: {
    type: Array,
    default: [],
  },
  update: {
    type: Function,
  },
  paymentStatus: {
    type: String,
  },
});

const emit = defineEmits(["selected"]);
const watchPaymentStatus = computed(() => props.paymentStatus);
const watchSearch = computed(() => props.isSearch);

const currentPage = ref(0);
const pageSize = ref(15);
const visibleData = ref([]);
const allChecked = ref(false);
const selectedEmployees = ref([]);
const allowances = computed(() =>
  store.listAllowance && store.listAllowance.data
    ? store.listAllowance.data
    : []
);

let headers = reactive([
  "First Name",
  "Last Name",
  "Gross Pay",
  "Net Pay",
  "Account Name",
  "Account Number",
  "Bank Name",
  ...allowances.value.map((a) => a.name),
  //   "Date Paid",
  "Status",
]);

const payrollForMonth = computed(() => store.getPayrollHistoryForMonth);

const fetchBankName = (code) => {
  const data = banks.find((bank) => bank.code === code);
  return data && data.name ? data.name : "N/A";
};

const queryAllowance = async () => {
  try {
    await query({
      endpoint: "ListAllowance",
      payload: {
        paging: {
          limit: 1000,
          skip: 0,
          search: null,
        },
      },
      service: "PAYROLL",
      storeKey: "allowance",
    });
  } catch (e) {
    console.log(e);
  }
  console.log(allowances.value, "AL");
};
queryAllowance();
onMounted(async () => {
  await queryAllowance();
});

// console.log("PAYROLL", payrollForMonth.value);

const allEmployees = ref([]);

const getStatus = (arg) => {
  const status = {
    paid: "text-success",
    failed: "text-error",
    "not paid": "text-error",
    processing: "text-pendingStatus",
  };

  return arg ? status[arg] : "";
};

onMounted(async () => {
  await updateVisibleData();
});

watch(payrollForMonth, () => {
  updateVisibleData();
});

watch(watchPaymentStatus, () => {
  updateVisibleData();
});
watch(watchSearch, () => {
  updateVisibleData();
});

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};

const transformArr = (arr) => {
  let newEmployeeArr = arr.map((employee) => {
    const { allowanceDetails, netAmount, grossAmount } = employee.staffLevel;

    return {
      allowanceDetails,
      netAmount,
      grossAmount,
      paymentDetails: employee.payment,
      employee,
      employeeId: employee.employeeId,
      status: "not paid",
    };
  });
  return newEmployeeArr;
};

const bringToTopLevel = (arr, src) => {
  if (!props.showPaid && src === "unsent") {
    arr = transformArr(arr);
  }

  const updatedDataArray = arr.map((dataObject) => {
    const allowanceDetailsKeys =
      dataObject && dataObject.allowanceDetails
        ? Object.keys(dataObject.allowanceDetails)
        : [];
    const paymentDetailsKeys =
      dataObject && dataObject.paymentDetails
        ? Object.keys(dataObject.paymentDetails)
        : [];

    allowanceDetailsKeys.forEach(
      (key) => (dataObject[key] = dataObject.allowanceDetails[key])
    );
    paymentDetailsKeys.forEach(
      (key) => (dataObject[key] = dataObject.paymentDetails[key])
    );

    return dataObject;
  });
  return updatedDataArray;
};

const updateVisibleData = async () => {
  if (payrollForMonth.value) {
    let datas = [];
    if (
      props.showPaid &&
      payrollForMonth.value.salaryRecords &&
      payrollForMonth.value.salaryRecords.length
    ) {
      datas = bringToTopLevel(
        payrollForMonth.value.salaryRecords,
        "salary"
      ).filter((re) => re.status.toLowerCase() === "paid");
    } else if (!props.showPaid) {
      let salaries = [];
      let unSent = [];
      if (
        payrollForMonth.value.salaryRecords &&
        payrollForMonth.value.salaryRecords.length
      ) {
        salaries = bringToTopLevel(
          payrollForMonth.value.salaryRecords,
          "salary"
        ).filter((re) => re.status.toLowerCase() !== "paid");
      }

      if (payrollForMonth.value.unsentEmployees) {
        unSent = bringToTopLevel(
          payrollForMonth.value.unsentEmployees,
          "unsent"
        );
      }
      console.log(unSent);
      datas = salaries.concat(unSent);
    }
    allEmployees.value = datas;

    if (props.paymentStatus !== "all") {
      datas = datas.filter((emp) => {
        if (emp.status == props.paymentStatus.toLowerCase()) {
          return emp;
        }
      });
    }

    if (props.isSearch) {
      datas = datas.filter(
        (emp) =>
          emp.employee.profile.firstName
            .toLowerCase()
            .includes(props.searchText.toLowerCase()) ||
          emp.employee.profile.lastName
            .toLowerCase()
            .includes(props.searchText.toLowerCase())
      );
    }

    visibleData.value = datas.slice(
      currentPage.value * pageSize.value,
      currentPage.value * pageSize.value + pageSize.value
    );

    if (visibleData.value.length == 0 && currentPage.value > 0) {
      updatePage(currentPage.value - 1);
    }
  } else {
    visibleData.value = [];
  }
};

const toggleCheckAll = () => {
  allChecked.value = !allChecked.value;

  // console.log(allEmployees.value, "Aemp");

  if (allChecked.value) {
    visibleData.value.forEach((emp) => {
      if (!selectedEmployees.value.includes(emp.employeeId)) {
        ////// Toggle only employee that matches the payment status filter if filter is on ///
        if (props.paymentStatus !== "all") {
          if (emp.status == props.paymentStatus.toLowerCase()) {
            selectedEmployees.value.push(emp.employeeId);
          }
        } else {
          selectedEmployees.value.push(emp.employeeId);
        }
      }
    });
  } else {
    selectedEmployees.value = [];
  }

  // console.log("selected", selectedEmployees.value);

  emit("selected", selectedEmployees.value);
};

const selectEmployee = async (arg) => {
  allChecked.value = false;

  if (selectedEmployees.value.includes(arg)) {
    selectedEmployees.value = [
      ...selectedEmployees.value.filter((id) => arg !== id),
    ];
  } else {
    selectedEmployees.value.push(arg);
  }

  console.log("SELECTED", selectedEmployees.value);

  emit("selected", selectedEmployees.value);
};

const viewEmployee = (id) => {
  if (typeof id == "string") {
    router.push({
      name: "PayrollHistoryDetails",
      query: { id: id },
    });
  }
};

function display(arg) {
  return arg ? arg : "N/A";
}

const style = computed(() => {
  return "padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #D7DCF6 line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 1px; border-top-width: 1px; background: #E7E8E7;;margin-bottom: 10px;color: #515251; border: 1px solid #E7E8E7; font-size: 12px; box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);";
});
const activeHeader = computed(() => {
  return "padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #E7E8E7; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 2px; border-top-width: 0; background: #E7E8E7;margin-bottom: 10px;color: #ffffff; font-size: 14px;";
});
const headStyle = computed(() => {
  return "padding-top: 0.75rem; padding-bottom: 0.75rem;  border-width: 2px; border-top-width: 0;margin-bottom: 10px;border-top-left-radius: 1rem;border-bottom-left-radius: 0rem; border: 1px solid #E7E8E7; font-size: 12px; text-align: center; width:3em";
  //   return "width: 3em;border: 1px solid #B5BDE1;  border-width: 2px; background: #E7E8E7;border-top-left-radius: 1rem;border-bottom-left-radius: 0rem; border: 1px solid #E7E8E7; font-size: 12px; text-align: left;margin-bottom: 10px;";
});
const lastHeaderStyle = computed(() => {
  return "padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #D7DCF6 line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 1px; border-top-width: 1px; background: rgba(240, 247, 235, 1);;margin-bottom: 10px;border-top-right-radius: 1rem;border-bottom-right-radius: 0rem;color: #515251; font-size: 14px; ";
});

const bodyCheckStyle = computed(() => {
  return "width: 2.4em;border: 1px solid #B5BDE1; color:#515251";
});
const highlightField = () => {
  return "background: rgba(240, 247, 235, 1); box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.2);";
  // return "background: #FCFCFC;";
};
</script>

<style></style>
