<template>
  <main>
    <easiModal rounded="3xl" @close="close">
      <template v-slot:header>
        <h1 class="capitalize text-sm font-medium text-headerText">
          Topup Wallet
        </h1>
      </template>
      <div class="flex flex-col mt-6 items-center">
        <img class="w-40" :src="sad" alt="" />
        <span class="text-sm font-bold">Insuficient Wallet Fund</span>
      </div>
      <div class="flex flex-col mt-5 items-center gap-y-4">
        <p class="text-gray">Please select an option to Top Up your wallet</p>

        <div
          class="flex md:flex-row flex-col md:items-center md:justify-center gap-5 w-full px-6"
        >
          <div
            @click="bankTransfer = true"
            :class="
              bankTransfer
                ? 'shadow shadow-xl bg-mint border-2 text-primary border-primary'
                : 'bg-white border-2 border-gray'
            "
            class="cursor-pointer flex flex-col items-center md:justify-center w-full gap-1 relative md:w-5/12 pt-2 px-4 md:pb-2 pb-5 rounded rounded-3xl"
          >
            <img
              v-show="bankTransfer"
              class="self-end justify-self-start place-self-start"
              src="@/assets/icons/checked.svg"
              alt=""
            />
            <div
              v-show="!bankTransfer"
              class="w-4 h-4 rounded-full border-2 border-gray bg-white self-end justify-self-start place-self-start"
            ></div>
            <span
              :class="
                !bankTransfer
                  ? 'font-medium text-gray'
                  : 'font-bold text-primary'
              "
              class="text-sm mt-2"
              >Bank Transfer</span
            >
            <img v-show="bankTransfer" src="@/assets/icons/arrow.svg" alt="" />
            <img
              v-show="!bankTransfer"
              src="@/assets/icons/arrow-inactive.svg"
              alt=""
            />
          </div>

          <div
            @click="bankTransfer = false"
            :class="
              !bankTransfer
                ? 'shadow shadow-xl bg-mint border-2 text-primary border-primary'
                : 'bg-white border-2 border-gray'
            "
            class="flex flex-col cursor-pointer items-center justify-center gap-1 relative w-full md:w-5/12 pt-2 px-6 pb-2 rounded rounded-3xl"
          >
            <img
              v-show="!bankTransfer"
              class="self-end justify-self-start place-self-start"
              src="@/assets/icons/checked.svg"
              alt=""
            />
            <div
              v-show="bankTransfer"
              class="w-4 h-4 rounded-full border float-right border-gray bg-white self-end justify-self-start place-self-start"
            ></div>
            <span
              :class="
                bankTransfer
                  ? 'md:font-medium md:text-gray font-bold text-dark-800'
                  : 'font-bold text-primary'
              "
              class="text-sm mt-2"
              >Paystack</span
            >
            <img
              v-show="!bankTransfer"
              class="invisible"
              src="@/assets/icons/arrow.svg"
              alt=""
            />
            <img
              v-show="bankTransfer"
              class="invisible"
              src="@/assets/icons/arrow-inactive.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <hr class="mt-8 mb-4 border-0.5 border-dividerColor text-dividerColor" />
      <div
        class="flex md:grid grid-cols-1 justify-center items-center flex-col gap-6 px-6 md:px-6 w-full my-6"
      >
        <easiButton
          block
          @click="
            bankTransfer
              ? (topupOption = false)((transferDetails = true))
              : (topupOption = false)((payStack = true))
          "
          class="w-full md:w-full self-center focus:outline-none mx-auto self-center text-sm py-3 px-4 bg-primary rounded-lg text-white"
        >
          Continue
        </easiButton>
        <span
          @click="topupOption = false"
          class="w-full md:w-full self-center cursor-pointer text-center bg-white rounded-full text-dark-800 font-medium border border-primary px-3 text-sm py-3"
          >Back to loan details</span
        >
      </div>
    </easiModal>

    <easiModal v-if="transferDetails" @close="transferDetails = false">
      <template v-slot:header>
        <h1 class="capitalize font-medium text-headerText">
          Transfer Directly to your Account
        </h1>
      </template>

      <div class="flex flex-col items-start gap-5">
        <div class="flex flex-col px-10 items-start">
          <span class="text-gray text-sm">Bank Name:</span>
          <span class="text-dark-800 font-medium text-base">{{
            companyAccount.bankName
          }}</span>
        </div>
        <hr class="border-b border-outlineGray w-full" />

        <div class="flex justify-between w-full px-10">
          <div class="flex flex-col items-start">
            <span class="text-gray text-sm">Account Name:</span>
            <span class="text-dark-800 font-medium text-base">{{
              companyAccount.accountName
            }}</span>
          </div>
          <div
            @click="copyText(companyAccount.accountName)"
            class="bg-newLimeGreen border border-outlineGray rounded rounded-full px-4 py-2 cursor-pointer flex items-center gap-3"
          >
            <img src="@/assets/icons/copy.svg" alt="" />
            <span class="text-primary font-bold inline-block text-sm"
              >Copy</span
            >
          </div>
        </div>
        <hr class="border-b border-outlineGray w-full" />
        <div class="flex justify-between w-full px-10">
          <div class="flex flex-col items-start">
            <span class="text-gray text-sm">Account Number:</span>

            <span id="copy" class="text-dark-800 font-medium text-base">{{
              companyAccount.accountNumber
            }}</span>
          </div>
          <div
            @click="copyText(companyAccount.accountNumber)"
            class="bg-newLimeGreen border border-outlineGray rounded rounded-full px-4 py-2 cursor-pointer flex items-center gap-3"
          >
            <img src="@/assets/icons/copy.svg" alt="" />
            <span class="text-dark-800 font-bold inline-block text-sm"
              >Copy</span
            >
          </div>
        </div>
      </div>
      <hr class="mt-4 mb-4 border border-outlineGray text-outlineGray" />
      <div
        class="flex flex-col flex-col-reverse px-6 md:px-0 md:flex-row gap-6 w-full my-6 md:justify-center md:items-center"
      >
        <span
          @click="transferDetails = false"
          class="w-full md:w-40 cursor-pointer text-center bg-white rounded-full text-dark-800 font-medium border border-primary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          @click="
            () => {
              transferDetails = false;
              transferNotification = true;
            }
          "
          class="w-full md:w-auto focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white"
        >
          I Have Made The Transfer
        </easiButton>
      </div>
    </easiModal>

    <easiModal :isBorder="false" v-if="transferNotification" @close="close">
      <div class="flex flex-col items-center justify-center px-10 gap-8">
        <span class="text-xl text-center mt-9 font-medium text-dark-800"
          >{{ title }}.</span
        >
        <img src="@/assets/icons/wallet-icon.svg" alt="" />
      </div>

      <hr class="mt-8 mb-4 border-0.5 border-dividerColor text-dividerColor" />
      <div class="flex gap-x-6 w-full my-6 justify-center items-center">
        <easiButton
          @click="action"
          class="w-80 md:w-auto focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white"
        >
          <span class="text-sm"> {{ btnText }} </span>
        </easiButton>
      </div>
    </easiModal>

    <easiModal v-if="payStack" @close="payStack = false">
      <template v-slot:header>
        <div class="flex flex-col items-center justify-center6 gap-2">
          <h1 class="capitalize font-medium text-headerText">pay via</h1>

          <div class="bg-white w-11/12 rounded rounded-xl p-5">
            <img src="@/assets/icons/paystack.svg" alt="" />
          </div>
        </div>
      </template>

      <div v-show="!linkGenerated" class="flex flex-col items-center">
        <form class="flex gap-4" action="">
          <div>
            <label class="text-dark-800 font-normal text-sm mr-2" for="amount"
              >One Time Payment</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="false"
              v-model="recurrentPayment"
              :checked="!recurrentPayment"
            />
          </div>

          <div>
            <label
              class="text-dark-800 font-normal text-sm mx-2"
              for="percentage"
              >Recurrent Payment</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="true"
              v-model="recurrentPayment"
              :checked="recurrentPayment"
            />
          </div>
        </form>
      </div>

      <span
        v-show="linkGenerated"
        @click="copyText(link)"
        class="text-hoverColor font-medium text-sm text-center"
        >{{ link }}</span
      >

      <div
        v-show="linkGenerated"
        class="flex mt-2 gap-x-5 justify-center items-center"
      >
        <div
          @click="copyText(link)"
          class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
        >
          <img src="@/assets/icons/copy.svg" alt="" />
          <span class="text-dark-800 font-bold inline-block text-sm">Copy</span>
        </div>
        <div
          @click="shareLink = true"
          class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
        >
          <img src="@/assets/icons/share.svg" alt="" />
          <span class="text-dark-800 font-bold inline-block text-sm"
            >Share</span
          >
        </div>
      </div>

      <hr class="mt-5 mb-7 border-0.5 border-dividerColor text-dividerColor" />
      <div
        v-show="linkGenerated"
        class="flex flex-col md:flex-row px-6 md:px-0 gap-6 w-full my-6 md:justify-center flex-col-reverse md:items-center"
      >
        <span
          @click="
            () => {
              payStack = false;
              linkGenerated = false;
            }
          "
          class="w-full md:w-40 cursor-pointer text-center bg-white rounded-lg text-dark-800 font-medium border border-secondary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          @click="
            () => {
              payStack = false;
              linkGenerated = false;
              transferNotification = true;
            }
          "
          class="w-full md:w-auto focus:outline-none text-sm py-3 px-4 bg-secondary rounded-lg text-white"
        >
          <span class="text-sm"> I Have Made The Transfer</span>
        </easiButton>
      </div>

      <form
        @submit.prevent="initPaystack"
        v-show="!linkGenerated"
        class="flex flex-col w-full"
        autocomplete="on"
      >
        <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
          <label
            for="paymentName"
            class="text-left text-dark-800 text-sm font-medium"
            >Payment Name</label
          >
          <easiTextInput
            placeholder="Payment Name"
            type="text"
            name="paymentName"
            class="mt-1 mb-5"
          />

          <!-- <label
            for="description"
            class="text-dark-800 text-left text-sm font-medium"
            >Description
          </label> -->
          <easiTextArea
            class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-6"
            name="description"
            id="description"
            cols="30"
            rows="5"
            placeholder="Payment Description"
          ></easiTextArea>

          <label
            for="amount"
            class="text-left text-dark-800 text-sm font-medium"
            >Amount</label
          >
          <easiTextInput
            placeholder="Input Amount"
            type="text"
            :format="true"
            name="amount"
            v-model="paystackArgs.amount"
            class="mt-1 mb-5"
            required
          />
          <label
            v-show="recurrentPayment"
            for="Interval"
            class="text-dark-800 text-left text-sm font-medium"
            >Select Interval</label
          >

          <easiSelectInput
            v-if="recurrentPayment === true"
            required
            class="mt-1 mb-5 text-left"
            @update="interval = $event"
            :value="interval"
            :options="payrollInterval"
            :searchable="true"
            :autoHeight="true"
          />
        </div>
        <div class="w-full my-3 md:justify-center md:items-center px-4 md:px-4">
          <easiButton
            block
            class="focus:outline-none text-sm py-3 bg-primary text-white"
          >
            Generate Payment Link
          </easiButton>
        </div>
      </form>
    </easiModal>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import sad from "@/assets/icons/sad-face.svg";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const { formatAmount, calculatePercentage } = helperFunctions;

const props = defineProps({
  title: {
    type: String,
    default: "Title",
  },
  btnText: {
    type: String,
    default: "Text",
  },
  action: {
    type: Function,
    default: (e) => {
      e = false;
    },
  },
});

const emit = defineEmits(["close"]);

function close() {
  emit("close");
}

const queryCompany = async () => {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
};
queryCompany();

const companyAccount = computed(() => store.getCompanyAccountDetails);
const balance = computed(() => store.getWalletBalance);
const payStackRes = computed(() => store.getPayStackResponse);
const paystackArgs = ref({
  amount: 0,
  email: "",
});

const bankTransfer = ref(true);
const topupOption = ref(false);
const transferDetails = ref(false);
const transferNotification = ref(false);
const payStack = ref(false);
const linkGenerated = ref(false);

const recurrentPayment = ref(false);
const accountNumber = ref("0711456765");

function copyText(val) {
  var text = val;
  navigator.clipboard.writeText(text);
  toast.info("Copied!");
}

const initPaystack = async () => {
  paystackArgs.value.amount = Number(paystackArgs.value.amount);
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "PaystackPaymentInit",
      data: { input: paystackArgs.value },
      service: "PAYROLL",
    });

    if (res.authorizationUrl.length) {
      link.value = res.authorizationUrl;
      await verifyPaystack(res.reference);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};

async function queryCompanyAccountDetails() {
  try {
    await query({
      endpoint: "ViewCompanyVirtualAccount",
      service: "AUTH",
      storeKey: "companyAccountDetails",
    });
  } catch (e) {
    console.log(e);
  }
}

onMounted(async () => {
  await queryCompanyAccountDetails();
});
</script>

<style></style>
