<template>
  <div class="">
    <div class="w-full text-left font-bold text-dark-800">
      <h2 class="text-xl text-2xl">Recent Activities</h2>
    </div>

    <div class="flex items-center justify-center mt-4 mb-2 gap-4">
      <!-- /////// Search Component ///// -->
      <div class="w-full">
        <ActivitySearchVue
          placeholder="Search by employee, activity or amount"
          v-model="searchText"
          @search="searchFN"
        />
      </div>

      <!-- /////// Filter Component Start /////// -->
      <div class="">
        <TransactionFilter ref="transactionFilterRef" @update="filterFN" />
      </div>
      <!-- /////// Filter Component Ends /////// -->
    </div>
    <easiActiveFilter @close="removeFilter" :filters="activeFilters" />

    <!-- <easiButton @click="queryActivities" size="small">Click Me</easiButton> -->
    <div v-if="loading" class="py-4">
      <easiLoader circle />
    </div>
    <div
      v-else
      style="max-height: 700px"
      class="overflow-auto darkBg w-full my-8 py-4"
    >
      <empty
        v-if="visibleData && !visibleData.length"
        :text="'No Recent Activities Yet'"
      />
      <div v-else>
        <div
          v-if="notFound"
          class="container mx-auto items-center justify-center w-full sm:w-11/12 my-6 grid grid-cols-2 gap-2"
        >
          <div class="flex flex-col text-left col-span-2 md:col-span-1">
            <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
              Oops!
            </h1>

            <div class="text-sm md:text-lg">
              Sorry we couldn’t find a result for your request.
            </div>
            <p class="text-xs md:text-sm mt-2">
              Check your spelling or try to use a different word. <br />
              Make sure you search by
              <span class="font-bold text-secondary">Date</span> or
              <span class="font-bold text-secondary">Activity Name</span>
            </p>
          </div>
          <div class="col-span-2 md:col-span-1">
            <img src="@/assets/icons/search_null.svg" alt="" />
          </div>
        </div>
        <div
          v-else
          v-for="(item, i) in visibleData"
          :key="i"
          class="w-full text-left p-4 lg:px-6 flex flex-col gap-4"
        >
          <h4 class="font-medium">
            {{ weeks[item.day] }}, {{ months[item.month] }} {{ item.date }},
            {{ item.year }}
          </h4>

          <div
            v-for="data in item.data"
            :key="data.createdAt + data.type"
            class="w-full rounded-2xl border border-blueHoverColor shadow-md p-4 md:px-8 flex flex-col lg:grid grid-cols-5 gap-4 bg-white"
          >
            <div>
              <span class="uppercase text-xs text-grey">{{ data.type }}</span>
              <div class="flex items-center gap-2 mt-2">
                <div class="inline-block items-center gap-2">
                  <div
                    v-if="data.creator && data.creator.pfp"
                    class="border w-9 h-9 rounded-full overflow-hidden"
                  >
                    <img
                      class="object-cover h-full w-full"
                      :src="data.creator && data.creator.pfp"
                      :alt="data.creator && data.creator.firstName"
                    />
                  </div>
                  <!-- :style="`background-color:${getAvatar(data).color}`" -->
                  <div
                    v-else
                    class="p-2 h-9 w-9 uppercase flex items-center justify-center text-sm bg-primary text-white font-medium rounded-full"
                  >
                    {{ getAvatar(data) }}
                  </div>
                </div>
                <span class="font-medium"> {{ data.name }}</span>
              </div>
            </div>
            <div
              :class="[data.totalEmployees ? 'col-span-1' : 'col-span-2']"
              class="flex flex-col"
              v-if="data.activity"
            >
              <span class="hidden lg:block uppercase text-xs text-grey"
                >Activity</span
              >
              <span class="mt-2 font-medium break-words">{{
                data.activity
              }}</span>
            </div>

            <!-- //////// Desktop Style ///// -->
            <div class="md:flex flex-col hidden" v-if="data.totalEmployees">
              <span class="uppercase text-xs text-grey">Employee</span>
              <span class="mt-2 font-medium">{{ data.totalEmployees }}</span>
            </div>
            <div
              class="md:flex flex-col hidden"
              v-if="data.type === 'Bonus' || data.type === 'Deduction'"
            >
              <span class="uppercase text-xs text-grey"
                >{{ data.type }} Name</span
              >
              <span class="mt-2 font-medium capitalize">{{
                data.bonusName
              }}</span>
            </div>
            <div class="md:flex flex-col hidden" v-if="data.amount">
              <span class="uppercase text-xs text-grey">Amount</span>
              <span class="mt-2 font-medium text-secondary">{{
                formatAmount(data.amount)
              }}</span>
            </div>
            <div class="md:flex flex-col hidden" v-if="data.time">
              <span class="uppercase text-xs text-grey">Time</span>
              <span class="mt-2 font-medium">{{ data.time }}</span>
            </div>

            <!-- //////// Mobile Style ///// -->
            <div class="md:hidden flex gap-4 items-center">
              <div class="md:flex flex-col hidden" v-if="data.totalEmployees">
                <span class="uppercase text-xs text-grey">Employee</span>
                <span class="mt-2 font-medium">{{ data.totalEmployees }}</span>
              </div>
              <div
                class="flex flex-col"
                v-if="data.type === 'Bonus' || data.type === 'Deduction'"
              >
                <span class="uppercase text-xs text-grey"
                  >{{ data.type }} Name</span
                >
                <span class="mt-2 font-medium capitalize">{{
                  data.bonusName
                }}</span>
              </div>
              <div class="flex flex-col" v-if="data.amount">
                <span class="uppercase text-xs text-grey">Amount</span>
                <span class="mt-2 font-medium text-secondary">{{
                  formatAmount(data.amount)
                }}</span>
              </div>
              <div class="flex flex-col" v-if="data.time">
                <span class="uppercase text-xs text-grey">Time</span>
                <span class="mt-2 font-medium">{{ data.time }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

import TransactionFilter from "@/components/Filters/TransactionFilter.vue";
import ActivitySearchVue from "../Search/ActivitySearch.vue";
import empty from "@/components/global/EmptyTransaction.vue";

const router = useRouter();

const toast = useToast();
const store = useDataStore();
const { query, mutate, set } = store;
const { getAllEmployees, checkLoading } = storeToRefs(store);
const { formatAmount, calculatePercentage, log } = helperFunctions;

const loading = ref(false);
const notFound = ref(false);

const queryActivities = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListActivities",
      data: {},
      service: "SETTINGS",
      storeKey: "activities",
    });
    transformActivities();

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

queryActivities();

const recentActivities = computed(() => store.getRecentActivities.data);
const visibleData = ref([]);

let searchText = ref("");
let isSearch = ref(false);
let filterOption = ref("Date");
const activeFilters = ref([]);
const transactionFilterRef = ref(null);

let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let weeks = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

watch(searchText, async (val) => {
  // console.log(val.trim(), isSearch.value);

  if (val.trim() === "") {
    notFound.value = false;
    await transformActivities();
    isSearch.value = false;
  }
});

const getAvatar = (emp) => {
  if (emp && emp.name) {
    let splitted = emp.name.split(" ");
    return `${splitted[0] && splitted[0].charAt(0)}${
      splitted[1] ? splitted[1].charAt(0) : ""
    }`;
  } else {
    return "N/A";
  }
};

const searchFN = async (suggested) => {
  console.log("Suggestion", suggested);
  if (suggested.length) {
    notFound.value = false;
    transformActivities(suggested);
  } else {
    notFound.value = true;
  }
  isSearch.value = true;
};

const filterFN = ({ filter, active }) => {
  isSearch.value = true;
  filterOption.value = filter;

  transformActivities();

  activeFilters.value = active;
};

const removeFilter = (arg) => {
  console.log(transactionFilterRef.value);
  if (transactionFilterRef.value && transactionFilterRef.value.removeFilter) {
    transactionFilterRef.value.removeFilter(arg);
  }
};

const initializeDate = (arr) => {
  visibleData.value = [];

  arr.forEach((el) => {
    const newDate = {
      year: el.year,
      month: el.month,
      date: el.date,
      day: el.day,
      // dateString: new Date(`${el.date}/${el.month}/${el.year}`),
      data: [],
    };
    if (!visibleData.value.length) {
      visibleData.value.push(newDate);
    } else {
      if (!isHasDate(newDate)) {
        visibleData.value.push(newDate);
      }
    }
  });

  function isHasDate(arg) {
    return visibleData.value.find(
      (el) =>
        el.year === arg.year && el.month === arg.month && el.date === arg.date
    );
  }
};

const transformActivities = (payload) => {
  let arr = [];
  let transformedData = [];

  arr = payload ? payload : filterActivities(); //filter activities by filter option and add their typess

  arr.map((el) => {
    const d = new Date(el.createdAt);

    el.year = d.getFullYear();
    el.date = d.getDate();
    el.month = d.getMonth();
    el.day = d.getDay();
    if (el.type !== "Bonus" && el.type !== "Deduction") {
      el.time = d.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
  });

  ///// Get Activities Dates  //////

  initializeDate(arr);

  /////// update Visible Data ////
  organizeDataIntoDates(arr);

  ////// sortData Visible Data//////
  visibleData.value = visibleData.value.sort((a, b) => b.day - a.day);
  visibleData.value = visibleData.value.sort((a, b) => b.date - a.date);
  visibleData.value = visibleData.value.sort((a, b) => b.month - a.month);
  visibleData.value = visibleData.value.sort((a, b) => b.year - a.year);
};

const organizeDataIntoDates = (arg) => {
  if (arg) {
    arg.forEach((el) => {
      visibleData.value.forEach((ol) => {
        if (
          el.year === ol.year &&
          el.month === ol.month &&
          el.date === ol.date
        ) {
          ol.data.push(el);
        }
      });
    });
  }

  // console.log("Visible Data");
  // log(visibleData.value);
};

const filterActivities = () => {
  let arr = [];
  if (recentActivities.value) {
    const obj = {
      Bonus: recentActivities.value.bonusActivities,
      "Employee Management": recentActivities.value.employeeActivities,
      Payroll: recentActivities.value.payrollActivities,
      Wallet: recentActivities.value.walletActivities,
      Deduction: recentActivities.value.deductionActivities,
    };

    if (filterOption.value === "Date") {
      Object.keys(obj).map((key) => {
        ////// Add Type
        obj[key].forEach((el) => {
          el.type = key;
        });

        arr = [...arr, ...obj[key]];
      });
    } else {
      ////// Add Type
      obj[filterOption.value].map((el) => {
        el.type = filterOption.value;
      });

      arr = obj[filterOption.value];
    }
  }
  return arr;
};
</script>

<style scoped>
.darkBg {
  background: rgba(242, 241, 241, 0.5);
}
</style>
