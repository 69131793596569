<template>
  <main class="my-6 w-full">
    <div
    class="flex items-center mx-auto  tracking-wide justify-center md:mb-- mb-0  text-sm font-medium cursor-pointer"

    >
    <div class="bg-white py-0 px-2 border border-outlineGray rounded-xl flex gap-x-2 overflow-x-auto">
      <router-link
      @click="moveCenter($event)"
      class="text-left text-sm -z-1 whitespace-nowrap py-3.5"
      v-for="link in links"
      :key="link.name"
      :to="{ name: link.routeName, params: { id: $route.params.id } }"
      v-slot="{ href, navigate, isActive }"
    >
      <a
        :href="href"
        @click="navigate"
      class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2 whitespace-nowrap "

        :class="[
          isActive
            ? 'bg-primary shadow-2xl text-white'
            : 'bg-newGrey',
        ]"
      >
        {{ link.name }}
      </a>
    </router-link>

    </div>
      
    </div>
  </main>
</template>

<script setup>
import { reactive, ref, computed } from "vue";
import { onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

const store = useDataStore();

const companyDetails = computed(() => store.getCompanyDetails);

const id = ref("");
const links = computed(() => {
  const list = [];
  const arr = [
    {
      name: "Profile",
      routeName: "Profile",
      routePath: "/settings/profile",
    },
    {
      name: "Manage Account",
      routeName: "ManageAccount",
      routePath: "/settings/manage-account",
    },
    {
      name: "Business Profile",
      routeName: "BusinessProfile",
      routePath: "/settings/business-profile",
    },
    {
      name: "Payroll Settings",
      routeName: "PayrollSettings",
      routePath: "/settings/payment",
    },
    {
      name: "Team",
      routeName: "Team",
      routePath: "/settings/team",
    },
    {
      name: "Security",
      routeName: "Security",
      routePath: "/settings/security",
    },
    {
      name: "Pricing",
      routeName: "Pricing",
      routePath: "/settings/pricing",
    },
    {
      name: "Refer",
      routeName: "Refer",
      routePath: "/settings/refer",
    },
  ];

  arr.forEach((el) => {
    if (
      el.name === "Business Profile" &&
      companyDetails.value &&
      companyDetails.value.registrationType === "COMPANY"
    ) {
      list.push(el);
    } else if (el.name !== "Business Profile") {
      list.push(el);
    }
  });

  return list;
});

onMounted(() => {
  id.value = useRoute().params.id;
});

const moveCenter = (e) => {
  const element = e.target;

  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });

  e.preventDefault();
};
</script>

<style>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
