<template>
  <easiLayout>
    <div
      class="py-10 h-full min-h-screen relative bg-newLimeGreen flex items-start sm:items-center justify-center"
    >
      <!-- ///////// Select Account Type Start Here /////// -->
      <div
        v-if="display === 'account-type'"
        class="w-11/12 sm:w-10/12 md:w-8/12 flex flex-col items-center justify-center"
      >
        <easiContainer
          bg="bg-white"
          :isClose="false"
          @close="$router.go(-1)"
          class="w-full sm:py-10 shadow-xl"
          paddingHorizontal=" "
        >
          <div class="w-full space-y-4 flex flex-col items-center">
            <!-- /////Personal Card //////-->
            <p class="text-secondary text-3xl font-bold">Welcome to Eazipay</p>
            <p class="pb-4 mx-4">
              Please choose an account to signup and get started
            </p>
            <div
              @click="selectregistrationType('PERSONAL')"
              :class="
                args.registrationType === 'PERSONAL'
                  ? ' bg-newLimeGreen border-newPaleGreen shadow-md'
                  : ' bg-newGrey  '
              "
              class="border w-11/12 sm:w-10/12 md:w-8/12 rounded-xl px-2 py-4 sm:p-4 md:py-6 relative cursor-pointer"
            >
              <span class="absolute top-3 right-3 cursor-pointer">
                <img
                  v-if="args.registrationType === 'PERSONAL'"
                  src="../../assets/icons/check-active.svg"
                  alt=""
                />
                <img v-else src="../../assets/icons/checkbox.svg" alt="" />
              </span>

              <img
                class="mx-auto my-3"
                src="../../assets/icons/checkActive1.svg"
                alt=""
              />

              <div class="text-center">
                <h5 class="font-bold my-1 text-primary text-sm sm:text-base">
                  Personal Account
                </h5>
                <p class="font-normal text-xs md:text-sm text-dark-800">
                  For individuals who pay their personal and domestic staff
                </p>
              </div>
            </div>
            <!-- ///// Business Card //////-->
            <div
              @click="selectregistrationType('COMPANY')"
              :class="
                args.registrationType === 'COMPANY'
                  ? 'border-newPaleGreen bg-newLimeGreen shadow-md'
                  : 'bg-newGrey'
              "
              class="border rounded-xl w-11/12 sm:w-10/12 md:w-8/12 px-2 py-4 sm:p-4 md:py-6 relative cursor-pointer"
            >
              <span class="absolute top-3 right-3 cursor-pointer">
                <img
                  v-if="args.registrationType === 'COMPANY'"
                  src="../../assets/icons/check-active.svg"
                  alt=""
                />
                <img v-else src="../../assets/icons/checkbox.svg" alt="" />
              </span>

              <img
                class="mx-auto my-3"
                src="../../assets/icons/business.svg"
                alt=""
              />

              <div class="text-center">
                <h5 class="font-bold my-1 text-primary text-sm sm:text-base">
                  Business Account
                </h5>
                <p class="font-normal text-xs md:text-sm text-dark-800">
                  For registered businesses-SMEs, startups, corporates, and
                  large companies.
                </p>
              </div>
            </div>

            <!-- //// button ///// -->
          </div>
          <div class="px-3 sm:px-12 md:px-20 mt-10">
            <easiButton
              block
              rounded="3xl"
              @click="args.registrationType ? (display = 'account-form') : null"
              color=" newGreen"
              >Get Started</easiButton
            >
          </div>
          <div
            class="sm:hidden text-dark-800 mt-8 text-base font-medium text-center"
          >
            Already have an account?
            <router-link class="text-secondary font-semibold" to="/login"
              >Sign in</router-link
            >
          </div>
        </easiContainer>
      </div>

      <!-- ///////// Select Account Type End Here /////// -->

      <!-- ///////// Account Form Start Here /////// -->
      <div
        v-if="display === 'account-form'"
        :class="args.registrationType === 'COMPANY' ? ' mt-12 ' : ''"
        class="w-10/12 md:w-8/12 flex flex-col items-center justify-center"
      >
        <easiContainer
          bg="bg-white "
          showBackbutton
          :isClose="false"
          class="w-full px-4 sm:px-6 md:px-8 md:px-30 h-80-screen"
          paddingHorizontal=" "
          @close="
            showPasscode ? (showPasscode = false) : (display = 'account-type')
          "
        >
          <div class="mb-3 mt-10">
            <h2
              class="text-secondary font-bold text-2xl sm:text-3xl md:text-4xl"
            >
              {{
                showPasscode ? "Create your Passcode" : " Welcome to Eazipay"
              }}
            </h2>
            <span v-if="!showPasscode" class="text-base md:text-md font-medium"
              >Please signup to get started</span
            >
          </div>
          <form class="" @submit.prevent="submit" ref="form" autocomplete="on">
            <div
              :class="[
                args.registrationType === 'COMPANY'
                  ? 'grid grid-cols-2 gap-x-2'
                  : 'grid grid-cols-1',
              ]"
            >
              <easiTextInput
                v-if="!showPasscode"
                placeholder="First Name"
                v-model="args.firstName"
                type="text"
                name="firstName"
                class="my-4 col-span-1"
                :error="errorRules.firstName"
              />

              <easiTextInput
                v-if="!showPasscode"
                placeholder="Last Name"
                v-model="args.lastName"
                type="text"
                name="lastName"
                class="my-4 col-span-1"
                :error="errorRules.lastName"
              />
              <easiTextInput
                v-if="!showPasscode"
                placeholder="Email"
                v-model="args.email"
                type="email"
                name="email"
                class="my-4 col-span-1"
                :error="errorRules.email"
              />
              <easiTextInput
                v-if="!showPasscode"
                placeholder="Phone Number"
                v-model="args.phoneNumber"
                type="tel"
                name="phoneNumber"
                class="my-4 col-span-1"
                :error="errorRules.phoneNumber"
              />
            </div>
            <easiTextInput
              v-if="args.registrationType === 'COMPANY' && !showPasscode"
              placeholder="Company Name"
              v-model="args.companyName"
              type="company"
              name="text"
              class="my-4"
              :error="errorRules.company"
            />

            <div class="my-4">
              <easiSelectInput
                v-if="args.registrationType === 'COMPANY' && !showPasscode"
                @update="args.companySize = $event"
                :value="args.companySize"
                placeholder="Company Size"
                :options="['1-20', '21-50', '51-100', '100 Above']"
                :error="errorRules.companySize"
              />
            </div>

            <div v-if="showPasscode" class="mt-10 space-y-10">
              <div>
                <div class="flex justify-center items-center mb-3">
                  <span class="capitalize text-newGray text-sm font-bold"
                    >Enter passcode
                  </span>

                  <img
                    v-if="showPin"
                    @click="showPin = !showPin"
                    class="cursor-pointer ml-2"
                    src="../../assets/icons/eye-close.svg"
                  />
                  <img
                    v-else
                    @click="showPin = !showPin"
                    class="cursor-pointer ml-2"
                    src="../../assets/icons/eye-open.svg"
                  />
                </div>

                <span class="mb-4 w-fit flex justify-around items-center">
                  <easiOtp
                    class="w-full sm:w-auto"
                    :type="showPin ? 'password' : 'number'"
                    size="10"
                    :token_length="6"
                    :max-length="1"
                    @input="checkPin"
                    :error="errorRules.pin"
                  />
                </span>
              </div>
              <div>
                <div class="flex justify-center items-center mb-3">
                  <span class="capitalize text-newGray text-sm font-bold"
                    >confirm passcode
                  </span>

                  <img
                    v-if="showPin1"
                    @click="showPin1 = !showPin1"
                    class="cursor-pointer ml-2"
                    src="../../assets/icons/eye-close.svg"
                  />
                  <img
                    v-else
                    @click="showPin1 = !showPin1"
                    class="cursor-pointer ml-2"
                    src="../../assets/icons/eye-open.svg"
                  />
                </div>

                <span class="mb-4 flex justify-center items-center">
                  <easiOtp
                    class=""
                    :type="showPin1 ? 'password' : 'number'"
                    size="10"
                    :token_length="6"
                    :max-length="1"
                    @input="checkConfirmPin"
                    :error="errorRules.confirmPin"
                  />
                </span>
                <easiButton
                  block
                  :loading="loading.btnGetstarted"
                  color=" newGreen"
                  rounded="3xl"
                  @click="showPasscode = true"
                >
                  <div class="flex items-center justify-center space-x-3">
                    <span class="font-semibold"> Sign up </span>
                  </div>
                </easiButton>
              </div>
            </div>

            <div v-if="!showPasscode" class="mb-7">
              <easiTextInput
                placeholder="Referral Code (Optional)"
                v-model="args.referralCode"
                type="text"
                name="referralCode"
                class="my-4 mb-5"
                :error="errorRules.referralCode"
              />

              <div
                @click="indemCheck = !indemCheck"
                class="cursor-pointer flex items-center gap-4 mt-5 text-sm"
              >
                <img
                  v-show="indemCheck"
                  src="@/assets/icons/i-checked.svg"
                  alt=""
                />
                <img
                  v-show="!indemCheck"
                  src="@/assets/icons/i-checked-inactive.svg"
                  alt=""
                />
                <p style="margin-top: 1px">
                  I have read and agreed to Eazipay
                  <span
                    @click="preview = !preview"
                    style="color: #2f80ed; font-weight: 400"
                    >Indemnity, Terms & Privacy Policy.</span
                  >
                </p>
              </div>

              <easiButton
                block
                class="mt-8"
                :loading="loading.btnGetstarted"
                color=" newGreen"
                rounded="3xl"
                :disabled="indemCheck ? false : true"
                @click.prevent="switchToPasscode"
              >
                <div class="flex items-center justify-center space-x-3">
                  <span class="font-semibold"> Proceed </span>
                  <img src="../../assets/img/proceedArrow.svg" alt="" />
                </div>
              </easiButton>
              <div
                class="text-dark-800 mt-10 text-base font-medium text-center"
              >
                Already have an account ?
                <router-link class="text-secondary font-semibold" to="/login"
                  >Sign In</router-link
                >
              </div>
            </div>
          </form>
        </easiContainer>
      </div>
      <!-- ///////// Account Form End Here /////// -->

      <!-- ///////// OTP Verification Start Here /////// -->
      <easiContainer
        bg="bg-white"
        showBackbutton
        :isClose="false"
        v-if="display === 'otp-verify'"
        class="w-11/12 md:w-8/12 py-8 px-3 sm:px-8 mx-auto text-center h-80-screen"
        @close="display = 'account-form'"
      >
        <div class="mb-4 mt-10">
          <h2 class="text-secondary font-bold text-2xl sm:text-3xl md:text-4xl">
            OTP Verification
          </h2>
          <span class="text-base md:text-md mt-1 font-medium block">
            Please input OTP sent to your email and phone number
          </span>
        </div>
        <span class="mb-4 flex justify-around items-center">
          <easiOtp
            v-model="otpCode"
            size="10"
            :token_length="6"
            :max-length="1"
            @input="checkOTP"
            :error="errorRules.otpCode"
          />
        </span>
        <div
          class="text-dark-800 mb-4 text-base font-medium text-center flex items-center justify-center"
        >
          Did not receive OTP?
          <easiButton
            @click="resendOTP"
            variant="text"
            :loading="loading.btnOtp"
            color="secondary"
            class="text-base px-0"
            >Resend</easiButton
          >
        </div>
        <easiButton
          block
          :loading="loading.confirm"
          @click="confirmRegistration"
          color=" newGreen"
          rounded="3xl"
        >
          Verify
        </easiButton>
      </easiContainer>
      <!-- ///////// OTP Verification End Here /////// -->

      <easiAlert
        v-if="successModal"
        @close="successModal = false"
        type="new"
        caption="Account created successfully"
      />
    </div>

    <easiModal
      :terms="true"
      :isBorder="false"
      rounded="2xl"
      v-show="preview"
      @close="preview = false"
    >
      <div
        class="w-full flex flex-col items-center h-auto overflow-y-scoll"
        style="overflow: auto; height: calc(100vh - 250px)"
      >
        <!-- <iframe
          src="https://eazipay.storage.googleapis.com/e25cbd6d-abe4-4caa-8b57-a43b64b3c5a3-indemnity-form-(2).pdf"
          style="width: 98%; height: 100%"
        >
        </iframe> -->
        <!-- <iframe :srcdoc="htmlContent" style="width: 98%; height: 100%">
        </iframe> -->
        <div class="w-full overflow-x-hidden">
          <htmlContent />
        </div>
      </div>
    </easiModal>
  </easiLayout>

  <!-- //////// Main form here ///////// -->
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";
import { ref, reactive, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "../../composable/helperFunctions";
import htmlContent from "@/views/main/Indemnity.vue";

const { processNumber, log, validateEmail, validatePhone } = helperFunctions;
const { mutate, query, set } = useDataStore();
const toast = useToast();
const router = useRouter();
const route = useRoute();

const checked = ref(null);
const showPin = ref(true);
const showPin1 = ref(true);
const successModal = ref(false);
const indemCheck = ref(false);
const pdfSrc = ref("");
const preview = ref(false);

// function previewPDF(base64Data) {
//   const byteCharacters = atob(base64Data);
//   const byteArrays = [];
//   for (let offset = 0; offset < byteCharacters.length; offset += 512) {
//     const slice = byteCharacters.slice(offset, offset + 512);
//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }
//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }
//   const blob = new Blob(byteArrays, { type: "application/pdf" });
//   const url = URL.createObjectURL(blob);

//   preview.value = true;
//   pdfSrc.value = url;
// }

const loading = reactive({
  btnGetstarted: false,
  btnOtp: false,
  confirm: false,
});
// const loadingProgress = ref(false);

// //Query Indemnity contract when done
// async function queryIndemnityContract() {
//   loadingProgress.value = true;

//   try {
//     await query({
//       endpoint: "LoanContract",
//       payload: {
//         loanId: route.query.id,
//       },
//       service: "LOAN",
//       storeKey: "loanContract",
//     });
//     previewPDF(contract.value.base64);
//   } catch (e) {
//     console.log(e);
//   } finally {
//     loadingProgress.value = false;
//   }
// }

const accountType = ref(window.localStorage.getItem("registrationType"));

const args = reactive({
  registrationType: accountType.value === "BUSINESS" ? "COMPANY" : "PERSONAL",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  companySize: "",
  pin: "",
  confirmPin: "",
  referralCode: "",
});

//// error rules for input fields ////
const errorRules = reactive({
  firstName: false,
  lastName: false,
  email: false,
  phoneNumber: false,
  company: false,
  pin: false,
  confirmPin: false,
  referralCode: false,
  otpCode: false,
});

const otpCode = ref("");
const display = ref(window.localStorage.getItem("display"));
const showPasscode = ref(false);

watch(display, (val) => {
  window.localStorage.setItem("display", val);
});

watch(accountType, (val) => {
  args.registrationType = val;
  window.localStorage.setItem("registrationType", val);
});

onMounted(() => {
  ///Init the value to the display on load to show the right form///
  display.value === null ? (display.value = "account-type") : display.value;
  accountType.value === null
    ? (accountType.value = "PERSONAL")
    : accountType.value;

  if (route.query.display) {
    display.value = route.query.display;
  } else if (route.query.ref) {
    args.referralCode = route.query.ref;
  }
  // display.value = "otp-verify";
  console.log(display.value);
});

function selectregistrationType(payload) {
  if (payload) {
    accountType.value = payload;
    checked.value = true;
  } else {
    accountType.value = null;
    checked.value = null;
  }
}

function validateForm() {
  const number = processNumber(args.phoneNumber);

  if (args.firstName.trim() === "") {
    errorRules.firstName = "Please fill in your first name";
    return false;
  } else if (args.lastName.trim() === "") {
    errorRules.lastName = "Please fill in your last name";
    return false;
  } else if (args.email.trim() === "") {
    errorRules.email = "Please fill in your email address";
    return false;
  } else if (!validateEmail(args.email.trim())) {
    errorRules.email = "Please enter a valid email address";
  } else if (args.phoneNumber.trim() === "") {
    errorRules.phoneNumber = "Please fill in your phone number";
    return false;
  } else if (!validatePhone(args.phoneNumber.trim())) {
    errorRules.phoneNumber = "Please fill in a valid phone number";
  } else if (
    isNaN(args.phoneNumber.trim()) ||
    processNumber(args.phoneNumber).length > 13
  ) {
    errorRules.phoneNumber = "Please fill in a valid phone number";
    return false;
  } else if (
    args.companyName.trim() === "" &&
    args.registrationType === "COMPANY"
  ) {
    errorRules.company = "Please fill in your company";
    return false;
  } else if (args.pin.trim() === "" && showPasscode.value) {
    errorRules.pin = "Please set a pin";
    return false;
  } else if (args.confirmPin.trim() === "" && showPasscode.value) {
    errorRules.confirmPin = "Please confirm your pin";
    return false;
  } else if (args.pin !== args.confirmPin && showPasscode.value) {
    errorRules.pin = "Pin does not match. Please check and put a matching pin";
    return false;
  } else {
    //Reset Error
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });

    return true;
  }
}
async function switchToPasscode() {
  if (validateForm()) {
    showPasscode.value = true;
  }
}

async function logRegistration(arg, title) {
  try {
    await mutate({
      endpoint: "LogRegister",
      data: { input: arg, title },
      service: "AUTH",
    });
  } catch (e) {
    console.log(e);
  }
}
const logPayload = ref({});
function sixChar(inp) {
  return inp.slice(0, 6);
}
async function submit() {
  if (validateForm()) {
    window.localStorage.setItem("userDetails", JSON.stringify(args));
    // const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
    const payload = {
      registrationType: args.registrationType,
      lastName: args.lastName,
      firstName: args.firstName,
      email: args.email.trim().toLocaleLowerCase(),
      phoneNumber: processNumber(args.phoneNumber),
      companyName: args.companyName,
      companySize: args.companySize,
      pin: sixChar(String(args.pin)),
      confirmPin: args.confirmPin,
      referralCode: args.referralCode ? args.referralCode : null,
    };
    logPayload.value = payload;

    try {
      loading.btnGetstarted = true;
      let res = await mutate({
        endpoint: "LiteRegisterCompany",
        data: { input: payload },
        service: "AUTH",
      });

      log(res);
      if (res.success) {
        toast.success("Signup successful, Please check email for otp code");

        await sendOTP();

        display.value = "otp-verify";
        loading.btnGetstarted = false;
      } else {
        loading.btnGetstarted = false;
        const data = Object.values(res.data)[0];

        console.log(data);

        if (data) {
          Object.keys(data).forEach(function (key) {
            if (data[key].length) {
              errorRules[key] = data[key][0];
              toast.error(data[key][0]);
            }
          });
        } else {
          toast.error(res.message);
        }
        await logRegistration(payload, "Attempted Registration");
      }
    } catch (e) {
      loading.btnGetstarted = false;
      toast.error(e.message);
      await logRegistration(payload, "Attempted Registration");
      console.log(e);
    }
  } else {
  }
}
const otpTimer = ref(null);

function startOTPTimer() {
  // Clear the existing timer, if any
  clearTimeout(otpTimer.value);

  // Set the timer for 2-3 minutes (in milliseconds)
  otpTimer.value = setTimeout(async function () {
    console.log("OTP not entered within the time limit.");

    await logRegistration(logPayload.value, "OTP not inputted after 2 minutes");
    // Call your function here to handle the case when OTP isn't entered
    // For example, you can show an error message or take some other action.
  }, 2 * 60 * 1000); // 2 minutes (change to 3 minutes by replacing 2 with 3)
}

async function sendOTP() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));

  //validate form field

  const payload = {
    channel: "EMAIL",
    // token_length: 4,
    value: userDetails.email,
    type: "SIGNUP",
    // phoneNumber: processNumber(userDetails.phoneNumber),
    // firstName: userDetails.firstName,
  };

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });
    console.log("From Send OTP", res);
    if (res.success) {
      //Store form details and reference in localstorage
      // window.localStorage.setItem("userDetails", JSON.stringify(args));
      window.localStorage.setItem("otpReference", res.data.reference);
      //send user to OTP form
      display.value = "otp-verify";
      startOTPTimer();
    } else {
    }
  } catch (e) {
    console.log(e);
  }
}

async function confirmRegistration() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  const confirmUserDetails = JSON.parse(
    window.localStorage.getItem("confirmUserDetails")
  );

  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    clearTimeout(otpTimer.value);
    loading.confirm = true;
    // console.log(otpCode.value);

    let payload;
    if (route.query.display) {
      payload = {
        reference: window.localStorage.getItem("otpReference"),
        code: otpCode.value,
        value: confirmUserDetails.value,
      };
    } else {
      payload = {
        reference: window.localStorage.getItem("otpReference"),
        code: otpCode.value,
        value: userDetails.email,
      };
    }

    try {
      let res = await mutate({
        endpoint: "ConfirmRegistration",
        data: { input: payload },
        service: "AUTH",
      });
      log(res);
      if (res.success) {
        // Set form back to register
        display.value = "account-type";
        //Clear error state
        errorRules.otpCode = false;
        loading.confirm = false;
        successModal.value = true;

        setTimeout(async () => {
          successModal.value = false;
          // toast.success(res.message);

          window.localStorage.setItem("token", res.token);
          window.localStorage.setItem("refreshToken", res.refreshToken);
          window.localStorage.setItem("privilege", res.privileges);
          window.localStorage.setItem("companyId", res.data.company._id);

          //Turn on Tour
          localStorage.setItem("tour", "true");

          useDataStore().$patch({
            userPrivileges: res.privileges,
            isLoggedIn: true,
          });
          display.value = "account-type";
          router.push("admin/dashboard");
          await queryCompany();
        }, 2000);
      } else {
        errorRules.otpCode = res.message;
        toast.error(res.message);
        loading.confirm = false;
      }
    } catch (e) {
      log(e);
      loading.confirm = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    }
  }
}

async function resendOTP() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  const confirmUserDetails = JSON.parse(
    window.localStorage.getItem("confirmUserDetails")
  );

  // log(args);
  // log("user details", userDetails);

  let payload;

  if (route.query.display) {
    payload = {
      channel: confirmUserDetails.channel,
      value: confirmUserDetails.value,
      type: "SIGNUP",
    };
  } else {
    payload = {
      channel: "EMAIL",
      value: userDetails.email,
      type: "SIGNUP",
    };
  }

  try {
    loading.btnOtp = true;
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      loading.btnOtp = false;
      toast.success("OTP sent successful. Please check email");
      window.localStorage.setItem("otpReference", res.data.reference);
    }
  } catch (e) {
    loading.btnOtp = false;
  }
}

//Update OTP Input Functions
function checkPin(otp_input) {
  args.pin = otp_input;
}

function checkConfirmPin(otp_input) {
  args.confirmPin = otp_input;
}

function checkOTP(otp_input) {
  otpCode.value = otp_input;
}

async function queryCompany() {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
}
</script>

<style scoped></style>
