<template>
  <div class="px-4 md:px-28 pt-8">
    <p class="font-bold text-black">
      <span class="text-2xl"> Settings / </span>

      <span>Support </span>
    </p>

    <form
      @submit.prevent="submit"
      ref="form"
      autocomplete="on"
      class="border border-outlineGray p-8 mt-10"
    >
      <div class="grid grid-cols-2 gap-4">
        <easiTextInput
          placeholder="Enter query title"
          v-model="args.title"
          type="text"
          name="title"
          :error="errorRules.title"
          :disabled="loading"
        />
        <easiTextInput
          placeholder="Email Address "
          v-model="args.email"
          type="email"
          name="email"
          :error="errorRules.email"
          :disabled="loading"
        />
      </div>

      <easiTextArea
        v-model="args.message"
        class="mt-4focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-5 mb-6"
        name="message"
        id="description"
        placeholder="Message"
        cols="30"
        rows="5"
        :disabled="loading"
      ></easiTextArea>
      <div class="w-full my-3 flex place-items-center gap-5 items-center mt-8">
        <div class="w-5/12 flex justify-end ml-auto gap-6">
          <span
            @click="clearFields"
            class="w-full font-normal text-base cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 py-3"
            >Cancel</span
          >
          <div class="w-full md:w-full">
            <easiButton :loading="loading" type="submit" class="w-full">
              Save changes
            </easiButton>
          </div>
        </div>
      </div>
    </form>
    <easiAlert
      v-if="thanksModal"
      @close="thanksModal = false"
      noIcon
      :showDefaultTop="false"
    >
      <div class="px-6">
        <div class="flex justify-center pt-6">
          <span>
            <img src="@/assets/img/success.gif" class="h-32" alt="" />
          </span>
          <!-- <img class="w-32" :src="success" alt="" /> -->
        </div>
        <div class="w-10/12 mx-auto text-center font-bold text-lg mt-6">
          Query Sent!
        </div>
        <p class="text-center w-11/12 mx-auto">
          Your query has been delivered. We will get in touch with you shortly.
        </p>
        <main class="w-full mt-6 space-y-4">
          <easiButton @click="thanksModal = false" block>Okay </easiButton>
        </main>
      </div>
    </easiAlert>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();
const store = useDataStore();
const { mutate } = store;

const thanksModal = ref(false);
const loading = ref(false);

let args = reactive({
  title: "",
  title: "",
  message: "",
});

const errorRules = reactive({
  title: false,
  title: false,
  message: false,
});

const validate = () => {
  if (!args.title.trim()) {
    errorRules.title = "Query title required";
    return false;
  } else if (!args.email.trim()) {
    errorRules.email = "Email is required";
    return false;
  } else if (!args.message.trim()) {
    errorRules.message = "Message is required";
    return false;
  } else {
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });
    return true;
  }
};

const clearFields = () => {
  args.title = "";
  args.email = "";
  args.message = "";
};

const submit = async () => {
  if (!validate()) return;

  const payload = { ...args };

  try {
    loading.value = true;
    const res = await mutate({
      endpoint: "SendSupportMessage",
      data: {
        input: payload,
      },
      service: "API",
    });

    if (res.success) {
      thanksModal.value = true;
      clearFields();
    } else {
      toast.error(res.message);
    }
  } catch (error) {
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
