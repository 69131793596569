<template>
  <main
    :class="'md:w-9/12 xl:w-9/12 w-full  rounded-2xl shadow-lg p-3 md:p-7'"
    class="w-full mx-auto bg-white minHeight"
  >
    <!-- ACTIVE PLAN  -->
    <h2 class="font-bold text-center text-xl mb-4">Pricing</h2>
    <div
      class="bg-newAsh px-2 py-2 flex items-center justify-between gap-3 w-full overflow-scroll rounded-2xl mb-4"
    >
      <div
        v-for="plan in availablePlans"
        :key="plan._id"
        class="w-full rounded-lg p-2 cursor-pointer text-sm text-center text-bodyDark"
        :class="selectedType._id == plan._id ? 'bg-white font-bold' : 'bg-none'"
        @click="selectedType = plan"
      >
        <span>{{ plan.description }}</span>
      </div>
    </div>

    <!-- <div
      v-if="showActivePlan"
      class="bg-primary p-5 text-white rounded-xl shadow"
    >
      <div v-if="current.subscriptionId" class="flex w-full">
        <div class="w-11/12 flex items-center gap-4">
          <div class="">
            <img
              :src="getPackageIcon(current.subscriptionId.description)"
              alt=""
            />
          </div>

          <div class="flex flex-col">
            <p class="font-bold">
              {{ current.subscriptionId.description }} Plan
            </p>
            <p class="text-newLightGreen text-base">
              {{ differenceBetweenDates(current.startTime, current.endTime) }}
              months
            </p>
          </div>
        </div>
        <div class="w-1/12 text-right items-end flex flex-col">
          <p class="font-bold mb-1">
            {{
              current.subscriptionId.basePackagePrice > 0
                ? formatAmount(current.subscriptionId.basePackagePrice)
                : "Free"
            }}
          </p>
          <img
            src="@/assets/icons/activePlanCheck.svg"
            class="w-5 h-5"
            alt=""
          />
        </div>
      </div>

      <div class="flex justify-end mt-5 w-full">
        <span
          @click="showActivePlan = false"
          class="md:w-auto font-medium cursor-pointer text-center bg-primary border border-newLightGreen rounded-full text-primary md:border px-3 text-sm py-2"
        >
          <span class="md:flex items-center text-sm text-newLightGreen">
            Change Plan
          </span></span
        >
      </div>
    </div> -->
    <div
      v-if="selectedType && Object.keys(selectedType).length"
      class="grid grid-cols-1 md:grid-cols-1 md:justify-items-center w-full gap-5"
    >
      <pricing-card
        @onSelected="handlePlanCard"
        :plan="selectedType"
        :companyId="companyId"
        :showCurrentPlan="current && current._id === selectedType._id"
        :upgrade="
          current &&
          Number(current.basePackagePrice) <
            Number(selectedType.basePackagePrice)
        "
      />
    </div>
    <easiSuccess v-if="successModal" @close="successModal = false">
      <template v-slot:message>
        <p class="text-base mx-auto">Your subscription is succesfull</p>
      </template>
    </easiSuccess>

    <easiModal
      v-if="insufficientModal"
      @close="insufficientModal = false"
      :isBorder="false"
    >
      <div class="bg-white flex flex-col text-center space-y-4">
        <div class="flex justify-center">
          <img src="@/assets/icons/pricingpopup.svg" class="" alt="" />
        </div>
        <p class="font-bold text-2xl">Insufficient Balance</p>
        <p class="text-base w-7/12 mx-auto">
          You do not have enough balance in your wallet
        </p>
        <div class="flex gap-5 w-10/12 mx-auto pb-8">
          <div class="w-full">
            <easiButton
              :loading="isLoading"
              @click="insufficientModal = false"
              class="w-full"
            >
              Okay
            </easiButton>
          </div>
        </div>
      </div>
    </easiModal>
    <easiModal v-if="preview" @close="preview = false" :isBorder="false">
      <div class="bg-white flex flex-col text-center space-y-4">
        <div class="flex justify-center">
          <img src="@/assets/icons/pricingpopup.svg" class="" alt="" />
        </div>
        <p class="font-bold text-2xl">Subscription</p>
        <p class="text-base w-7/12 mx-auto">
          Are you sure you want to subscribe to this plan?
        </p>
        <div class="flex gap-5 w-10/12 mx-auto pb-8">
          <div
            @click="preview = false"
            class="w-full font-medium cursor-pointer text-center bg-white border border-primary rounded-full text-primary md:border px-3 text-sm py-3"
          >
            NO
          </div>
          <div class="w-full">
            <easiButton :loading="isLoading" @click="changeSub" class="w-full">
              Yes
            </easiButton>
          </div>
        </div>
      </div>
    </easiModal>
    <easiRoundLoader v-if="showRoundLoader" />
  </main>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

import PricingCard from "@/components/Settings/Pricing/PricingCard";

import basicIcon from "@/assets/icons/basicIcon.svg";
import StartupIcon from "@/assets/icons/startupicon.svg";
import businessIcon from "@/assets/icons/businessicon.svg";
import businessplusIcon from "@/assets/icons/businessplusicon.svg";

import priceData from "@/views/admin/employee/data.JSON";

const toast = useToast();
const userSubscriptionPlan = computed(
  () => current.value && current.value.subscriptionId
);
const subscriptions = computed(() => {
  //// Sort the subscription in order of price
  const arr = [...availablePlans.value].sort(
    (a, b) => a.basePackagePrice - b.basePackagePrice
  );
  //// Sort The Staff Subscription Plan
  arr.forEach((el) => {
    el.staffSubscriptions = el.staffSubscriptions.sort(
      (a, b) => a.staffNoMin - b.staffNoMin
    );
  });
  //// Sort the subscription in order of active plan
  return current.value
    ? [...arr].sort((a, b) =>
        a.description === current.value.description
          ? -1
          : b.description === current.value.description
          ? 1
          : 0
      )
    : arr;
});
const { formatAmount } = helperFunctions;
const store = useDataStore();
const { query, mutate } = store;
const preview = ref(false);
const insufficientModal = ref(false);
const successModal = ref(false);
const selectedPlan = ref({});
const isLoading = ref(false);
const differenceBetweenDates = (startDate, endDate) => {
  let start = new Date(startDate);
  let end = new Date(endDate);
  let diff = (end.getTime() - start.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7 * 4;
  return Math.abs(Math.round(diff));
};
const changeSub = async () => {
  try {
    isLoading.value = true;
    const payload = {
      subscriptionId: selectedPlan.value._id,
      // companyId: companyId.value,
    };
    const res = await mutate({
      endpoint: "SubscribeV3",
      service: "PAYROLL",
      data: {
        input: payload,
      },
    });
    if (res.success) {
      await fetchCompanyAdminDetails();
      preview.value = false;
      successModal.value = true;
    } else {
      preview.value = false;
      toast.error(res.message);
      // insufficientModal.value = true;
    }
  } catch (e) {
    console.log("error");
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};
const handlePlanCard = async (obj) => {
  selectedPlan.value = obj;
  preview.value = true;
};

const showActivePlan = ref(true);
const showRoundLoader = ref(true);
const companyId = ref("");
const current = ref({});
const availablePlans = ref([]);
const getPackageIcon = (name) => {
  const icons = {
    Basic: basicIcon,
    Startup: StartupIcon,
    Business: businessIcon,
    "Business Plus": businessplusIcon,
  };

  return name ? icons[name] : null;
};
const fetchCompanyAdminDetails = async () => {
  try {
    showRoundLoader.value = true;
    const res = await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
    if (res) {
      companyId.value = res.company._id;

      const res3 = await query({
        endpoint: "GetCompanySubscriptionV3",
        service: "PAYROLL",
        // payload: {
        //   input: payload3,
        // },
      });
      current.value = res3.data.baseSub;
    }
    const res2 = await query({
      endpoint: "GetAllSubscriptionPackages",
      service: "PAYROLL",
      // payload: {
      //   input: {
      //     companyId: companyId.value,
      //     custom: true,
      //   },
      // },
    });
    availablePlans.value = res2;
    console.log(availablePlans.value, "available plans");
  } catch (e) {
    // toast.error(e.message);
    console.log("error", e);
  } finally {
    showRoundLoader.value = false;
  }
};
const selectedType = ref({});

// const priceData = ref([
//   {
//     name: "EaziStarter",
//     monthlySubscription: "Free",
//     employeeSize: "Less than 10 employees",
//     payroll: "Unlimited; bank charges",
//     payslips: "NGN 50 per payslip",
//   },
//   {
//     name: "EaziPlus",
//     monthlySubscription: "Free",
//     employeeSize: "Less than 10 employees",
//     payroll: "Unlimited; bank charges",
//     payslips: "NGN 50 per payslip",
//   },
//   {
//     name: "EaziPremium",
//     monthlySubscription: "Free",
//     employeeSize: "Less than 10 employees",
//     payroll: "Unlimited; bank charges",
//     payslips: "NGN 50 per payslip",
//   },
//   {
//     name: "EaziBusiness",
//     monthlySubscription: "Free",
//     employeeSize: "Less than 10 employees",
//     payroll: "Unlimited; bank charges",
//     payslips: "NGN 50 per payslip",
//   },
// ]);

onMounted(async () => {
  await fetchCompanyAdminDetails();
  if (availablePlans.value && availablePlans.value.length) {
    selectedType.value = availablePlans.value[0];
  }
});
</script>

<style scoped>
.minHeight {
  min-height: 30vh;
}
</style>
