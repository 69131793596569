<template>
  <div class="bg-blueGray-100 pb-20 md:pb-6 onprint">
    <sidebar
      :style="`${getTourLevel === 3 ? 'z-index:400' : ''}`"
      :class="getTourLevel === 3 ? 'pointer-events-none' : ''"
    />
    <div class="relative md:ml-56 bg-blueGray-100 onprint">
      <admin-navbar
        v-if="$route.path.includes('dashboard')"
        class="md:hidden"
      />
      <admin-navbar class="hidden md:block" />
      <!-- <header-stats /> -->
      <div class="md:mx-auto w-full px-2 sm:px-4 md:px-10">
        <router-view />
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";

import { computed, ref } from "vue";
import { storeToRefs, mapWritableState } from "pinia";
import { useDataStore } from "@/stores/data.js";

export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
  },
  setup() {
    const store = useDataStore();
    const { getTourLevel } = storeToRefs(store);

    return { getTourLevel };
  },
};
</script>

<style></style>
