<template>
  <main v-if="visibleData && visibleData.length">
    <div class="flex w-full gap-1 md:gap-x-3">
      <div class="w-9/12">
        <ComplianceSearch
          placeholder="Search service, date..."
          v-model="searchText"
          @search="searchData"
        />
      </div>

      <!-- /////// Filter Component Start /////// -->
      <div class="md:ml-0 mb-3 z-20">
        <Filter
          :transaction="true"
          ref="viewEmployeeFilterRef"
          @update="updateData($event)"
        />
      </div>

      <div class="md:ml-0 mb-3 z-20">
        <ComplianceActions />
      </div>
    </div>
    <hr class="border-0.5 border-outlineGray mb-2" />
    <div
      v-for="reg in visibleData"
      :key="reg.dateAdded"
      class="bg-white mb-4 w-full shadow-md md:py-4 md:px-5 p-3 rounded-lg hidden md:flex flex-col md:flex-row justify-between md:items-center"
    >
      <div class="flex flex-col gap-3">
        <span class="text-xs text-newGray capitalize">Service Type</span>
        <p
          :class="
            ['pension', 'Pension'].includes(reg.serviceType)
              ? 'capitalize'
              : 'uppercase'
          "
          class="text-bodyDark text-sm"
        >
          {{ reg.serviceType }}
        </p>
      </div>

      <div class="flex flex-col gap-3">
        <span class="text-xs text-newGray capitalize">Registration type</span>
        <p class="text-bodyDark text-sm capitalize">
          {{ reg.registrationType }}
        </p>
      </div>

      <div class="flex flex-col gap-3">
        <span class="text-xs text-newGray capitalize">Company Name</span>
        <p class="text-bodyDark text-sm capitalize">{{ reg.name }}</p>
      </div>

      <div class="flex flex-col gap-3">
        <span class="text-xs text-newGray capitalize">Date Applied</span>
        <p class="text-bodyDark text-sm">{{ getDateText(reg.dateAdded) }}</p>
      </div>
    </div>

    <div
      v-for="reg in visibleData"
      :key="reg.dateAdded"
      class="bg-white w-full shadow-lg p-3 rounded-lg md:hidden flex flex-col mb-2 gap-3 justify-between md:items-center"
      :class="
        showDetails[reg.dateAdded] ? 'border border-outlineGray' : undefined
      "
    >
      <div class="flex items-center justify-between">
        <div class="flex flex-col gap-1">
          <span class="text-xs text-newGray capitalize">Company Name</span>
          <p class="text-bodyDark capitalize text-sm">
            {{ reg.name }}
          </p>
        </div>

        <svg
          @click="showDetails[reg.dateAdded] = !showDetails[reg.dateAdded]"
          v-show="showDetails[reg.dateAdded]"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.25 6.875L10 13.125L3.75 6.875"
            stroke="#8D8E8D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <svg
          @click="showDetails[reg.dateAdded] = !showDetails[reg.dateAdded]"
          v-show="!showDetails[reg.dateAdded]"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.875 3.75L13.125 10L6.875 16.25"
            stroke="#8D8E8D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div v-show="showDetails[reg.dateAdded]" class="flex flex-col gap-1">
        <span class="text-xs text-newGray capitalize">Service Type</span>
        <p class="text-bodyDark text-sm capitalize">{{ reg.serviceType }}</p>
      </div>

      <div class="flex items-center justify-between">
        <div v-show="showDetails[reg.dateAdded]" class="flex flex-col gap-1">
          <span class="text-xs text-newGray capitalize">Registration type</span>
          <p class="text-bodyDark text-sm capitalize">
            {{ reg.registrationType }}
          </p>
        </div>

        <div v-show="showDetails[reg.dateAdded]" class="flex flex-col gap-1">
          <span class="text-xs text-newGray capitalize">Date Applied</span>
          <p class="text-bodyDark text-sm capitalize">
            {{ getDateText(reg.dateAdded) }}
          </p>
        </div>
      </div>
    </div>

    <hr class="border-0.5 border-dividerColor w-full mt-1" />

    <div
      v-if="$route.name === 'ViewComplianceHistory'"
      class="flex w-full items-center justify-end self-end md:justify-start"
    >
      <easiPagin
        :data="regDetails"
        @page:update="updatePage"
        :currentPage="currentPage"
        :visibleData="visibleData"
        :pageSize="pageSize"
        :fromCompliance="true"
        class="w-full mt-2"
      />
    </div>
    <div v-else class="flex w-full justify-end mt-3 items-center">
      <p
        @click="$router.push({ name: 'ViewComplianceHistory' })"
        class="cursor-pointer text-primary text-xs"
      >
        VIEW ALL COMPLIANCES HISTORY <span class="text-lg">></span>
      </p>
    </div>

    <easiLoading v-if="loading" />
  </main>

  <div v-else class="text-center my-6 flex flex-col justify-center">
    <div class="bg-white p-4 rounded-xl">
      <div
        class="container mx-auto flex items-center justify-center gap-4 flex-wrap"
      >
        <img src="@/assets/img/Validation.png" class="h-32" alt="" />
      </div>
      <h3 class="text-sm md:text-xl text-dark-800 font-bold">No History!</h3>
      <p class="text-dark-500 text-sm">
        You have not registered any compliance.
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import ComplianceSearch from "@/components/Search/ComplianceSearch.vue";
import Filter from "@/components/Filters/ComplianceFilter.vue";
import ComplianceActions from "@/components/Compliance/ComplianceActions.vue";

const { formatDateString, getDateText } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const showDetails = ref({});
const props = defineProps({
  data: {
    type: Array,
  },
});
const filterData = ref({
  to: null,
  names: null,
  from: null,
});
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);
const regDetails = computed(() => store.getSingleComplianceReg);

const updateVisibleData = () => {
  let datas = regDetails.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (
    visibleData.value &&
    visibleData.value.length == 0 &&
    currentPage.value > 0
  ) {
    updatePage(currentPage.value - 1);
  }
  for (let d of datas) {
    showDetails.value[d.dateAdded] = false;
  }
};
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const loading = ref(false);

// watch(
//   filterData,
//   async () => {
//     await queryEmployeeCompliance();
//     updateVisibleData();
//   },
//   { deep: true }
// );

const queryEmployeeCompliance = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetComplianceRegistration",
      payload: {
        input: filterData.value,
      },
      service: "PAYROLL",
      storeKey: "listComplianceRegistration",
    });

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

const searchText = ref("");

function analyzeSearchText(searchText) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Check if the search text partially matches a month
  const partialMonthMatch = monthNames.some((month) =>
    month.toLowerCase().includes(searchText.toLowerCase())
  );
  if (partialMonthMatch) {
    return "Partial Month";
  }

  // Check if the search text partially matches a date (assuming the date is in the "YYYY-MM-DD" format)
  if (/^\d{4}-\d{0,2}-\d{0,2}$/.test(searchText) || /\d/.test(searchText)) {
    return "Partial Date";
  }

  // If it's not a partial month or date, consider it as regular text
  return "Regular Text";
}

function searchArrayObjects(arr, searchString) {
  if (analyzeSearchText(searchString) === "Regular Text") {
    return arr.filter((item) => {
      return (
        item.serviceType.toLowerCase().includes(searchString) ||
        item.registrationType.toLowerCase().includes(searchString)
      );
    });
  }
  return arr.filter((item) => {
    return (
      item.dateAdded.toLowerCase().includes(searchString) ||
      getDateText(item.dateAdded).toLowerCase().includes(searchString)
    );
  });
}

function searchData(e) {
  searchText.value = e.toLowerCase();
  if (
    searchText.value &&
    searchText.value !== undefined &&
    searchText.value.length
  ) {
    visibleData.value = regDetails.value;
    visibleData.value = searchArrayObjects(regDetails.value, searchText.value);
  } else {
    visibleData.value = regDetails.value;
  }
}
async function updateData(obj) {
  filterData.value = obj;
  await queryEmployeeCompliance();
  updateVisibleData();
}
onMounted(async () => {
  await queryEmployeeCompliance();
  updateVisibleData();
});
</script>

<style></style>
