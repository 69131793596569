<template>
  <div class="flex flex-col gap-6">
    <div
      class="flex items-center justify-between gap-4 uppercase md:capitalize border border-dark-100 rounded-md p-2 md:p-0 md:rounded-none md:border-none"
    >
      <div class="flex items-center gap-2 md:gap-4">
        <h2 class="md:text-lg text-xs">Valid Identity Document</h2>
        <GetStatus
          v-if="
            kycInfo &&
            kycInfo[kycInfo.length - 1] &&
            kycInfo[kycInfo.length - 1].status
          "
          :status="kycInfo[kycInfo.length - 1].status"
        />
      </div>
      <div class="flex md:flex-row flex-col items-center gap-4">
        <easiButton
          v-if="
            (kycInfo &&
              kycInfo[kycInfo.length - 1] &&
              kycInfo[kycInfo.length - 1].status &&
              kycInfo[kycInfo.length - 1].status !== 'verified' &&
              kycInfo[kycInfo.length - 1].status !== 'pending') ||
            hasData == true
          "
          @click="() => (hasData = false)"
          variant="outlined"
          size="small"
          >Edit</easiButton
        >

        <div>
          <slot />
        </div>
      </div>
    </div>

    <div
      :class="[hasData ? 'bg-newGrey' : 'border border-dark-100']"
      class="rounded-2xl md:rounded-3xl p-2 md:p-4 grid grid-cols-1 md:grid-cols-2 items-center gap-2 md:gap-4 text-xs md:text-sm"
    >
      <easiSelectInput2
        v-if="!kycInfo.length || !hasData"
        :options="Options"
        :value="args.idType"
        @update="args.idType = $event"
        placeholder="Select ID"
      ></easiSelectInput2>

      <UploadComponent
        v-if="!kycInfo.length || !hasData"
        dotted
        capture
        @fileUrl="uploadFile = $event"
        :clear="removeFile"
      >
        <template #dotted>
          <div class="text-xs sm:text-sm flex items-center justify-between">
            <span v-if="uploadFile && uploadFile.name">{{
              uploadFile.name
            }}</span>
            <span v-else-if="uploadFile && !uploadFile.name">{{
              uploadFile
            }}</span>
            <span v-else>Upload document</span>
            <img src="@/assets/icons/grey-upload.svg" alt="" />
          </div>
        </template>
      </UploadComponent>

      <easiTextCard
        v-if="kycInfo && kycInfo.length && hasData"
        title="Means of Identification"
        :value="
          kycInfo &&
          kycInfo[kycInfo.length - 1] &&
          kycInfo[kycInfo.length - 1].idType &&
          typeOfId[kycInfo[kycInfo.length - 1].idType]
        "
      />
      <easiTextCard
        v-if="kycInfo && kycInfo.length && hasData"
        :title="
          kycInfo &&
          kycInfo[kycInfo.length - 1] &&
          kycInfo[kycInfo.length - 1].front &&
          getLink(kycInfo[kycInfo.length - 1].front)
        "
        :url="
          kycInfo &&
          kycInfo[kycInfo.length - 1] &&
          kycInfo[kycInfo.length - 1].front &&
          kycInfo[kycInfo.length - 1].front
        "
      />
    </div>

    <div
      v-if="
        !kycInfo || !kycInfo[kycInfo.length - 1] || !kycInfo.length || !hasData
      "
      class="flex w-full flex-col-reverse md:flex-row md:items-center gap-4 justify-between"
    >
      <easiButton
        :block="breakPoints('md')"
        @click="hasData = !hasData"
        variant="text"
        color="secondary"
        size="medium"
        >{{ breakPoints("md") ? "Discard Changes" : "Cancel" }}</easiButton
      >

      <div class="flex flex-col md:flex-row md:items-center md:gap-4">
        <easiButton
          @click="submit"
          variant="outlined"
          size="medium"
          class="hidden md:flex"
          >Save Update</easiButton
        >
        <easiButton @click="submit" :block="breakPoints('md')" size="medium"
          >Submit</easiButton
        >
      </div>
    </div>
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

import UploadComponent from "@/components/global/UploadComponent";
import GetStatus from "../GetStatus.vue";

import { computed, onMounted, reactive, ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const { breakPoints, uploadFileToServer } = helperFunctions;

const uploadFile = ref(null);
const store = useDataStore();

const { query, mutate } = store;
const toast = useToast();

const loading = ref(false);
const hasData = ref(true);
const status = ref("Verified");

const kycInfo = computed(() =>
  store.getKyc && store.getKyc.data && store.getKyc.data.identityInformation
    ? store.getKyc.data.identityInformation.filter(
        (info) => info.idType !== "video_verification"
      )
    : []
);
console.log(kycInfo.value);

const identityDoc = computed(() => {
  return {
    documentType: "National ID Card",
    document: null,
  };
});

const documentType = ref("");
const args = reactive({
  idType: "",
  front: "",
  back: "",
});

function getLink(url) {
  const parts = url.split("/");
  const filename = parts[parts.length - 1];
  return filename;
}

const typeOfId = {
  nin_slip: "NIN",
  national_id: "National ID",
  voters_card: "Voters Card",
  drivers_license: "Drivers License",
  international_passport: "International Passport",
};

const Options = ref([
  {
    label: "National ID",
    value: "national_id",
  },
  {
    label: "Voters Card",
    value: "voters_card",
  },
  {
    label: "NIN",
    value: "nin_slip",
  },
  {
    label: "Drivers License",
    value: "drivers_license",
  },
  {
    label: "International Passport",
    value: "international_passport",
  },
]);

function validate() {
  if (uploadFile.value) {
    return true;
  }
  toast.error("Upload all files");
  return false;
}

const createValidIdentityInformation = async () => {
  loading.value = true;

  try {
    if (uploadFile.value) {
      args.front = await uploadFileToServer(uploadFile.value);
    }
    if (typeof args.front !== "string") {
      toast.error("Upload failed");
      return;
    }
    const data = [args];
    let res = await mutate({
      endpoint: "CreateValidIdentityInformation",
      data: { input: data },
      service: "SETTINGS",
    });
    if (res.success) {
      loading.value = false;
      hasData.value = !hasData.value;
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};

async function submit() {
  if (validate()) {
    await createValidIdentityInformation();
  }
}

onMounted(() => {
  if (kycInfo.value && kycInfo.value.length) {
    const info = kycInfo.value[kycInfo.value.length - 1];
    args.idType = info.idType;
    args.front = info.front;
    args.back = info.back;
    uploadFile.value = info.front;
  }
});
</script>

<style></style>
